import React from 'react';
import Img_desktop from "../assets/images/single-img/desctop/childrens.webp";
import Img_mobile from "../assets/images/single-img/mobile/children.webp";

const initialState = {  
    pageTopPart: {
        pageName: "price",
        srcDesktop: Img_desktop,            
        srcMobile: Img_mobile,
        title: "Цены"       
    }, 
    tabs: [
        {
            title: "Новый город (пр-т Врача Сурова, д.4)",
            status: "surova"
        },
        {
            title: "Центр (ул. Бебеля, 1А)",
            status: "bebelya"
        },
        {
            title: "Генетические исследования",
            status: "geneticResearch"
        }
    ],
	navigation: {
        surova: [
            {
                name: "1. Консультационно-диагностическое отделение",
                href: "Раздел 1"
            },
            {
                name: "1.1 Врачебные лечебно-диагностические услуги",
                href: "1.1"
            },
            {
                name: "1.2 Процедуры и лечебные манипуляции",
                href: "1.2"
            },
            {
                name: "1.2.1 Гинекология и урология",
                href: "1.2.1"
            },
            {
                name: "1.2.2 Травматология",
                href: "1.2.2"
            },
            {
                name: "1.3 Услуги отделения УЗД (ультразвуковой диагностики)",
                href: "1.3"
            },
            {
                name: "1.3.1 Акушерство и гинекология",
                href: "1.3.1"
            },
            {
                name: "1.3.2 УЗИ органов малого таза, брюшной полости, забрюшинного пространства и мочеполовой системы",
                href: "1.3.2"
            },
            {
                name: "1.3.3 Поверхностные структуры",
                href: "1.3.3"
            },
            {
                name: "1.3.4 Сердечно-сосудистая система",
                href: "1.3.4"
            },
            {
                name: "1.3.5 Ультрозвуковая диагностика (дети)",
                href: "1.3.5"
            },
            {
                name: "1.4 Функциональная диагностика",
                href: "1.4"
            },
            {
                name: "2. Оперативное лечение",
                href: "Раздел 2"
            },
            {
                name: "2.1 Гинекология и урология",
                href: "2.1"
            },
            {
                name: "2.2 Травматология",
                href: "2.2"
            },
            {
                name: "3. Лаборатория ВРТ (Вспомогательные репродуктивные технологии)",
                href: "Раздел 3"
            },
            {
                name: "4. Лабораторные исследования",
                href: "Раздел 4"
            }
        ],
        bebelya: [
            {
                name: "1. Консультационно-диагностическое отделение",
                href: "Раздел 1"
            },
            {
                name: "1.1 Врачебные лечебно-диагностические услуги",
                href: "1.1"
            },
            {
                name: "1.2 Процедуры и лечебные манипуляции",
                href: "1.2"
            },
            {
                name: "1.2.1 Гинекология и урология",
                href: "1.2.1"
            },
            {
                name: "1.2.2 Травматология",
                href: "1.2.2"
            },
            {
                name: "1.3 Услуги отделения УЗД (ультразвуковой диагностики)",
                href: "1.3"
            },
            {
                name: "1.3.1 Акушерство и гинекология",
                href: "1.3.1"
            },
            {
                name: "1.3.2 УЗИ органов малого таза, брюшной полости, забрюшинного пространства и мочеполовой системы",
                href: "1.3.2"
            },
            {
                name: "1.3.3 Поверхностные структуры",
                href: "1.3.3"
            },
            {
                name: "1.3.4 Сердечно-сосудистая система",
                href: "1.3.4"
            },
            {
                name: "1.3.5 Ультрозвуковая диагностика (дети)",
                href: "1.3.5"
            },
            {
                name: "1.4 Функциональная диагностика",
                href: "1.4"
            },
            {
                name: "2. Лаборатория ВРТ (вспомогательные репродуктивные технологии)",
                href: "Раздел 2"
            },
            {
                name: "3. Лабораторные исследования",
                href: "Раздел 3"
            }
        ]       
    },
    content: {
        surova: [
            {
                firstCell: "Раздел 1",
                secondCell: "100",
                thirdCell: "Консультационно - диагностическое отделение",
                fourthСell: "",
                chapter: true            
            },
            {
                firstCell: "1.1",
                secondCell: "",
                thirdCell: "Врачебные лечебно-диагностические услуги",
                fourthСell: "",
                subChapter: true            
            },
            {
                firstCell: "В01.001.001",
                secondCell: "100.01",
                thirdCell: "Прием врача-акушера-гинеколога первичный (комплексный осмотр репродуктолога: консультация врача, инструментальный осмотр, УЗИ обследование)",
                fourthСell: "1 800,00"                       
            },
            {
                firstCell: "В01.001.001",
                secondCell: "100.02",
                thirdCell: "Прием врача-акушера-гинеколога первичный (репродуктолог)",
                fourthСell: "1 100,00"                       
            },
            {
                firstCell: "В01.001.001",
                secondCell: "100.02.01",
                thirdCell: "Прием врача-акушера-гинеколога первичный (репродуктолог, кандидат медицинских наук)",
                fourthСell: "1200,00"                       
            }, 
            {
                firstCell: "B01.001.002",
                secondCell: "100.03",
                thirdCell: "Прием врача-акушера-гинеколога повторный (репродуктолог)",
                fourthСell: "1 000,00"                       
            },
            {
                firstCell: "B01.001.002",
                secondCell: "100.03.01",
                thirdCell: "Прием врача-акушера-гинеколога повторный (репродуктолог, кандидат медицинских наук)",
                fourthСell: "1100,00"                       
            },
            {
                firstCell: "В01.001.001",
                secondCell: "100.05",
                thirdCell: "Прием врача-акушера - гинеколога первичный (Комплекс: консультация врача, обследование в смотровом кабинете, инструментальный осмотр, ультразвуковое исследование)",
                fourthСell: "1700,00"                       
            },
            {
                firstCell: "В01.001.001",
                secondCell: "100.06",
                thirdCell: "Прием врача-акушера - гинеколога первичный",
                fourthСell: "1000,00"                       
            },
            {
                firstCell: "В01.001.001",
                secondCell: "100.06.1",
                thirdCell: "Прием врача-акушера - гинеколога  первичный (кандидат медицинских наук)",
                fourthСell: "1100,00"                       
            },
            {
                firstCell: "B01.001.002",
                secondCell: "100.07",
                thirdCell: "Прием врача-акушера - гинеколога повторный",
                fourthСell: "900,00"                       
            },
            {
                firstCell: "B01.001.002",
                secondCell: "100.07.1",
                thirdCell: "Прием врача-акушера - гинеколога повторный (кандидат медицинских услуг)",
                fourthСell: "1000,00"                       
            },
            {
                firstCell: "B01.001.002",
                secondCell: "100.27",
                thirdCell: "Прием врача-акушера-гинеколога повторный  (выписка из амбулаторной карты)",
                fourthСell: "600,00"                       
            },
            {
                firstCell: "B01.053.001 ",
                secondCell: "100.08",
                thirdCell: "Прием врача-уролога первичный",
                fourthСell: "950,00"                       
            },
            {
                firstCell: "B01.053.001",
                secondCell: "100.08.1",
                thirdCell: "Прием врача-уролога первичный (кандидат медицинских наук)",
                fourthСell: "1050,00"                       
            },
            {
                firstCell: "B01.053.001",
                secondCell: "100.08.2",
                thirdCell: "Прием врача-уролога первичный (доктор медицинских наук)",
                fourthСell: "2000,00"                       
            },
            {
                firstCell: "B01.053.002 ",
                secondCell: "100.09",
                thirdCell: "Прием врача-уролога повторный",
                fourthСell: "750,00"                       
            },
            {
                firstCell: "B01.053.002",
                secondCell: "100.09.1",
                thirdCell: "Прием врача-уролога повторный (кандидат медицинских наук)",
                fourthСell: "800,00"                       
            },
            {
                firstCell: "B01.053.002",
                secondCell: "100.09.2",
                thirdCell: "Прием врача-уролога повторный (доктор медицинских наук)",
                fourthСell: "1930,00"                       
            },
            {
                firstCell: "B01.015.001 ",
                secondCell: "Б100.12",
                thirdCell: "Прием врача-кардиолога (терапевта)  первичный ",
                fourthСell: "1100,00"                       
            },
            {
                firstCell: "B01.015.002",
                secondCell: "Б100.12.1",
                thirdCell: "Прием врача-кардиолога (терапевта) повторный",
                fourthСell: "1000,00"                       
            },
            {
                firstCell: "B01.058.001 ",
                secondCell: "100.13",
                thirdCell: "Прием врача-эндокринолога первичный",
                fourthСell: "1000,00"                       
            },
            {
                firstCell: "B01.058.002",
                secondCell: "100.13.1",
                thirdCell: "Прием врача-эндокринолога повторный",
                fourthСell: "900,00"                       
            },
            {
                firstCell: "B01.047.001 ",
                secondCell: "100.15",
                thirdCell: "Прием врача-терапевта первичный",
                fourthСell: "900,00"                       
            },
            {
                firstCell: "B01.047.002 ",
                secondCell: "100.15.1",
                thirdCell: "Прием врача-терапевта повторный",
                fourthСell: "800,00"                       
            },
            {
                firstCell: "B01.050.001 ",
                secondCell: "Б100.41",
                thirdCell: "Прием врача-травматолога-ортопеда первичный",
                fourthСell: "1000,00"                       
            },
            {
                firstCell: "B01.050.002",
                secondCell: "Б100.41.1",
                thirdCell: "Прием врача-травматолога-ортопеда повторный",
                fourthСell: "900,00"                       
            },
            {
                firstCell: "B01.027.002 ",
                secondCell: "Б100.42",
                thirdCell: "Прием врача-онколога первичный",
                fourthСell: "1000,00"                       
            },
            {
                firstCell: "B01.027.003",
                secondCell: "Б100.42.1",
                thirdCell: "Прием врача-онколога повторный",
                fourthСell: "900,00"                       
            },
            {
                firstCell: "",
                secondCell: "",
                thirdCell: "Приемы врачей-специалистов перед вводом в программу ЭКО по ОМС",
                fourthСell: "",
                title: true
            },
            {
                firstCell: "B01.001.002",
                secondCell: "100.45",
                thirdCell: "Прием врача-акушера-гинеколога повторный (оформление репродуктологом выписки на проведение ЭКО по ОМС)",
                fourthСell: "3000,00"                       
            },
            {
                firstCell: "B01.001.002",
                secondCell: "100.45.1",
                thirdCell: "Прием врача-акушера-гинеколога повторный (срочное оформление репродуктологом выписки на проведение ЭКО по ОМС)",
                fourthСell: "5000,00"                       
            },
            {
                firstCell: "B01.047.002",
                secondCell: "100.17",
                thirdCell: "Консультация врача-терапевта (допуск к программе ЭКО/гистероскопии)",
                fourthСell: "800,00"                       
            },
            {
                firstCell: "",
                secondCell: "",
                thirdCell: "Консультации врачей-специалистов по результатам обследования",
                fourthСell: "",
                title: true                       
            },
            {
                firstCell: "B01.001.002",
                secondCell: "100.38",
                thirdCell: "Консультация врача-акушера-гинеколога повторная (по результатам обследования)",
                fourthСell: "500,00"                       
            },
            {
                firstCell: "B01.047.002",
                secondCell: "100.38.1",
                thirdCell: "Консультация врача-терапевта повторная (по результатам обследования)",
                fourthСell: "500,00"                       
            },
            {
                firstCell: "B01.053.002",
                secondCell: "100.38.2",
                thirdCell: "Консультация врача-уролога повторная (по результатам обследования)",
                fourthСell: "500,00"                       
            },
            {
                firstCell: "",
                secondCell: "",
                thirdCell: "Ведение беременности",
                fourthСell: "",
                title: true                      
            },
            {
                firstCell: "B01.001.004",
                secondCell: "100.21",
                thirdCell: "Прием врача-акушера-гинеколога беременной первичный (Комплекс: консультация врача, обследование в смотровом кабинете, инструментальный осмотр, для беременных, не состоящих на учете в клинике)",
                fourthСell: "1300,00"                       
            },
            {
                firstCell: "B01.001.005",
                secondCell: "100.21.1",
                thirdCell: "Прием врача-акушера-гинеколога беременной повторный  (Комплекс: :консультация врача, обследование в смотровом кабинете, инструментальный осмотр, для беременных, не состоящих на учете в клинике)",
                fourthСell: "950,00"                       
            },
            {
                firstCell: "B01.001.004",
                secondCell: "100.22",
                thirdCell: "Прием врача-акушера-гинеколога беременной первичный (Программа первичного обследования для постановки на учет и ведение беременности: скрининг УЗИ, скрининг биохимический, полное обследование и осмотр специалистов согласно приказу МЗ РФ)",
                fourthСell: "15000,00"                       
            },
            {
                firstCell: "B01.001.005 ",
                secondCell: "100.23",
                thirdCell: "Прием врача-акушера-гинеколога беременной повторный (Ведение беременности 1 триместр: скрининг УЗИ, скрининг биохимический, полное обследование и осмотр специалистов согласно приказу МЗ РФ, 2 посещения по 30 мин.)",
                fourthСell: "8000,00"                       
            },
            {
                firstCell: "B01.001.005",
                secondCell: "100.24",
                thirdCell: "Прием врача-акушера-гинеколога беременной повторный (Ведение беременности 2 триместр: скрининг УЗИ, скрининг биохимический, полное обследование согласно приказу МЗ РФ, 5 посещений по 30 мин.)",
                fourthСell: "13000,00"                       
            },
            {
                firstCell: "B01.001.005",
                secondCell: "100.25",
                thirdCell: "Прием врача-акушера-гинеколога беременной повторный (Ведение беременности 3 триместр: скрининг УЗИ, полное обследование, а так же: КТГ плода и осмотр специалистов согласно приказу МЗ РФ, 8 посещений по 30 мин.)",
                fourthСell: "26000,00"                       
            },
            {
                firstCell: "",
                secondCell: "",
                thirdCell: "Дистанционное консультирование",
                fourthСell: "",
                title: true                      
            },
            {
                firstCell: "В01.001.001",
                secondCell: "ДИС100.02",
                thirdCell: "Консультация врача-акушера-гинеколога первичная (репродуктолог, дистанционное информирование)",
                fourthСell: "500,00"                       
            },
            {
                firstCell: "В01.001.001",
                secondCell: "ДИС100.02.01",
                thirdCell: "Консультация врача-акушера-гинеколога  первичная (репродуктолог, кандидат медицинских наук, дистанционное информирование)",
                fourthСell: "600,00"                       
            },
            {
                firstCell: "B01.001.002",
                secondCell: "ДИС100.03",
                thirdCell: "Консультация врача-акушера-гинеколога повторная (репродуктолог, дистанционная)",
                fourthСell: "850,00"                       
            },
            {
                firstCell: "B01.001.002",
                secondCell: "ДИС100.03.01",
                thirdCell: "Консультация врача-акушера-гинеколога повторная (репродуктолог, кандидат медицинских наук, дистанционная)",
                fourthСell: "950,00"                       
            },
            {
                firstCell: "В01.001.001",
                secondCell: "ДИС100.06",
                thirdCell: "Консультация врача-акушера - гинеколога первичная (дистанционное информирование)",
                fourthСell: "500,00"                       
            },
            {
                firstCell: "B01.001.002",
                secondCell: "ДИС100.07",
                thirdCell: "Консультация врача-акушера - гинеколога повторная (дистанционная)",
                fourthСell: "800,00"                       
            },
            {
                firstCell: "B01.053.001",
                secondCell: "ДИС100.08",
                thirdCell: "Консультация врача-уролога первичная (дистанционное информирование)",
                fourthСell: "500,00"                       
            },
            {
                firstCell: "B01.053.002",
                secondCell: "ДИС100.09",
                thirdCell: "Консультация врача-уролога повторная (дистанционная)",
                fourthСell: "700,00"                       
            },
            {
                firstCell: "B01.053.002",
                secondCell: "ДИС100.09.2",
                thirdCell: "Консультация врача-уролога повторная (дистанционная, доктор медицинских наук)",
                fourthСell: "1350,00"                       
            },
            {
                firstCell: "B01.058.001",
                secondCell: "ДИС100.13",
                thirdCell: "Консультация врача-эндокринолога первичная (дистанционное информирование)",
                fourthСell: "500,00"                       
            },
            {
                firstCell: "B01.058.002",
                secondCell: "ДИС100.13.1",
                thirdCell: "Консультация врача-эндокринолога повторная (дистанционная)",
                fourthСell: "700,00"                       
            },
            {
                firstCell: "B01.047.002",
                secondCell: "ДИС100.15.1",
                thirdCell: "Консультация врача-терапевта повторная (дистанционная)",
                fourthСell: "600,00"                       
            },
            {
                firstCell: "",
                secondCell: "",
                thirdCell: "Функциональное обследование",
                fourthСell: "",
                title: true                       
            },
            {
                firstCell: "А01.30",
                secondCell: "Б100.44",
                thirdCell: "Пред-послерейсовый осмотр водителя",
                fourthСell: "100"                       
            },
            {
                firstCell: "1.2",
                secondCell: "100",
                thirdCell: "Процедуры и лечебные манипуляции",
                fourthСell: "",
                subChapter: true            
            },
            {
                firstCell: "1.2.1",
                secondCell: "",
                thirdCell: "Гинекология и урология",
                fourthСell: "",
                subSubChapter: true            
            },
            {
                firstCell: "A03.20.001",
                secondCell: "200.06",
                thirdCell: "Кольпоскопия (исследование шейки матки методом видеокольпоскопии)",
                fourthСell: "1050,00"                       
            },
            {
                firstCell: "A03.20.001",
                secondCell: "200.09",
                thirdCell: "Кольпоскопия",
                fourthСell: "1000,00"                       
            },
            {
                firstCell: "A03.20.005",
                secondCell: "200.08",
                thirdCell: "Вульвоскопия",
                fourthСell: "850,00"                       
            },
            {
                firstCell: "A16.20.036.001",
                secondCell: "200.30",
                thirdCell: "Электродиатермоконизация шейки матки (аргоноплазменная конизации)",
                fourthСell: "3500,00"                       
            },
            {
                firstCell: "A16.20.036.001",
                secondCell: "200.31",
                thirdCell: "Электродиатермоконизация шейки матки (радиоволновая конизация)",
                fourthСell: "3300,00"                       
            },
            {
                firstCell: "A16.20.090",
                secondCell: "200.22",
                thirdCell: "Снятие швов с шейки матки ",
                fourthСell: "850,00"                       
            },
            {
                firstCell: "A16.30.069",
                secondCell: "200.22.1",
                thirdCell: "Снятие послеоперационных швов (лигатур)",
                fourthСell: "850,00"                       
            },
            {
                firstCell: "A11.28.008",
                secondCell: "200.01",
                thirdCell: "Инстилляция мочевого пузыря  (капельное введение лекарственных средств в мочевой пузырь)",
                fourthСell: "800,00"                       
            },
            {
                firstCell: "A11.20.024",
                secondCell: "200.01.1",
                thirdCell: "Введение лекарственных препаратов интравагинально (в цервикальный канал)",
                fourthСell: "850,00"                       
            },
            {
                firstCell: "A11.20.024",
                secondCell: "200.01.2",
                thirdCell: "Введение лекарственных препаратов интравагинально  (введение препарата Антиадгезин 3г (противоспаечный гель) в полость матки, включая стоимость препарата)",
                fourthСell: "7000,00"                       
            },
            {
                firstCell: "A11.20.024",
                secondCell: "200.01.3",
                thirdCell: "Введение лекарственных препаратов интравагинально  (введение препарата Антиадгезин 5г (противоспаечный гель) в полость матки, включая стоимость препарата)",
                fourthСell: "7500,00"                       
            },
            {
                firstCell: "A11.20.024",
                secondCell: "200.55",
                thirdCell: "Введение лекарственных препаратов интравагинально  (введение препарата Мезогель в полость матки,  включая стоимость препарата)",
                fourthСell: "6500,00"                       
            },
            {
                firstCell: "A11.20.024",
                secondCell: "200.29",
                thirdCell: "Введение лекарственных препаратов интравагинально (в полость матки, без стоимости препарата)",
                fourthСell: "850,00"                       
            },
            {
                firstCell: "A11.20.024",
                secondCell: "200.29.1",
                thirdCell: "Введение лекарственных препаратов интравагинально (введение препарата Инстиллагель в полость матки, включая стоимость препарата)",
                fourthСell: "1200,00"                       
            },
            {
                firstCell: "A11.20.024",
                secondCell: "200.65",
                thirdCell: "Введение лекарственных препаратов интравагинально (ванночки, инстилляция препарата в цервикальный канал, без стоимости лекарственного препарата)",
                fourthСell: "700,00"                       
            },
            {
                firstCell: "A11.20.003 ",
                secondCell: "200.11",
                thirdCell: "Биопсия тканей матки (взятие биопсийного материала с шейки матки в амбулаторных условиях с гистологическим исследованием)",
                fourthСell: "3000,00"                       
            },
            {
                firstCell: "А11.20.",
                secondCell: "200.12",
                thirdCell: "Получение аспирата эндометрия на гистологическое исследование",
                fourthСell: "4500,00"                       
            },
            {
                firstCell: "А11.20.",
                secondCell: "200.13",
                thirdCell: "Получение аспирата эндометрия для бактериологического исследования",
                fourthСell: "4500,00"                       
            },
            {
                firstCell: "A11.20.005 ",
                secondCell: "200.17",
                thirdCell: "Получение влагалищного мазка",
                fourthСell: "300,00"                       
            },
            {
                firstCell: "A11.20.014 ",
                secondCell: "200.18",
                thirdCell: "Введение внутриматочной спирали  (под УЗИ контролем, без стоимости внутриматочной спирали)",
                fourthСell: "2000,00"                       
            },
            {
                firstCell: "A11.20.015",
                secondCell: "200.19",
                thirdCell: "Удаление внутриматочной спирали ",
                fourthСell: "1000,00"                       
            },
            {
                firstCell: "A11.20.013",
                secondCell: "200.54",
                thirdCell: "Тампонирование лечебное влагалища (без стоимости препаратов)",
                fourthСell: "250,00"                       
            },
            {
                firstCell: "A11.20.041",
                secondCell: "200.56",
                thirdCell: "Введение акушерского разгружающего поддерживающего кольца (пессария) (со стоимостью пессария)",
                fourthСell: "9000,00"                       
            },
            {
                firstCell: "A11.20.041",
                secondCell: "200.56.1",
                thirdCell: "Введение акушерского разгружающего поддерживающего кольца (пессария) (без стоимости пессария)",
                fourthСell: "1000,00"                       
            },
            {
                firstCell: "A11.20.041 ",
                secondCell: "200.57",
                thirdCell: "Введение акушерского разгружающего поддерживающего кольца (пессария) (примерка пессария) ",
                fourthСell: "300,00"                       
            },
            {
                firstCell: "A11.20.030",
                secondCell: "200.67",
                thirdCell: 'Внутриматочное введение эмбриона ("Пробный" перенос эмбриона в полость матки)',
                fourthСell: "2900,00"                       
            },
            {
                firstCell: "A11.21.004",
                secondCell: "200.04",
                thirdCell: "Сбор секрета простаты ",
                fourthСell: "750,00"                       
            },
            {
                firstCell: "A11.28.009",
                secondCell: "200.01.4",
                thirdCell: "Инстилляция  уретры (капельное введение лекарственных средств в уретру)",
                fourthСell: "800,00"                       
            },
            {
                firstCell: "A11.28.009",
                secondCell: "200.29.2",
                thirdCell: "Инстилляция  уретры (в уретру, без стоимости препарата)",
                fourthСell: "800,00"                       
            },
            {
                firstCell: "A11.28.006",
                secondCell: "200.52",
                thirdCell: "Получение уретрального отделяемого",
                fourthСell: "300,00"                       
            },
            {
                firstCell: "A16.01.017",
                secondCell: "200.24",
                thirdCell: "Удаление доброкачественных новообразований кожи  (удаление единичных папиллом/кондилом половых органов радиоволновым методом)",
                fourthСell: "2500,00"                       
            },
            {
                firstCell: "A16.01.017 ",
                secondCell: "200.25",
                thirdCell: "Удаление доброкачественных новообразований кожи (удаление множественных папиллом/кондилом половых органов радиоволновым методом)",
                fourthСell: "4500,00"                       
            },
            {
                firstCell: "A16.01.017",
                secondCell: "200.26",
                thirdCell: "Удаление доRC:R[20]Cброкачественных новообразований кожи (удаление папиллом/кондилом анагенитальной области)",
                fourthСell: "5700,00"                       
            },
            {
                firstCell: "A16.01.017",
                secondCell: "200.27",
                thirdCell: "Удаление доброкачественных новообразований кожи (аргоноплазменная вапоризация кондилом половых органов)",
                fourthСell: "4500,00"                       
            },
            {
                firstCell: "A16.01.017",
                secondCell: "200.28",
                thirdCell: "Удаление доброкачественных новообразований кожи (аргоноплазменная вапоризация папиллом/кондилом анагенитальной области)",
                fourthСell: "5800,00"                       
            },
            {
                firstCell: "A16.01.004",
                secondCell: "200.39",
                thirdCell: "Хирургическая обработка раны или инфицированной ткани  (обработка раны и наложение асептической повязки)",
                fourthСell: "250,00"                       
            },
            {
                firstCell: "A16.01.004",
                secondCell: "200.21",
                thirdCell: "Хирургическая обработка раны или инфицированной ткани (обработка швов на шейке матки, влагалище)",
                fourthСell: "750,00"                       
            },
            {
                firstCell: "A16.01.004",
                secondCell: "200.66",
                thirdCell: "Хирургическая обработка раны или инфицированной ткани (послеоперационная обработка шейки матки)",
                fourthСell: "350,00"                       
            },
            {
                firstCell: "A17.30.021",
                secondCell: "200.14",
                thirdCell: "Электрокоагуляция (аргоноплазменная коагуляция шейки матки)",
                fourthСell: "2700,00"                       
            },
            {
                firstCell: "A17.30.021",
                secondCell: "200.15",
                thirdCell: "Электрокоагуляция (радиоволновая коагуляция шейки матки)",
                fourthСell: "2500,00"                       
            },
            {
                firstCell: "A21.21.001",
                secondCell: "200.05",
                thirdCell: "Массаж простаты",
                fourthСell: "800,00"                       
            },
            {
                firstCell: "A25.20.001",
                secondCell: "200.15.1",
                thirdCell: "Назначение лекарственных препаратов при заболеваниях женских половых органов (диагностика и лечение патологии шейки матки)",
                fourthСell: "3670,00"                       
            },
            {
                firstCell: "A11.02.002",
                secondCell: "200.33",
                thirdCell: "Внутримышечное введение лекарственных препаратов  (без стоимости препаратов)",
                fourthСell: "120,00"                       
            },
            {
                firstCell: "A11.12.003",
                secondCell: "200.32",
                thirdCell: "Внутривенное введение лекарственных препаратов  (без стоимости одноразовой иглы с адаптером и лекарственных препаратов)",
                fourthСell: "200,00"                       
            },
            {
                firstCell: "A11.12.003.001",
                secondCell: "200.34",
                thirdCell: "Внутривенное введение лекарственных препаратов (включая стоимость одноразовой иглы с адаптером, без стоимости лекарственных препаратов)",
                fourthСell: "300,00"                       
            },            
            {
                firstCell: "A11.12.003.001",
                secondCell: "200.34.1",
                thirdCell: "Внутривенное введение лекарственных препаратов (включая стоимость одноразовой системы, окситоцина 5МЕ/1 мл , натрия хлорида 200 мл)",
                fourthСell: "500,00"                       
            },
            {
                firstCell: "A11.12.003.001",
                secondCell: "200.34.2",
                thirdCell: "Внутривенное введение лекарственных препаратов (без стоимости одноразовой системы, без стоимости лекарственных препаратов)",
                fourthСell: "400,00"                       
            },
            {
                firstCell: "A11.12.003.001",
                secondCell: "200.34.3",
                thirdCell: "Внутривенное введение лекарственных препаратов (включая стоимость одноразовой системы, без стоимости лекарственных препаратов)",
                fourthСell: "450,00"                       
            },
            {
                firstCell: "A11.12.009",
                secondCell: "01-00-001",
                thirdCell: "Взятие крови из периферической вены (с использованием вакуумной системы)",
                fourthСell: "150,00"                       
            },
            {
                firstCell: "A11.08.010.001",
                secondCell: "01-00-002",
                thirdCell: "Получение мазков со слизистой оболочки носоглотки",
                fourthСell: "100,00"                       
            },
            {
                firstCell: "A11.08.010.002",
                secondCell: "01-00-003",
                thirdCell: "Получение мазков со слизистой оболочки ротоглотки",
                fourthСell: "100,00"                       
            },
            {
                firstCell: "A11.21.005",
                secondCell: "600.72",
                thirdCell: "Биопсия предстательной железы (под ТрУЗИ контролем и гистология)",
                fourthСell: "6600,00"                       
            },
            {
                firstCell: "А23.02",
                secondCell: "200.23",
                thirdCell: "Постановка экспресс-теста на беременность",
                fourthСell: "220,00"                       
            },
            {
                firstCell: "1.2.2",
                secondCell: "",
                thirdCell: "Травматология",
                fourthСell: "",
                subSubChapter: true            
            },
            {
                firstCell: "A11.04.004",
                secondCell: "200.68",
                thirdCell: "Внутрисуставное введение лекарственных препаратов  (PRP терапия)",
                fourthСell: "8000,00"                       
            },
            {
                firstCell: "A11.04.005",
                secondCell: "200.68.1",
                thirdCell: "Пункция синовиальной сумки сустава",
                fourthСell: "900,00"                       
            },
            {
                firstCell: "A11.04.006",
                secondCell: "200.68.2",
                thirdCell: "Околосуставное введение лекарственных препаратов (без стоимости препаратов)",
                fourthСell: "900,00"                       
            },
            {
                firstCell: "A11.04.006",
                secondCell: "200.68.3",
                thirdCell: "Околосуставное введение лекарственных препаратов (со стоимостью препарата)",
                fourthСell: "1500,00"                       
            },
            {
                firstCell: "A16.04.051",
                secondCell: "200.68.4",
                thirdCell: "Внутрисуставное введение заменителей (протезов) синовиальной жидкости ( со стоимостью препарата Русвиск 1,6% 2 мл.)",
                fourthСell: "10000,00"                       
            },
            {
                firstCell: "A16.04.051",
                secondCell: "200.68.4.1",
                thirdCell: "Внутрисуставное введение заменителей (протезов) синовиальной жидкости ( со стоимостью препарата Русвиск 2,2% 3 мл.)",
                fourthСell: "15000,00"                       
            },
            {
                firstCell: "A16.04.051",
                secondCell: "200.68.4.2",
                thirdCell: "Внутрисуставное введение заменителей (протезов) синовиальной жидкости ( со стоимостью препарата Русвиск 2,2% 5 мл.)",
                fourthСell: "20000,00"                       
            },
            {
                firstCell: "A16.04.051",
                secondCell: "200.68.5",
                thirdCell: "Внутрисуставное введение заменителей (протезов) синовиальной жидкости  ( со стоимостью препарата Армавискон 1,5 % 2мл.)",
                fourthСell: "8500,00"                       
            },
            {
                firstCell: "A16.04.051",
                secondCell: "200.68.6",
                thirdCell: "Внутрисуставное введение заменителей (протезов) синовиальной жидкости ( со стоимостью препарата Гиапро 1,5 % 30 мг/2 мл.)",
                fourthСell: "10000,00"                       
            },
            {
                firstCell: "A16.04.051",
                secondCell: "200.68.6.1",
                thirdCell: "Внутрисуставное введение заменителей (протезов) синовиальной жидкости ( со стоимостью препарата Гиапро 2 % 40 мг/2 мл.)",
                fourthСell: "15000,00"                       
            },
            {
                firstCell: "A16.04.051",
                secondCell: "200.68.6.2",
                thirdCell: "Внутрисуставное введение заменителей (протезов) синовиальной жидкости ( со стоимостью препарата Гиапро Макс 2 % 40 мг/2 мл.)",
                fourthСell: "20000,00"                       
            },
            {
                firstCell: "A16.30.069",
                secondCell: "200.22.1",
                thirdCell: "Снятие послеоперационных швов (лигатур)",
                fourthСell: "850,00"                       
            },
            {
                firstCell: "A15.04.003",
                secondCell: "200.69",
                thirdCell: "Наложение повязки при операциях на суставах",
                fourthСell: "550,00"                       
            },
            {
                firstCell: "1.3",
                secondCell: "",
                thirdCell: "Услуги отделения УЗД (ультразвуковой диагностики)",
                fourthСell: "",
                subChapter: true            
            },
            {
                firstCell: "1.3.1",
                secondCell: "",
                thirdCell: "Акушерство и гинекология",
                fourthСell: "",
                subSubChapter: true            
            },
            {
                firstCell: "A04.20.001.001",
                secondCell: "600.01",
                thirdCell: "Ультразвуковое исследование матки и придатков трансвагиальное",
                fourthСell: "1000,00"                       
            },
            {
                firstCell: "A04.20.001",
                secondCell: "600.02",
                thirdCell: "Ультразвуковое исследование матки и придатков трансабдоминальное",
                fourthСell: "700,00"                       
            },
            {
                firstCell: "A04.20.001.002",
                secondCell: "600.04",
                thirdCell: "Ультразвуковое исследование матки и придатков трансректальное",
                fourthСell: "900,00"                       
            },
            {
                firstCell: "A04.20.001 A04.20.001.001",
                secondCell: "600.05",
                thirdCell: "Ультразвуковое исследование матки и придатков трансвагиальное/ трансабдоминальное (в динамике, в течение 1 месяца)",
                fourthСell: "700,00"                       
            },
            {
                firstCell: "B01.052.001",
                secondCell: "600.06",
                thirdCell: "Осмотр врача ультразвуковой диагностики  (УЗ-мониторинг овуляции: включает оценку эндометрия и фолликулярного аппарата яичников, на аппарате экспертного класса)",
                fourthСell: "800,00"                       
            },
            {
                firstCell: "B01.001.002",
                secondCell: "200.16",
                thirdCell: "Прием врача-акушера-гинеколога повторный (ультразвуковой мониторинг овуляции: включает оценку эндометрия и фолликулярного аппарата яичников)",
                fourthСell: "700,00"                       
            },
            {
                firstCell: "A04.20.001.004",
                secondCell: "600.07",
                thirdCell: "Ультразвуковое исследование шейки матки (УЗ-цервикометрия)",
                fourthСell: "800,00"                       
            },
            {
                firstCell: "A04.30.010",
                secondCell: "600.08",
                thirdCell: "Ультразвуковое исследование органов малого таза (комплексное) (трехмерная реконструкция органов малого таза (у женщин)",
                fourthСell: "900,00"                       
            },
            {
                firstCell: "",
                secondCell: "",
                thirdCell: "Исследования при сроке беременности до 11 недель",
                fourthСell: "",
                title: true
            },
            {
                firstCell: "A04.30.001",
                secondCell: "600.09",
                thirdCell: "Ультразвуковое исследование плода  (при сроке беременности до 11 недель)",
                fourthСell: "1000,00"                       
            },
            {
                firstCell: "A04.30.001",
                secondCell: "600.10",
                thirdCell: "Ультразвуковое исследование плода (при сроке беременности до 11 недель, многоплодная беременность (двойня)",
                fourthСell: "1100,00"                       
            },
            {
                firstCell: "A04.30.001",
                secondCell: "600.11",
                thirdCell: "Ультразвуковое исследование плода (при сроке беременности до 11 недель, многоплодная беременность (тройня)",
                fourthСell: "1200,00"                       
            },
            {
                firstCell: "",
                secondCell: "",
                thirdCell: "Исследования при сроке беременности 11-18 недель",
                fourthСell: "",
                title: true
            },
            {
                firstCell: "A04.30.001",
                secondCell: "600.15",
                thirdCell: "Ультразвуковое исследование плода (при сроке беременности  11-18 недель, демонстрация родственникам и фотография в подарок)",
                fourthСell: "1300,00"                       
            },
            {
                firstCell: "A04.30.001",
                secondCell: "600.16",
                thirdCell: "Ультразвуковое исследование плода (при сроке беременности  11-18 недель, многоплодная беременность (двойня), демонстрация родственникам и фотография в подарок)",
                fourthСell: "1400,00"                       
            },
            {
                firstCell: "A04.30.001",
                secondCell: "600.17",
                thirdCell: "Ультразвуковое исследование плода (при сроке беременности  11-18 недель многоплодная беременность (тройня), демонстрация родственникам и фотография в подарок)",
                fourthСell: "1450,00"                       
            },
            {
                firstCell: "A04.30.001",
                secondCell: "600.18",
                thirdCell: "Ультразвуковое исследование плода (при сроке беременности  11-18 недель с допплерографией и цветным картированием маточно-плацентарно-плодового комплекса)",
                fourthСell: "1500,00"                       
            },
            {
                firstCell: "A04.30.001",
                secondCell: "600.19",
                thirdCell: "Ультразвуковое исследование плода ( при сроке беременности  11-18 недель с допплерографией и цветным картированием маточно-плацентарно-плодового комплекса, многоплодная беременность (двойня)",
                fourthСell: "1650,00"                       
            },
            {
                firstCell: "A04.30.001",
                secondCell: "600.20",
                thirdCell: "Ультразвуковое исследование плода (при сроке беременности  11-18 недель с допплерографией и цветным картированием маточно-плацентарно-плодового комплекса, многоплодная беременность (тройня)",
                fourthСell: "1700,00"                       
            },
            {
                firstCell: "A04.30.001",
                secondCell: "600.24",
                thirdCell: "Ультразвуковое исследование плода  (при сроке беременности до 18 недель, в режиме 3D/4D, демонстрация родственникам, запись на DVD )",
                fourthСell: "2400,00"                       
            },
            {
                firstCell: "A04.30.001",
                secondCell: "600.25",
                thirdCell: "Ультразвуковое исследование плода (при сроке беременности до 18 недель (двойня), в режиме 3D/4D, демонстрация родственникам, запись на DVD)",
                fourthСell: "3000,00"                       
            },
            {
                firstCell: "A04.30.001",
                secondCell: "600.26",
                thirdCell: "Ультразвуковое исследование плода (при сроке беременности до 18 недель (тройня), в режиме 3D/4D, демонстрация родственникам, запись на DVD)",
                fourthСell: "3300,00"                       
            },
            {
                firstCell: "A04.12.024",
                secondCell: "600.27",
                thirdCell: "Ультразвуковая допплерография маточно-плацентарного кровотока  (при сроке беременности до 18 недель, цветное картирование)",
                fourthСell: "1000,00"                       
            },
            {
                firstCell: "A04.12.024.001",
                secondCell: "600.28",
                thirdCell: "Ультразвуковая допплерография маточно-плацентарного кровотока  (при сроке беременности до 18 недель (двойня), цветное картирование)",
                fourthСell: "1050,00"                       
            },
            {
                firstCell: "A04.12.024.002",
                secondCell: "600.29",
                thirdCell: "Ультразвуковая допплерография маточно-плацентарного кровотока  (при сроке беременности до 18 недель (тройня), цветное картирование)",
                fourthСell: "1100,00"                       
            },
            {
                firstCell: "",
                secondCell: "",
                thirdCell: "Исследования при сроке беременности  18-30 недель",
                fourthСell: "",
                title: true
            },
            {
                firstCell: "A04.30.001",
                secondCell: "600.30",
                thirdCell: "Ультразвуковое исследование плода (при сроке беременности 18-30 недель, демонстрация родственникам и фотография в подарок) ",
                fourthСell: "1500,00"                       
            },
            {
                firstCell: "A04.30.001",
                secondCell: "600.31",
                thirdCell: "Ультразвуковое исследование плода (при сроке беременности 18-30 недель (двойня), демонстрация родственникам и фотография в подарок)",
                fourthСell: "1600,00"                       
            },
            {
                firstCell: "A04.30.001",
                secondCell: "600.32",
                thirdCell: "Ультразвуковое исследование плода (при сроке беременности 18-30 недель (тройня), демонстрация родственникам и фотография в подарок)",
                fourthСell: "1700,00"                       
            },
            {
                firstCell: "A04.30.001",
                secondCell: "600.33",
                thirdCell: "Ультразвуковое исследование плода (при сроке беременности  18-30 недель с допплерографией и цветным картированием маточно-плацентарно-плодового комплекса)",
                fourthСell: "1700,00"                       
            },
            {
                firstCell: "A04.30.001",
                secondCell: "600.34",
                thirdCell: "Ультразвуковое исследование плода ( при сроке беременности  18-30 недель с допплерографией и цветным картированием маточно-плацентарно-плодового комплекса, многоплодная беременность (двойня)",
                fourthСell: "1900,00"                       
            },
            {
                firstCell: "A04.30.001",
                secondCell: "600.35",
                thirdCell: "Ультразвуковое исследование плода (при сроке беременности 18-30 недель с допплерографией и цветным картированием маточно-плацентарно-плодового комплекса, многоплодная беременность (тройня)",
                fourthСell: "2000,00"                       
            },
            {
                firstCell: "A04.30.001",
                secondCell: "600.39",
                thirdCell: "Ультразвуковое исследование плода  (при сроке беременности 18-30 недель, в режиме 3D/4D, демонстрация родственникам, запись на DVD ) ",
                fourthСell: "2400,00"                       
            },
            {
                firstCell: "A04.30.001",
                secondCell: "600.40",
                thirdCell: "Ультразвуковое исследование плода (при сроке беременности 18-30 недель (двойня), в режиме 3D/4D, демонстрация родственникам, запись на DVD)",
                fourthСell: "3000,00"                       
            },
            {
                firstCell: "A04.30.001",
                secondCell: "600.41",
                thirdCell: "Ультразвуковое исследование плода (при сроке беременности  18-30  недель (тройня), в режиме 3D/4D, демонстрация родственникам, запись на DVD)",
                fourthСell: "3500,00"                       
            },
            {
                firstCell: "A04.12.024",
                secondCell: "600.42",
                thirdCell: "Ультразвуковая допплерография маточно-плацентарного кровотока  (при сроке беременности 18-30 недель, цветное картирование)",
                fourthСell: "1000,00"                       
            },
            {
                firstCell: "A04.12.024",
                secondCell: "600.43",
                thirdCell: "Ультразвуковая допплерография маточно-плацентарного кровотока  (при сроке беременности 18-30 недель (двойня), цветное картирование)",
                fourthСell: "1100,00"                       
            },
            {
                firstCell: "A04.12.024",
                secondCell: "600.44",
                thirdCell: "Ультразвуковая допплерография маточно-плацентарного кровотока  (при сроке беременности 18-30 недель (тройня), цветное картирование)",
                fourthСell: "1200,00"                       
            },
            {
                firstCell: "",
                secondCell: "",
                thirdCell: "Исследования при сроке беременности более 30 недель",
                fourthСell: "",
                title: true
            },
            {
                firstCell: "A04.30.001",
                secondCell: "600.45",
                thirdCell: "Ультразвуковое исследование плода (при сроке беременности более 30 недель, демонстрация родственникам и фотография в подарок)",
                fourthСell: "1300,00"                       
            },
            {
                firstCell: "A04.30.001",
                secondCell: "600.46",
                thirdCell: "Ультразвуковое исследование плода (при сроке беременности более 30 недель (двойня), демонстрация родственникам и фотография в подарок)",
                fourthСell: "1700,00"                       
            },
            {
                firstCell: "A04.30.001",
                secondCell: "600.47",
                thirdCell: "Ультразвуковое исследование плода (при сроке беременности более 30 недель (тройня), демонстрация родственникам и фотография в подарок)",
                fourthСell: "2600,00"                       
            },
            {
                firstCell: "A04.30.001",
                secondCell: "600.48",
                thirdCell: "Ультразвуковое исследование плода (при сроке беременности  более 30 недель с допплерографией и цветным картированием маточно-плацентарно-плодового комплекса)",
                fourthСell: "1600,00"                       
            },
            {
                firstCell: "A04.30.001",
                secondCell: "600.49",
                thirdCell: "Ультразвуковое исследование плода ( при сроке беременности  более 30 недель с допплерографией и цветным картированием маточно-плацентарно-плодового комплекса, многоплодная беременность (двойня)",
                fourthСell: "2000,00"                       
            },
            {
                firstCell: "A04.30.001",
                secondCell: "600.50",
                thirdCell: "Ультразвуковое исследование плода (при сроке беременности более 30 недель с допплерографией и цветным картированием маточно-плацентарно-плодового комплекса, многоплодная беременность (тройня)",
                fourthСell: "2100,00"                       
            },
            {
                firstCell: "A04.30.001",
                secondCell: "600.54",
                thirdCell: "Ультразвуковое исследование плода  (при сроке беременности более 30 недель, в режиме 3D/4D, демонстрация родственникам, запись на DVD ) ",
                fourthСell: "2400,00"                       
            },
            {
                firstCell: "A04.30.001",
                secondCell: "600.55",
                thirdCell: "Ультразвуковое исследование плода (при сроке беременности более 30 недель (двойня), в режиме 3D/4D, демонстрация родственникам, запись на DVD)",
                fourthСell: "3000,00"                       
            },
            {
                firstCell: "A04.30.001",
                secondCell: "600.56",
                thirdCell: "Ультразвуковое исследование плода (при сроке беременности  более 30  недель (тройня), в режиме 3D/4D, демонстрация родственникам, запись на DVD)",
                fourthСell: "3500,00"                       
            },
            {
                firstCell: "A04.12.024",
                secondCell: "600.57",
                thirdCell: "Ультразвуковая допплерография маточно-плацентарного кровотока  (при сроке беременности более 30 недель, цветное картирование)",
                fourthСell: "1000,00"                       
            },
            {
                firstCell: "A04.12.024",
                secondCell: "600.58",
                thirdCell: "Ультразвуковая допплерография маточно-плацентарного кровотока  (при сроке беременности более 30 недель (двойня), цветное картирование)",
                fourthСell: "1100,00"                       
            },
            {
                firstCell: "A04.12.024",
                secondCell: "600.59",
                thirdCell: "Ультразвуковая допплерография маточно-плацентарного кровотока  (при сроке беременности более 30 недель (тройня), цветное картирование)",
                fourthСell: "1200,00"                       
            },
            {
                firstCell: "1.3.2",
                secondCell: "",
                thirdCell: "УЗИ органов малого таза, брюшной полости, забрюшинного пространства и мочеполовой системы",
                fourthСell: "",
                subSubChapter: true            
            },
            {
                firstCell: "B03.052.001",
                secondCell: "600.60",
                thirdCell: "Комплексное ультразвуковое исследование внутренних органов (брюшная полость: печень, желчный пузырь, поджелудочная железа, селезенка)",
                fourthСell: "1000,00"                       
            },
            {
                firstCell: "B03.052.001",
                secondCell: "600.61",
                thirdCell: "Комплексное ультразвуковое исследование внутренних органов (органы брюшной полости и почки)",
                fourthСell: "1400,00"                       
            },
            {
                firstCell: "B03.052.001",
                secondCell: "600.62",
                thirdCell: "Комплексное ультразвуковое исследование внутренних органов (почки, надпочечники и забрюшинное пространство)",
                fourthСell: "700,00"                       
            },
            {
                firstCell: "A04.28.002.001",
                secondCell: "600.63",
                thirdCell: "Ультразвуковое исследование почек  (допплерография почек с цветным картированием)",
                fourthСell: "900,00"                       
            },
            {
                firstCell: "A04.28.002.003",
                secondCell: "600.64",
                thirdCell: "Ультразвуковое исследование мочевого пузыря",
                fourthСell: "700,00"                       
            },
            {
                firstCell: "A04.09.001",
                secondCell: "600.65",
                thirdCell: "Ультразвуковое исследование плевральной полости",
                fourthСell: "700,00"                       
            },
            {
                firstCell: "A04.18.001 ",
                secondCell: "600.66",
                thirdCell: "Ультразвуковое исследование толстой кишки",
                fourthСell: "700,00"                       
            },
            {
                firstCell: "A04.19.001.001",
                secondCell: "600.67",
                thirdCell: "Ультразвуковое исследование прямой кишки трансректальное (с допплерографией и цветным картированием)",
                fourthСell: "800,00"                       
            },
            {
                firstCell: "A04.21.001",
                secondCell: "600.68",
                thirdCell: "Ультразвуковое исследование предстательной железы ",
                fourthСell: "800,00"                       
            },
            {
                firstCell: "A04.21.001.001 ",
                secondCell: "600.69",
                thirdCell: "Ультразвуковое исследование предстательной железы трансректальное ",
                fourthСell: "800,00"                       
            },
            {
                firstCell: "A04.21.001.001",
                secondCell: "600.69",
                thirdCell: "Ультразвуковое исследование предстательной железы трансректальное",
                fourthСell: "800,00"                       
            },
            {
                firstCell: "A04.21.001",
                secondCell: "600.70",
                thirdCell: "Ультразвуковое исследование предстательной железы  (Комплекс: предстательная железа и мочевой пузырь, определение остаточной мочи (трансабдоминально)",
                fourthСell: "1300,00"                       
            },
            {
                firstCell: "A04.21.001.001",
                secondCell: "600.71",
                thirdCell: "Ультразвуковое исследование предстательной железы трансректальное ( с допплерографией и цветным картированием)",
                fourthСell: "900,00"                       
            },
            {
                firstCell: "A04.28.003",
                secondCell: "600.74",
                thirdCell: "Ультразвуковое исследование органов мошонки (с допплерографией)",
                fourthСell: "900,00"                       
            },
            {
                firstCell: "B03.052.001",
                secondCell: "Б600.91",
                thirdCell: "Комплексное ультразвуковое исследование внутренних органов (трансвагинальное: тазовое дно и уретра)",
                fourthСell: "1400,00"                       
            },
            {
                firstCell: "1.3.3",
                secondCell: "",
                thirdCell: "Поверхностные структуры",
                fourthСell: "",
                subSubChapter: true            
            },
            {
                firstCell: "A04.22.001 ",
                secondCell: "600.80",
                thirdCell: "Ультразвуковое исследование щитовидной железы и паращитовидных желез  (с регионарными лимфатическими узлами и допплерографией щитовидной железы с цветным картированием)",
                fourthСell: "900,00"                       
            },
            {
                firstCell: "A04.20.002",
                secondCell: "600.82",
                thirdCell: "Ультразвуковое исследование молочных желез (с регионарными лимфоузлами)",
                fourthСell: "900,00"                       
            },
            {
                firstCell: "A04.07.002",
                secondCell: "600.83",
                thirdCell: "Ультразвуковое исследование слюнных желез",
                fourthСell: "800,00"                       
            },
            {
                firstCell: "A04.06.002",
                secondCell: "600.84",
                thirdCell: "Ультразвуковое исследование лимфатических узлов (одна анатомическая зона) при системных заболеваниях",
                fourthСell: "800,00"                       
            },
            {
                firstCell: "A04.06.002",
                secondCell: "600.84.1",
                thirdCell: "Ультразвуковое исследование лимфатических узлов (все группы лимфатических узлов при системных заболеваниях)",
                fourthСell: "1400,00"                       
            },
            {
                firstCell: "A04.01.001",
                secondCell: "600.85",
                thirdCell: "Ультразвуковое исследование мягких тканей (одна анатомическая зона)",
                fourthСell: "700,00"                       
            },
            {
                firstCell: "1.3.4",
                secondCell: "",
                thirdCell: "Сердечно-сосудистая система",
                fourthСell: "",
                subSubChapter: true            
            },
            {
                firstCell: "A04.10.002",
                secondCell: "600.86",
                thirdCell: "Эхокардиография (УЗИ сердца с допплеровским анализом)",
                fourthСell: "1000,00"                       
            },
            {
                firstCell: "A04.12.",
                secondCell: "600.87",
                thirdCell: "Цветное допплеровское картирование и допплерометрия сосудов матки",
                fourthСell: "1000,00"                       
            },
            {
                firstCell: "A04.12.",
                secondCell: "Б600.92",
                thirdCell: "УЗДГ МАГ и шеи",
                fourthСell: "1000,00"                       
            },
            {
                firstCell: "A04.12.002",
                secondCell: "Б600.93",
                thirdCell: "Ультразвуковая допплерография сосудов (артерий) верхних конечностей",
                fourthСell: "1000,00"                       
            },
            {
                firstCell: "A04.12.002.001",
                secondCell: "Б600.94",
                thirdCell: "Ультразвуковая допплерография сосудов (артерий) нижних конечностей ",
                fourthСell: "1000,00"                       
            },
            {
                firstCell: "A04.12.002.001",
                secondCell: "Б600.95",
                thirdCell: "Ультразвуковая допплерография вен нижних конечностей",
                fourthСell: "1000,00"                       
            },
            {
                firstCell: "A04.12.002",
                secondCell: "Б600.96",
                thirdCell: "Ультразвуковая допплерография вен верхних конечностей ",
                fourthСell: "1000,00"                       
            },
            {
                firstCell: "1.3.5",
                secondCell: "",
                thirdCell: "Ультразвуковая диагностика (дети)",
                fourthСell: "",
                subSubChapter: true            
            },
            {
                firstCell: "A04.20.002 ",
                secondCell: "600.82.1",
                thirdCell: "Ультразвуковое исследование молочных желез (с регионарными лимфоузлами у детей)",
                fourthСell: "750,00"                       
            },
            {
                firstCell: "B03.052.001",
                secondCell: "600.98",
                thirdCell: "Комплексное ультразвуковое исследование внутренних органов (брюшная полость, почки, забрюшинное пространство)",
                fourthСell: "1200,00"                       
            },
            {
                firstCell: "B03.052.001",
                secondCell: "600.99",
                thirdCell: "Комплексное ультразвуковое исследование внутренних органов (брюшная полость, почки)",
                fourthСell: "1000,00"                       
            },
            {
                firstCell: "B03.052.001",
                secondCell: "600.60.1",
                thirdCell: "Комплексное ультразвуковое исследование внутренних органов (брюшная полость: печень, желчный пузырь, поджелудочная железа, селезенка)",
                fourthСell: "870,00"                       
            },
            {
                firstCell: "A04.07.002",
                secondCell: "600.83",
                thirdCell: "Ультразвуковое исследование слюнных желез",
                fourthСell: "700,00"                       
            },
            {
                firstCell: "A04.28.002.001",
                secondCell: "600.63.1",
                thirdCell: "Ультразвуковое исследование почек (с допплерографией сосудов)",
                fourthСell: "800,00"                       
            },
            {
                firstCell: "A04.28.002.001 A04.28.002.005",
                secondCell: "600.64.1",
                thirdCell: "Ультразвуковое исследование почек и мочевого пузыря (с определением остаточной мочи)",
                fourthСell: "1150,00"                       
            },
            {
                firstCell: "A04.28.002.005",
                secondCell: "600.100",
                thirdCell: "Ультразвуковое исследование мочевого пузыря с определением остаточной мочи",
                fourthСell: "650,00"                       
            },
            {
                firstCell: "B03.052.001",
                secondCell: "600.101",
                thirdCell: "Комплексное ультразвуковое исследование внутренних органов (почки и надпочечники)",
                fourthСell: "700,00"                       
            },
            {
                firstCell: "A04.21.001",
                secondCell: "600.68.1",
                thirdCell: "Ультразвуковое исследование предстательной железы",
                fourthСell: "700,00"                       
            },
            {
                firstCell: "A04.22.001",
                secondCell: "600.80.1",
                thirdCell: "Ультразвуковое исследование щитовидной железы и паращитовидных желез  (ЦДК и регионарными лимфатическими узлами)",
                fourthСell: "750,00"                       
            },
            {
                firstCell: "A04.28.003",
                secondCell: "600.104",
                thirdCell: "Ультразвуковое исследование органов мошонки (с допплерографией и пробой Вальсальвы)",
                fourthСell: "700,00"                       
            },
            {
                firstCell: "A04.01.001",
                secondCell: "600.105",
                thirdCell: "Ультразвуковое исследование мягких тканей",
                fourthСell: "600,00"                       
            },
            {
                firstCell: "A04.30.010",
                secondCell: "600.106",
                thirdCell: "Ультразвуковое исследование органов малого таза (комплексное), трансобдоминально",
                fourthСell: "700,00"                       
            },
            {
                firstCell: "1.4",
                secondCell: "",
                thirdCell: "Функциональная диагностика",
                fourthСell: "",
                subChapter: true            
            },
            {
                firstCell: "A05.10.006",
                secondCell: " Б1002.1",
                thirdCell: "Регистрация электрокардиограммы (ЭКГ с расшифровкой)",
                fourthСell: "450,00"                       
            },
            {
                firstCell: "A05.10.006",
                secondCell: "Б1002.2",
                thirdCell: "Регистрация электрокардиограммы (ЭКГ с описанием и интерпретацией данных)",
                fourthСell: "650,00"                       
            },
            {
                firstCell: "A05.10.006",
                secondCell: "100.36",
                thirdCell: "Регистрация электрокардиограммы (ЭКГ без описания)",
                fourthСell: "330,00"                       
            },
            {
                firstCell: "A05.30.001",
                secondCell: "200.50",
                thirdCell: "Кардиотокография плода",
                fourthСell: "1000,00"                       
            },
            {
                firstCell: "Раздел 2",
                secondCell: "300",
                thirdCell: "Оперативное лечение ",
                fourthСell: "",
                chapter: true            
            },
            {
                firstCell: "2.1",
                secondCell: "",
                thirdCell: "Гинекология и урология",
                fourthСell: "",
                subChapter: true            
            },
            {
                firstCell: "A16.20.084",
                secondCell: "300.11",
                thirdCell: "Удаление полипа женских половых органов (удаление полипа шейки матки с гистологическим исследованием)",
                fourthСell: "6500,00"                       
            },
            {
                firstCell: "A16.20.084",
                secondCell: "300.11.1",
                thirdCell: "Удаление полипа женских половых органов (удаление полипа шейки матки без гистологического исследования)",
                fourthСell: "4800,00"                       
            },
            {
                firstCell: "A16.20.084",
                secondCell: "300.12",
                thirdCell: "Удаление полипа женских половых органов (удаление полипа цервикального канала с выскабливанием цервикального канала)",
                fourthСell: "5700,00"                       
            },
            {
                firstCell: "A16.20.059.001",
                secondCell: "300.13",
                thirdCell: "Удаление новообразования влагалища  (удаление кисты влагалища размером до 2-х см)",
                fourthСell: "8000,00"                       
            },
            {
                firstCell: "A16.20.059.001",
                secondCell: "300.14",
                thirdCell: "Удаление новообразования влагалища (удаление кисты влагалища размером свыше 2-х см)",
                fourthСell: "9000,00"                       
            },
            {
                firstCell: "A16.20.059.001",
                secondCell: "300.16",
                thirdCell: "Удаление новообразования влагалища (удаление кисты бартолиниевой железы) ",
                fourthСell: "8500,00"                       
            },
            {
                firstCell: "A11.20.040",
                secondCell: "300.21",
                thirdCell: "Биопсия вульвы",
                fourthСell: "5800,00"                       
            },
            {
                firstCell: "A16.20.021",
                secondCell: "300.17",
                thirdCell: "Рассечение девственной плевы",
                fourthСell: "8500,00"                       
            },
            {
                firstCell: "A16.20.060",
                secondCell: "300.18",
                thirdCell: "Восстановление девственной плевы",
                fourthСell: "12000,00"                       
            },
            {
                firstCell: "A16.20.060",
                secondCell: "300.18.1",
                thirdCell: "Восстановление девственной плевы (долговременная)",
                fourthСell: "15000,00"                       
            },
            {
                firstCell: "A16.20.065",
                secondCell: "300.19",
                thirdCell: "Рассечение перегородки влагалища (удаление)",
                fourthСell: "10000,00"                       
            },
            {
                firstCell: "A16.20.098",
                secondCell: "300.20",
                thirdCell: "Пластика малых половых губ",
                fourthСell: "18000,00"                       
            },
            {
                firstCell: "A03.20.003",
                secondCell: "300.04",
                thirdCell: "Гистероскопия (диагностическая, с в/в обезболиванием)",
                fourthСell: "15000,00"                       
            },
            {
                firstCell: "A03.20.003",
                secondCell: "300.27",
                thirdCell: "Гистерорезектоскопия ( I ст. сложности: в/в обезболивание, удаление полипов, гиперплазия эндометрия)",
                fourthСell: "16000,00"                       
            },
            {
                firstCell: "A03.20.003",
                secondCell: "300.27.1",
                thirdCell: "Гистерорезектоскопия ( II ст. сложности: в/в обезболивание, рассечение синехий)",
                fourthСell: "17000,00"                       
            },
            {
                firstCell: "A03.20.003",
                secondCell: "300.27.2",
                thirdCell: "Гистерорезектоскопия (III ст. сложности: в/в обезболивание, удаление миоматозных узлов, лигатур)",
                fourthСell: "18000,00"                       
            },
            {
                firstCell: "A03.20.003",
                secondCell: "300.27.3",
                thirdCell: "Гистерорезектоскопия (I ст. сложности: в/в обезболивание, удаление полипов эндометрия, удаление полипов цервикального канала, гистологическое исследование)",
                fourthСell: "19000,00"                       
            },
            {
                firstCell: "A03.28.001",
                secondCell: "300.01",
                thirdCell: "Цистоскопия (осмотр мочевого пузыря с помощью оптики)",
                fourthСell: "3900,00"                       
            },
            {
                firstCell: "A03.28.001",
                secondCell: "300.02",
                thirdCell: "Цистоскопия (взятие биопсии)",
                fourthСell: "4000,00"                       
            },
            {
                firstCell: "A11.20.008.001",
                secondCell: "300.09",
                thirdCell: "Раздельное диагностическое выскабливание полости матки  (диагностическое, с гистологическим исследованием)",
                fourthСell: "9000,00"                       
            },
            {
                firstCell: "A11.20.008.001",
                secondCell: "300.10",
                thirdCell: "Раздельное диагностическое выскабливание полости матки  (с гистологическим исследованием, в/в обезболиванием)",
                fourthСell: "9500,00"                       
            },
            {
                firstCell: "A11.20.008.002",
                secondCell: "300.07",
                thirdCell: "Раздельное диагностическое выскабливание цервикального канала  (в амбулаторных условиях, взятие материала с шейки матки для гистологического исследования)",
                fourthСell: "3700,00"                       
            },
            {
                firstCell: "A11.20.007",
                secondCell: "300.30",
                thirdCell: "Пункция кисты яичника и аспирация экссудата",
                fourthСell: "13200,00"                       
            },
            {
                firstCell: "А16.20",
                secondCell: "300.28",
                thirdCell: "Бужирование цервикального канала (без обезболивания)",
                fourthСell: "1500,00"                       
            },
            {
                firstCell: "А16.20",
                secondCell: "300.29",
                thirdCell: "Бужирование цервикального канала (с в/в обезболиванием)",
                fourthСell: "6700,00"                       
            },
            {
                firstCell: "A16.01.018",
                secondCell: "300.15",
                thirdCell: "Удаление доброкачественных новообразований подкожно-жировой клетчатки (удаление липом/атером половых органов)",
                fourthСell: "5600,00"                       
            },
            {
                firstCell: "А16.20",
                secondCell: "300.08",
                thirdCell: "Инструментальная дилатация цервикального канала при его атрезии",
                fourthСell: "4000,00"                       
            },
            {
                firstCell: "A16.21.038",
                secondCell: "300.22",
                thirdCell: "Пластика уздечки крайней плоти",
                fourthСell: "6500,00"                       
            },
            {
                firstCell: "A16.21.038",
                secondCell: "300.22.1",
                thirdCell: "Пластика уздечки крайней плоти (с в/в обезболиванием)",
                fourthСell: "8000,00"                       
            },
            {
                firstCell: "A16.21.013",
                secondCell: "300.23",
                thirdCell: "Обрезание крайней плоти (при фимозе)",
                fourthСell: "9000,00"                       
            },
            {
                firstCell: "А16.21",
                secondCell: "300.24",
                thirdCell: "Хирургическое лечение водянки яичек (гидроцеле)",
                fourthСell: "12000,00"                       
            },
            {
                firstCell: "А16.21",
                secondCell: "300.25",
                thirdCell: "Хирургическое лечение варикоцеле",
                fourthСell: "10000,00"                       
            },
            {
                firstCell: "A11.12.003",
                secondCell: "300.06",
                thirdCell: "Внутривенное введение лекарственных препаратов (обезболивание, при проведении малых операций)",
                fourthСell: "3100,00"                       
            },
            {
                firstCell: "2.2",
                secondCell: "",
                thirdCell: "Травматология",
                fourthСell: "",
                subChapter: true            
            },
            {
                firstCell: "A03.04.001",
                secondCell: "300.33",
                thirdCell: "Артроскопия диагностическая (коленный сустав)",
                fourthСell: "28000,00"                       
            },
            {
                firstCell: "A03.04.001",
                secondCell: "300.33.3",
                thirdCell: "Артроскопия диагностическая 2 степени сложности (коленный сустав)",
                fourthСell: "40000,00"                       
            },
            {
                firstCell: "A16.01.004",
                secondCell: "300.32",
                thirdCell: "Хирургическая обработка раны или инфицированной ткани (обработка раны, наложение швов)",
                fourthСell: "2500,00"                       
            },
            {
                firstCell: "A16.01.027.001",
                secondCell: "300.31",
                thirdCell: "Удаление ногтевой пластинки с клиновидной резекцией матрикса (вросший ноготь)",
                fourthСell: "3000,00"                       
            },
            {
                firstCell: "A16.04.047",
                secondCell: "300.33.1",
                thirdCell: "Артроскопическая санация сустава (I ст. сложности: рассечение медиопателлярной складки, синовэктомия коленного сустава)",
                fourthСell: "49000,00"                       
            },
            {
                firstCell: "A16.04.047",
                secondCell: "300.33.2",
                thirdCell: "Артроскопическая санация сустава (II ст. сложности: расширенная синовэктомия, резекция тел мениска, удаление свободных тел)",
                fourthСell: "54000,00"                       
            },
            {
                firstCell: "А16.02",
                secondCell: "300.34",
                thirdCell: "Хирургическое  лечение синдрома карпального канала",
                fourthСell: "7000,00"                       
            },
            {
                firstCell: "А16.02",
                secondCell: "300.35",
                thirdCell: "Хирургическое лечение лигаментита",
                fourthСell: "7000,00"                       
            },
            {
                firstCell: "А16.02",
                secondCell: "300.36",
                thirdCell: "Хирургическое лечение болезни де Кервена",
                fourthСell: "7000,00"                       
            },
            {
                firstCell: "А16.04",
                secondCell: "300.37",
                thirdCell: "Хирургическое лечение гигромы",
                fourthСell: "7500,00"                       
            },
            {
                firstCell: "А16.02",
                secondCell: "300.38",
                thirdCell: "Хирургическое лечение синдрома канала Гийона",
                fourthСell: "7000,00"                       
            },
            {
                firstCell: "A16.02.004",
                secondCell: "300.39",
                thirdCell: "Иссечение контрактуры Дюпюитрена (I ст. сложности)",
                fourthСell: "8000,00"                       
            },
            {
                firstCell: "A16.02.004",
                secondCell: "300.39.1",
                thirdCell: "Иссечение контрактуры Дюпюитрена (II ст. сложности)",
                fourthСell: "13000,00"                       
            },
            {
                firstCell: "A16.02.004",
                secondCell: "300.39.2",
                thirdCell: "Иссечение контрактуры Дюпюитрена  (Игольная апоневротомия)",
                fourthСell: "8000,00"                       
            },
            {
                firstCell: "А16.04",
                secondCell: "300.40",
                thirdCell: "Иссечение препателлярной бурсы коленного сустава",
                fourthСell: "8000,00"                       
            },
            {
                firstCell: "А16.04",
                secondCell: "300.40.1",
                thirdCell: "Удаление бурсы локтевого сустава",
                fourthСell: "7000,00"                       
            },
            {
                firstCell: "A16.03.089",
                secondCell: "300.41",
                thirdCell: "Удаление экзостоза, хондромы (остеохондропатия бугристости большеберцовой кости) ",
                fourthСell: "18000,00"                       
            },
            {
                firstCell: "A16.03.014.002",
                secondCell: "300.42",
                thirdCell: "Удаление инородного тела кости экстрамедуллярных металлоконструкций",
                fourthСell: "23000,00"                       
            },
            {
                firstCell: "A16.04.037.003",
                secondCell: "300.43",
                thirdCell: "Пластика боковой связки коленного сустава",
                fourthСell: "28000,00"                       
            },
            {
                firstCell: "A16.03.028.002",
                secondCell: "300.44",
                thirdCell: "Остеосинтез ключицы",
                fourthСell: "28000,00"                       
            },
            {
                firstCell: "Раздел 3",
                secondCell: "400",
                thirdCell: "Лаборатория ВРТ (вспомогательные репродуктивные технологии)",
                fourthСell: "",
                chapter: true            
            },
            {
                firstCell: "A11.20.034 ",
                secondCell: "400.05",
                thirdCell: "Внутриматочное введение спермы мужа (партнера)",
                fourthСell: "10000,00"                       
            },
            {
                firstCell: "A11.20.035",
                secondCell: "400.05.01",
                thirdCell: "Внутриматочное введение спермы донора",
                fourthСell: "10000,00"                       
            },
            {
                firstCell: "A11.21.002",
                secondCell: "400.07",
                thirdCell: "Биопсия яичка, придатка яичка и семенного канатика  (комплекс: тестикулярная биопсия с целью получения жизнеспособных сперматозоидов, в/в обезболивание, пребывание в палате с наблюдением)",
                fourthСell: "20000,00"                       
            },
            {
                firstCell: "A11.21.002",
                secondCell: "400.08",
                thirdCell: "Биопсия яичка, придатка яичка и семенного канатика (комплекс: тестикулярная биопсия  с целью получения жизнеспособных сперматозоидов, местное обезболивание, пребывание в палате с наблюдением)",
                fourthСell: "18000,00"                       
            },
            {
                firstCell: "",
                secondCell: "400.11",
                thirdCell: "Цикл лечения методом ЭКО без стоимости препаратов. В том числе:",
                fourthСell: "97500,00",
                title: true
            },
            {
                firstCell: "B01.001.002",
                secondCell: "400.11.01",
                thirdCell: "Прием врача-акушера-гинеколога повторный  (Выбор тактики лечения, овариальная гиперстимуляция без стоимости препаратов,коррекция стимуляции суперовуляции, УЗИ мониторинг созревания фолликулов)",
                fourthСell: "21500,00"                       
            },
            {
                firstCell: "A11.20.019",
                secondCell: "400.11.02",
                thirdCell: "Трансвагинальная пункция фолликулов яичников (ТВП, консультация врача-анестезиолога-реаниматолога, пребывание в палате с наблюдением)",
                fourthСell: "38000,00"                       
            },
            {
                firstCell: "A11.20.027 A11.20.028",
                secondCell: "400.11.03",
                thirdCell: "Экстракорпоральное оплодотворение ооцитов и культивирование эмбрионов",
                fourthСell: "27000,00"                       
            },
            {
                firstCell: "A11.20.030",
                secondCell: "400.11.04",
                thirdCell: "Внутриматочное введение эмбриона",
                fourthСell: "11000,00"                       
            },
            {
                firstCell: "A11.20.030.001",
                secondCell: "400.11.04.01",
                thirdCell: "Внутриматочное введение эмбриона (с применением специализированной среды с гиалуроновой кислотой)",
                fourthСell: "18000,00"                       
            },
            {
                firstCell: "А11.20.024.006",
                secondCell: "400.11.04.02",
                thirdCell: "Введение лекарственных препаратов интравагинально  (применение специализированной среды с гиалуроновой кислотой)",
                fourthСell: "7000,00"                       
            },
            {
                firstCell: "",
                secondCell: "400.12",
                thirdCell: "Повторный цикл лечения методом ЭКО без стоимости препаратов. В том числе:",
                fourthСell: "89000,00",
                title: true
            },
            {
                firstCell: "B01.001.002",
                secondCell: "400.12.01",
                thirdCell: "Прием врача-акушера-гинеколога повторный (Выбор тактики лечения, овариальная гиперстимуляция без стоимости препаратов, коррекция стимуляции суперовуляции, УЗИ мониторинг созревания фолликулов. Для пациентов, проходящих повторный цикл ЭКО)",
                fourthСell: "18500,00"                       
            },
            {
                firstCell: "A11.20.019",
                secondCell: "400.12.02",
                thirdCell: "Трансвагинальная пункция фолликулов яичников (ТВП, консультация врача-анестезиолога-реаниматолога, пребывание в палате с наблюдением)",
                fourthСell: "36500,00"                       
            },
            {
                firstCell: "A11.20.027 A11.20.028",
                secondCell: "400.12.03",
                thirdCell: "Экстракорпоральное оплодотворение ооцитов и культивирование эмбрионов (для пациентов, проходящих повторный цикл лечения методом ЭКО)",
                fourthСell: "24000,00"                       
            },
            {
                firstCell: "A11.20.030",
                secondCell: "400.12.04",
                thirdCell: "Внутриматочное введение эмбриона  (для пациентов, проходящих повторный цикл лечения методом ЭКО)",
                fourthСell: "10000,00"                       
            },
            {
                firstCell: "A11.20.030",
                secondCell: "400.12.04.01",
                thirdCell: "Внутриматочное введение эмбриона (с применением специализированной среды с гиалуроновой кислотой)",
                fourthСell: "17000,00"                       
            },
            {
                firstCell: "А11.20.024",
                secondCell: "400.11.04.02",
                thirdCell: "Введение лекарственных препаратов интравагинально  (применение специализированной среды с гиалуроновой кислотой)",
                fourthСell: "7000,00"                       
            },
            {
                firstCell: "",
                secondCell: "400.13",
                thirdCell: "Цикл лечения методом ЭКО в натуральном цикле. В том числе:",
                fourthСell: "81000,00",
                title: true
            },
            {
                firstCell: "B01.001.002",
                secondCell: "400.13.01",
                thirdCell: "Прием врача-акушера-гинеколога повторный (ультразвуковой мониторинг созревания фолликулов)",
                fourthСell: "5000,00"                       
            },
            {
                firstCell: "A11.20.019",
                secondCell: "400.13.02",
                thirdCell: "Трансвагинальная пункция фолликулов яичников (ТВП, консультация врача-анестезиолога-реаниматолога, пребывание в палате с наблюдением)",
                fourthСell: "38000,00"                       
            },
            {
                firstCell: "A11.20.027 A11.20.028 ",
                secondCell: "400.13.03",
                thirdCell: "Экстракорпоральное оплодотворение ооцитов и культивирование эмбрионов",
                fourthСell: "27000,00"                       
            },
            {
                firstCell: "A11.20.030",
                secondCell: "400.13.04",
                thirdCell: "Внутриматочное введение эмбриона",
                fourthСell: "11000,00"                       
            },
            {
                firstCell: "A11.20.030",
                secondCell: "400.11.04.01",
                thirdCell: "Внутриматочное введение эмбриона (с применением специализированной среды с гиалуроновой кислотой)",
                fourthСell: "18000,00"                       
            },
            {
                firstCell: "A11.20.024",
                secondCell: "400.11.04.02",
                thirdCell: "Введение лекарственных препаратов интравагинально  (применение специализированной среды с гиалуроновой кислотой)",
                fourthСell: "7000,00"                       
            },
            {
                firstCell: "",
                secondCell: "",
                thirdCell: "Донорский биоматериал / донор",
                fourthСell: "",
                title: true
            },
            {
                firstCell: "",
                secondCell: "400.31",
                thirdCell: "Предоставление законсервированной спермы (2 соломины для процедуры ЭКО) (г.Казань)",
                fourthСell: "26000,00"                       
            },
            {
                firstCell: "",
                secondCell: "400.31.1",
                thirdCell: "Предоставление законсервированной спермы (1 доза), г. Москва",
                fourthСell: "60000,00"                       
            },
            {
                firstCell: "",
                secondCell: "400.31.2",
                thirdCell: "Предоставление законсервированной спермы (3 соломины для процедуры ВМИ) г. Казань",
                fourthСell: "29000,00"                       
            },
            {
                firstCell: "",
                secondCell: "400.32",
                thirdCell: "Предоставление донорских ооцитов (6 шт.)",
                fourthСell: "180000,00"                       
            },
            {
                firstCell: "",
                secondCell: "400.33",
                thirdCell: "Предоставление донорских ооцитов (7 шт.)",
                fourthСell: "195000,00"                       
            },
            {
                firstCell: "",
                secondCell: "400.34",
                thirdCell: "Предоставление донорских ооцитов (8 шт.)",
                fourthСell: "210000,00"                       
            },
            {
                firstCell: "",
                secondCell: "400.32-5",
                thirdCell: "Предоставление донорских ооцитов (9 шт.)",
                fourthСell: "225000,00"                       
            },
            {
                firstCell: "",
                secondCell: "400.32-6",
                thirdCell: "Предоставление донорских ооцитов (10 шт.)",
                fourthСell: "240000,00"                       
            },
            {
                firstCell: "",
                secondCell: "",
                thirdCell: "Методы ЭКО, хетчинг",
                fourthСell: "",
                title: true
            },
            {
                firstCell: "A11.20.027",
                secondCell: "400.15",
                thirdCell: "Экстракорпоральное оплодотворение ооцитов (оплодотворение методом ИКСИ (свыше 3-х клеток), осуществляется дополнительно к процедуре ЭКО)",
                fourthСell: "30000,00"                       
            },
            {
                firstCell: "A11.20.027",
                secondCell: "400.15.1",
                thirdCell: "Экстракорпоральное оплодотворение ооцитов (оплодотворение методом ПИКСИ, осуществляется дополнительно к процедуре ИКСИ)",
                fourthСell: "16000,00"                       
            },
            {
                firstCell: "A11.20.027",
                secondCell: "400.15.2",
                thirdCell: "Экстракорпоральное оплодотворение ооцитов (оплодотворение методом ИКСИ (1- 3 клетки), осуществляется дополнительно к процедуре ЭКО)",
                fourthСell: "20000,00"                       
            },
            {
                firstCell: "A11.20.029",
                secondCell: "400.16",
                thirdCell: "Вспомогательный хетчинг (рассечение блестящей оболочки) эмбриона",
                fourthСell: "6000,00"                       
            },
            {
                firstCell: "",
                secondCell: "",
                thirdCell: "Криоконсервация, разморозка, хранение биоматериала",
                fourthСell: "",
                title: true
            },
            {
                firstCell: "A11.20.031",
                secondCell: "400.17",
                thirdCell: "Криоконсервация эмбрионов  (Комплекс: криоконсервация методом витрификации  (1-3) эмбрионов и их хранение в течение 2-х лет)",
                fourthСell: "15000,00"                       
            },
            {
                firstCell: "A11.20.031",
                secondCell: "400.17.1",
                thirdCell: "Криоконсервация эмбрионов  (Комплекс: криоконсервация методом витрификации  (4-х и более) эмбрионов и их хранение в течение 2-х лет)",
                fourthСell: "21000,00"                       
            },
            {
                firstCell: "A11.20.031",
                secondCell: "400.17.1.1",
                thirdCell: "Криоконсервация эмбрионов  (Комплекс: криоконсервация методом витрификации  (6-8 эмбрионов) и их хранение в течение 6 мес.)",
                fourthСell: "16500,00"                       
            },
            {
                firstCell: "A11.20.031",
                secondCell: "400.17.4",
                thirdCell: "Криоконсервация эмбрионов (комплекс: витрификация (1-3)  эмбрионов и  хранение в течение 3-х месяцев)",
                fourthСell: "10000,00"                       
            },
            {
                firstCell: "A11.20.031",
                secondCell: "400.17.5",
                thirdCell: "Криоконсервация эмбрионов (комплекс: витрификация (1-3)  эмбрионов и  хранение в течение 1 месяца)",
                fourthСell: "9500,00"                       
            },
            {
                firstCell: "A11.20.032",
                secondCell: "400.17.2",
                thirdCell: "Криоконсервация гамет (комплекс: витрификация ооцитов (до 6-ти клеток) и хранение их в течение 2-х лет)",
                fourthСell: "22000,00"                       
            },
            {
                firstCell: "A11.20.032",
                secondCell: "400.17.2.1",
                thirdCell: "Криоконсервация гамет (комплекс: витрификация ооцитов (до 6-ти клеток) и хранение их в течение 6 месяцев)",
                fourthСell: "13800,00"                       
            },
            {
                firstCell: "A11.20.032",
                secondCell: "400.17.2.2",
                thirdCell: "Криоконсервация гамет (комплекс: витрификация ооцитов (до 6-ти клеток)",
                fourthСell: "12000,00"                       
            },
            {
                firstCell: "A11.20.032",
                secondCell: "400.17.3",
                thirdCell: "Криоконсервация гамет (комплекс: витрификация ооцитов (более 6-ти клеток) и хранение их в течение 2-х лет)",
                fourthСell: "25000,00"                       
            },
            {
                firstCell: "A11.20.032",
                secondCell: "400.17.3.1",
                thirdCell: "Криоконсервация гамет (комплекс: витрификация ооцитов (более 6 клеток) и хранение их в течение 9 месяцев)",
                fourthСell: "17500,00"                       
            },
            {
                firstCell: "A11.20.032",
                secondCell: "400.19",
                thirdCell: "Криоконсервация гамет (комплекс: криоконсервация тестикулярного биоптата, спермы мужа и хранение в течение 1 месяца)",
                fourthСell: "10000,00"                       
            },
            {
                firstCell: "A11.20.032",
                secondCell: "400.21.1",
                thirdCell: "Криоконсервация гамет (комплекс : криоконсервация и хранение в течение 1 месяца спермы мужа (до двух сдач)",
                fourthСell: "12500,00"                       
            },
            {
                firstCell: "A11.20.032",
                secondCell: "400.21",
                thirdCell: "Криоконсервация гамет (комплекс: криоконсервация и хранение в течение 1 месяца спермы мужа (до трех сдач)",
                fourthСell: "14000,00"                       
            },
            {
                firstCell: "A11.20.032",
                secondCell: "400.19.2",
                thirdCell: "Криоконсервация гамет (комплекс: криоконсервация тестикулярного биоптата, спермы мужа и хранение в течение 1 года)",
                fourthСell: "15000,00"                       
            },
            {
                firstCell: "A11.20.032",
                secondCell: "400.21.3",
                thirdCell: "Криоконсервация гамет (комплекс : криоконсервация и хранение до 1 года спермы мужа (до двух сдач)",
                fourthСell: "16200,00"                       
            },
            {
                firstCell: "A11.20.032",
                secondCell: "400.21.2",
                thirdCell: "Криоконсервация гамет (комплекс: криоконсервация и хранение в течение 1 года спермы мужа (до трех сдач)",
                fourthСell: "17500,00"                       
            },
            {
                firstCell: "A11.20.",
                secondCell: "400.18",
                thirdCell: "Разморозка ооцитов",
                fourthСell: "12000,00"                       
            },
            {
                firstCell: "A11.20.",
                secondCell: "400.18.1",
                thirdCell: "Разморозка эмбрионов",
                fourthСell: "12500,00"                       
            },
            {
                firstCell: "A11.20.",
                secondCell: "400.19.1",
                thirdCell: "Разморозка тестикулярного биоптата, спермы",
                fourthСell: "4000,00"                       
            },
            {
                firstCell: "A11.20.032",
                secondCell: "400.20",
                thirdCell: "Криоконсервация гамет (хранение эмбрионов, спермы, тестикулярного биоптата за каждый последующий месяц)",
                fourthСell: "750,00"                       
            },
            {
                firstCell: "A11.20.032",
                secondCell: "400.20.1",
                thirdCell: "Криоконсервация гамет (хранение эмбрионов, спермы, тестикулярного биоптата в течение 1 года (при оплате 10 месяцев хранения  2 месяца Вы получаете в подарок)",
                fourthСell: "7500,00"                       
            },
            {
                firstCell: "",
                secondCell: "",
                thirdCell: "Исследование эякулята",
                fourthСell: "",
                title: true
            },
            {
                firstCell: "B03.053.002",
                secondCell: "400.22",
                thirdCell: "Спермограмма (для цикла ЭКО по критериям Крюгера: спермограмма по ВОЗ, MAR - тест, исследование морфологии)",
                fourthСell: "3000,00"                       
            },
            {
                firstCell: "B03.053.002",
                secondCell: "400.22В",
                thirdCell: "Спермограмма (для цикла ЭКО по критериям Крюгера)",
                fourthСell: "2450,00"                       
            },
            {
                firstCell: "A11.21.010",
                secondCell: "400.22.1",
                thirdCell: "Обработка спермы для проведения процедуры экстракорпорального оплодотворения (осуществляется дополнительно к спермограмме для цикла ЭКО по критериям Крюгера)",
                fourthСell: "3000,00"                       
            },
            {
                firstCell: "B03.053.002",
                secondCell: "400.23",
                thirdCell: "Спермограмма",
                fourthСell: "1000,00"                       
            },
            {
                firstCell: "A12.21.001",
                secondCell: "400.24",
                thirdCell: "Микроскопическое исследование спермы  (MAR тест с использованием IgG)",
                fourthСell: "1800,00"                       
            },
            {
                firstCell: "A12.21.001",
                secondCell: "400.25",
                thirdCell: "Микроскопическое исследование спермы (исследование морфологии эякулята по критериям Крюгера)",
                fourthСell: "1500,00"                       
            },
            {
                firstCell: "A12.21.001",
                secondCell: "400.26",
                thirdCell: "Микроскопическое исследование спермы (тест на криотолерантность сперматозоидов, комплексное исследование)",
                fourthСell: "4500,00"                       
            },
            {
                firstCell: "A12.21.001",
                secondCell: "400.27",
                thirdCell: "Микроскопическое исследование спермы (анализ на среду)",
                fourthСell: "2300,00"                       
            },
            {
                firstCell: "A12.21.001",
                secondCell: "400.29",
                thirdCell: "Микроскопическое исследование спермы (проба Шуварского, посткоитальный тест)",
                fourthСell: "800,00"                       
            },
            {
                firstCell: "A12.21.001",
                secondCell: "400.37",
                thirdCell: "Микроскопическое исследование спермы (НВА тест на связывание сперматозоидов с гиалуроновой кислотой)",
                fourthСell: "6900,00"                       
            },
            {
                firstCell: "A12.21.001",
                secondCell: "400.46",
                thirdCell: "Микроскопическое исследование спермы (Тест-LeucoScreen определение пероксидаза-положительных лейкоцитов в сперме)",
                fourthСell: "1500,00"                       
            },
            {
                firstCell: "B03.045.030",
                secondCell: "700.70",
                thirdCell: "Комплекс исследований для установления родословных кровнородственных связей (ДНК-профилирование (25-маркеров) 1 человек)",
                fourthСell: "6800,00"                       
            },
            {
                firstCell: "",
                secondCell: "",
                thirdCell: "Биопсия эмбрионов, ПГД",
                fourthСell: "",
                title: true
            },
            {
                firstCell: "A11.30.010",
                secondCell: "400.38",
                thirdCell: "Биопсия эмбриона  (биопсия трофэктодермы бластоцисты (1 эмбриона на 5-6 день развития)",
                fourthСell: "12000,00"                       
            },
            {
                firstCell: "A11.30.010.001",
                secondCell: "400.39",
                thirdCell: "Биопсия эмбриона (биопсия трофэктодермы бластоцисты (2 эмбрионов на 5-6 день развития)",
                fourthСell: "13000,00"                       
            },
            {
                firstCell: "A11.30.010.002",
                secondCell: "400.40",
                thirdCell: "Биопсия эмбриона (биопсия трофэктодермы бластоцисты (3 эмбрионов на 5-6 день развития)",
                fourthСell: "17500,00"                       
            },
            {
                firstCell: "A11.30.010.004",
                secondCell: "400.41.1",
                thirdCell: "Биопсия эмбриона (биопсия трофэктодермы бластоцисты (4 эмбрионов на 5-6 день развития)",
                fourthСell: "19000,00"                       
            },
            {
                firstCell: "A11.30.010.005",
                secondCell: "400.41.2",
                thirdCell: "Биопсия эмбриона (биопсия трофэктодермы бластоцисты (5 эмбрионов на 5-6 день развития)",
                fourthСell: "24500,00"                       
            },
            {
                firstCell: "A11.30.010.006",
                secondCell: "400.41.3",
                thirdCell: "Биопсия эмбриона (биопсия трофэктодермы бластоцисты (6 эмбрионов на 5-6 день развития)",
                fourthСell: "27000,00"                       
            },
            {
                firstCell: "A11.30.010.007",
                secondCell: "400.41.4",
                thirdCell: "Биопсия эмбриона (биопсия трофэктодермы бластоцисты (7 эмбрионов на 5-6 день развития)",
                fourthСell: "33000,00"                       
            },
            {
                firstCell: "A11.30.010.008",
                secondCell: "400.41.5",
                thirdCell: "Биопсия эмбриона (биопсия трофэктодермы бластоцисты (8 эмбрионов на 5-6 день развития)",
                fourthСell: "35500,00"                       
            },
            {
                firstCell: "А10.20.001.001",
                secondCell: "400.42",
                thirdCell: "Преимплантационное генетическое исследование на анеуплоидии (ПГТ-А)  (исследование 1 эмбриона методом NGS)",
                fourthСell: "35000,00"                       
            },
            {
                firstCell: "А10.20.001.001",
                secondCell: "400.42.1",
                thirdCell: "Преимплантационное генетическое исследование на анеуплоидии (ПГТ-А) (исследование 2 эмбрионов методом NGS)",
                fourthСell: "60000,00"                       
            },
            {
                firstCell: "А10.20.001.001",
                secondCell: "400.42.2",
                thirdCell: "Преимплантационное генетическое исследование на анеуплоидии (ПГТ-А)  (исследование 3 эмбрионов методом NGS)",
                fourthСell: "80000,00"                       
            },
            {
                firstCell: "А10.20.001.001",
                secondCell: "400.42.3",
                thirdCell: "Преимплантационное генетическое исследование на анеуплоидии (ПГТ-А)  (исследование 4 эмбрионов методом NGS",
                fourthСell: "95000,00"                       
            },
            {
                firstCell: "А10.20.001.001",
                secondCell: "400.42.8",
                thirdCell: "Преимплантационное генетическое исследование на анеуплоидии (ПГТ-А)  (исследование 5 эмбрионов методом NGS)",
                fourthСell: "115000,00"                       
            },
            {
                firstCell: "А10.20.001.001",
                secondCell: "400.42.4",
                thirdCell: "Преимплантационное генетическое исследование на анеуплоидии (ПГТ-А)  (исследование 6 эмбрионов методом NGS)",
                fourthСell: "135000,00"                       
            },
            {
                firstCell: "А10.20.001.001",
                secondCell: "400.42.5",
                thirdCell: "Преимплантационное генетическое исследование на анеуплоидии (ПГТ-А) (исследование 7 эмбрионов методом NGS)",
                fourthСell: "157500,00"                       
            },
            {
                firstCell: "А10.20.001.001",
                secondCell: "400.42.6",
                thirdCell: "Преимплантационное генетическое исследование на анеуплоидии (ПГТ-А)  (исследование 8 эмбрионов методом NGS)",
                fourthСell: "180000,00"                       
            },
            {
                firstCell: "А10.20.001.002",
                secondCell: "400.42.9",
                thirdCell: "Преимплантационное генетическое исследование на моногенные заболевания (ПГТ-М)",
                fourthСell: "170000,00"                       
            },
            {
                firstCell: "",
                secondCell: "500",
                thirdCell: "Дневной стационар",
                fourthСell: "",
                title: true
            },
            {
                firstCell: "A25.20.004",
                secondCell: "500.01",
                thirdCell: "Назначение лекарственных препаратов при беременности  (лечение раннего токсикоза в сроке до 12 недель беременности, размещение в дневном стационаре, 1 пациенто-день: пребывание, обследование, наблюдение со стоимостью препаратов). Минимальный срок лечения 7 пациенто-дней",
                fourthСell: "2000,00"                       
            },
            {
                firstCell: "A25.20.004",
                secondCell: "500.02",
                thirdCell: "Назначение лекарственных препаратов при беременности (лечение фетоплацентарной недостаточности в сроке до 34 недель беременности, размещение в дневном стационаре). Минимальный срок лечения 6 пациенто-дней",
                fourthСell: "2200,00"                       
            },
            {
                firstCell: "A25.20.004",
                secondCell: "500.03",
                thirdCell: "Назначение лекарственных препаратов при беременности (лечение угрозы прерывания беременности I и II триместров, размещение в дневном стационаре, 1 пациенто-день: пребывание, обследование, наблюдение со стоимостью препаратов). Минимальный срок лечения 7 пациенто-дней",
                fourthСell: "2000,00"                       
            },
            {
                firstCell: "A25.20.001",
                secondCell: "500.04",
                thirdCell: "Назначение лекарственных препаратов при заболеваниях женских половых органов  (внутривенное переливание после пункции фолликулов для профилактики СГЯ)",
                fourthСell: "1600,00"                       
            },
            {
                firstCell: "A25.20.001",
                secondCell: "500.05",
                thirdCell: "Назначение лекарственных препаратов при заболеваниях женских половых органов (лечение СГЯ легкой степени, размещение в дневном стационаре, 1 пациенто-день) Минимальный срок лечения 5 пациенто-дней",
                fourthСell: "2300,00"                       
            },
            {
                firstCell: "A25.20.001",
                secondCell: "500.06",
                thirdCell: "Назначение лекарственных препаратов при заболеваниях женских половых органов (лечение хронических воспалительных заболеваний мочеполовой системы, размещение в дневном стационаре, 1 койко-день: пребывание, обследование, наблюдение со стоимостью препаратов)",
                fourthСell: "2300,00"                       
            },
            {
                firstCell: "A25.20.001",
                secondCell: "500.07",
                thirdCell: "Назначение лекарственных препаратов при заболеваниях женских половых органов (пребывание и наблюдение в дневном стационаре, 1 пациенто-день без стоимости препаратов)",
                fourthСell: "1300,00"                       
            },
            {
                firstCell: "A25.20.001",
                secondCell: "500.08",
                thirdCell: "Назначение лекарственных препаратов при заболеваниях женских половых органов (оказание помощи при обильном кровотечении, размещение в дневном стационаре, 1 пациенто/день  со стоимостью медикаментов)",
                fourthСell: "1700,00"                       
            },
            {
                firstCell: "Раздел 4",
                secondCell: "",
                thirdCell: "Лабораторные исследования",
                fourthСell: "",
                chapter: true            
            },
            {
                firstCell: "",
                secondCell: "700",
                thirdCell: "Генетические исследования",
                fourthСell: "",
                title: true
            },
            {
                firstCell: "B03.045.031",
                secondCell: "700.1",
                thirdCell: "Тест на родство «Универсальный»",
                fourthСell: "18000,00"                       
            },
            {
                firstCell: "B03.045.031",
                secondCell: "700.1.1",
                thirdCell: "Тест на родство «Универсальный» (досудебная, судебная экспертиза)",
                fourthСell: "20000,00"                       
            },
            {
                firstCell: "B03.045.031",
                secondCell: "700.2",
                thirdCell: "Тест на отцовство/материнство (дуэт) 20 маркеров",
                fourthСell: "13000,00"                       
            },
            {
                firstCell: "B03.045.031",
                secondCell: "700.2.1",
                thirdCell: "Дополнительный участник исследования, 20 маркеров",
                fourthСell: "6000,00"                       
            },
            {
                firstCell: "B03.045.031",
                secondCell: "700.2.1.1",
                thirdCell: "Тест на отцовство/материнство (дуэт, трио) 20 маркеров (судебная/досудебная экспертиза)",
                fourthСell: "17000,00"                       
            },
            {
                firstCell: "B03.045.031",
                secondCell: "700.5",
                thirdCell: "Тест на отцовство/материнство ТРИО, 20 маркеров (3 участника: 1 предполагаемый родитель, 1 безусловный родитель, 1 ребенок)",
                fourthСell: "14000,00"                       
            },
            {
                firstCell: "B03.045.031",
                secondCell: "700.7",
                thirdCell: "ТЕСТ НА РОДСТВО, 20 маркеров (исследуются аутосомные маркеры, 2 участника: дедушка/бабушка - внук/внучка, дядя/тетя - племянник/племянница (авункулярный тест), родные/сводные братья/сестры (полно- и полусиблинговый, близнецовый тест",
                fourthСell: "13500,00"                       
            },
            {
                firstCell: "B03.045.031",
                secondCell: "700.8",
                thirdCell: "ТЕСТ НА РОДСТВО, 24 маркера (исследуются аутосомные маркеры, 2 участника: дедушка/бабушка - внук/внучка, дядя/тетя - племянник/племянница (авункулярный тест), родные/сводные братья/сестры (полно- и полусиблинговый, близнецовый тест",
                fourthСell: "17000,00"                       
            },
            {
                firstCell: "B03.045.031",
                secondCell: "700.10",
                thirdCell: "ТЕСТ на родство, тестирование Х-хромосом (2 участника: бабушка по линии отца, внучка)",
                fourthСell: "16000,00"                       
            },
            {
                firstCell: "B03.045.031",
                secondCell: "700.11",
                thirdCell: "ТЕСТ на родство, тестирование Х-хромосом (2 участника: бабушка по линии отца, внучка), досудебная/судебная экспертиза",
                fourthСell: "22000,00"                       
            },
            {
                firstCell: "B03.045.031",
                secondCell: "700.13",
                thirdCell: "ТЕСТ НА РОДСТВО, тестирование Y-хромосомы (2 участника: дедушка по линии отца - внук, дядя - племянник, родные/сводные по отцу братья)",
                fourthСell: "16000,00"                       
            },
            {
                firstCell: "B03.045.031",
                secondCell: "700.26",
                thirdCell: "ТЕСТ на отцовство/материнство (дуэт, трио) 20 маркеров (досудебная экспертиза)",
                fourthСell: "15000,00"                       
            },
            {
                firstCell: "B03.045.031",
                secondCell: "700.44",
                thirdCell: "ВЫДЕЛЕНИЕ ДНК ИЗ НЕСТАНДАРТНОГО ОБРАЗЦА 1 ТИПА (волосы с корешками; ногти; окурок; жевательная резинка; зубная щетка; ушная сера; детская соска-пустыш",
                fourthСell: "5000,00"                       
            },
            {
                firstCell: "B03.045.031",
                secondCell: "ВКР 700.1",
                thirdCell: "Неинвазивный тест по дородовому установлению отцовствства",
                fourthСell: "90000,00"                       
            },
            {
                firstCell: "B03.006",
                secondCell: "НИПТ31",
                thirdCell: "Неинвазивный пренатальный тест (расширенная панель, 31 синдром)+определение пола плода",
                fourthСell: "35000,00"                       
            },
            {
                firstCell: "B03.006",
                secondCell: "НИПТ7",
                thirdCell: "Неинвазивный пренатальный тест (стандартная панель 7 синдромов)+определение пола плода",
                fourthСell: "30000,00"                       
            },
            {
                firstCell: "B03.006",
                secondCell: "НИПС1",
                thirdCell: "Неинвазивный пренатальный ДНК скрининг на синдром Дауна + определение пола плода",
                fourthСell: "23000,00"                       
            },
            {
                firstCell: "",
                secondCell: "700.9era",
                thirdCell: "Определение уровня рецептивности эндометрия - тест ERA",
                fourthСell: "60000,00"                       
            },
        ],
        bebelya: [
            {
                firstCell: "Раздел 1",
                secondCell: "100",
                thirdCell: "Консультационно - диагностическое отделение",
                fourthСell: "",
                chapter: true            
            },
            {
                firstCell: "1.1",
                secondCell: "",
                thirdCell: "Врачебные лечебно-диагностические услуги",
                fourthСell: "",
                subChapter: true            
            },
            {
                firstCell: "В01.001.001",
                secondCell: "100.01",
                thirdCell: "Прием врача-акушера-гинеколога первичный (комплексный осмотр репродуктолога: консультация врача, инструментальный осмотр, УЗИ обследование)",
                fourthСell: "1800,00"                       
            },
            {
                firstCell: "В01.001.001",
                secondCell: "100.02",
                thirdCell: "Прием врача-акушера-гинеколога первичный (репродуктолог)",
                fourthСell: "1100,00"                       
            },
            {
                firstCell: "В01.001.001",
                secondCell: "100.02.01",
                thirdCell: "Прием врача-акушера-гинеколога первичный (репродуктолог, кандидат медицинских наук)",
                fourthСell: "1200,00"                       
            },
            {
                firstCell: "B01.001.002",
                secondCell: "100.03",
                thirdCell: "Прием врача-акушера-гинеколога повторный (репродуктолог)",
                fourthСell: "1000,00"                       
            },
            {
                firstCell: "B01.001.002",
                secondCell: "100.03.01",
                thirdCell: "Прием врача-акушера-гинеколога повторный (репродуктолог, кандидат медицинских наук)",
                fourthСell: "1100,00"                       
            },
            {
                firstCell: "В01.001.001",
                secondCell: "100.05",
                thirdCell: "Прием врача-акушера - гинеколога первичный (Комплекс: консультация врача, обследование в смотровом кабинете, инструментальный осмотр, ультразвуковое исследование",
                fourthСell: "1700,00"                       
            },
            {
                firstCell: "В01.001.001",
                secondCell: "100.06",
                thirdCell: "Прием врача-акушера - гинеколога первичный",
                fourthСell: "1000,00"                       
            },
            {
                firstCell: "В01.001.001",
                secondCell: "100.06.1",
                thirdCell: "Прием врача-акушера - гинеколога  первичный (кандидат медицинских наук)",
                fourthСell: "1100,00"                       
            },
            {
                firstCell: "B01.001.002 ",
                secondCell: "100.07",
                thirdCell: "Прием врача-акушера - гинеколога повторный",
                fourthСell: "900,00"                       
            },
            {
                firstCell: "B01.001.002",
                secondCell: "100.07.1",
                thirdCell: "Прием врача-акушера - гинеколога повторный (кандидат медицинских услуг)",
                fourthСell: "1000,00"                       
            },
            {
                firstCell: "B01.001.002",
                secondCell: "100.27",
                thirdCell: "Прием врача-акушера-гинеколога повторный  (выписка из амбулаторной карты)",
                fourthСell: "600,00"                       
            },
            {
                firstCell: "B01.053.001",
                secondCell: "100.08",
                thirdCell: "Прием врача-уролога первичный",
                fourthСell: "950,00"                       
            },
            {
                firstCell: "B01.053.001",
                secondCell: "100.08.2",
                thirdCell: "Прием врача-уролога первичный (доктор медицинских наук)",
                fourthСell: "2000,00"                       
            },
            {
                firstCell: "B01.053.002",
                secondCell: "100.09",
                thirdCell: "Прием врача-уролога повторный",
                fourthСell: "750,00"                       
            },
            {
                firstCell: "B01.053.002",
                secondCell: "100.09.2",
                thirdCell: "Прием врача-уролога повторный (доктор медицинских наук)",
                fourthСell: "1930,00"                       
            },
            {
                firstCell: "B01.058.001",
                secondCell: "100.13",
                thirdCell: "Прием врача-эндокринолога первичный",
                fourthСell: "1000,00"                       
            },
            {
                firstCell: "B01.058.002",
                secondCell: "100.13.1",
                thirdCell: "Прием врача-эндокринолога повторный",
                fourthСell: "900,00"                       
            },
            {
                firstCell: "B01.047.001",
                secondCell: "100.15",
                thirdCell: "Прием врача-терапевта первичный",
                fourthСell: "900,00"                       
            },
            {
                firstCell: "B01.047.002",
                secondCell: "100.15.1",
                thirdCell: "Прием врача-терапевта повторный",
                fourthСell: "800,00"                       
            },
            {
                firstCell: "B01.050.001",
                secondCell: "Б100.41",
                thirdCell: "Прием врача-травматолога-ортопеда первичный",
                fourthСell: "1000,00"                       
            },
            {
                firstCell: "B01.050.002",
                secondCell: "Б100.41.1",
                thirdCell: "Прием врача-травматолога-ортопеда повторный",
                fourthСell: "900,00"                       
            },
            {
                firstCell: "B01.027.002",
                secondCell: "Б100.42",
                thirdCell: "Прием врача-онколога первичный",
                fourthСell: "1000,00"                       
            },
            {
                firstCell: "B01.027.003",
                secondCell: "Б100.42.1",
                thirdCell: "Прием врача-онколога повторный",
                fourthСell: "900,00"                       
            },
            {
                firstCell: "B01.047.002",
                secondCell: "100.17",
                thirdCell: "Консультация врача-терапевта (допуск к программе ЭКО/гистероскопии)",
                fourthСell: "800,00"                       
            },
            {
                firstCell: "B01.001.002",
                secondCell: "100.38",
                thirdCell: "Консультация врача-акушера-гинеколога повторная (по результатам обследования)",
                fourthСell: "500,00"                       
            },
            {
                firstCell: "B01.047.002",
                secondCell: "100.38.1",
                thirdCell: "Консультация врача-терапевта повторная (по результатам обследования)",
                fourthСell: "500,00"                       
            },
            {
                firstCell: "B01.053.002",
                secondCell: "100.38.2",
                thirdCell: "Консультация врача-уролога повторная (по результатам обследования)",
                fourthСell: "500,00"                       
            },
            {
                firstCell: "",
                secondCell: "",
                thirdCell: "Ведение беременности",
                fourthСell: "",
                title: true
            },
            {
                firstCell: "B01.001.004 ",
                secondCell: "100.21",
                thirdCell: "Прием врача-акушера-гинеколога беременной первичный (Комплекс: консультация врача, обследование в смотровом кабинете, инструментальный осмотр, для беременных, не состоящих на учете в клинике)",
                fourthСell: "1300,00"                       
            },
            {
                firstCell: "B01.001.005",
                secondCell: "100.21.1",
                thirdCell: "Прием врача-акушера-гинеколога беременной повторный  (Комплекс: :консультация врача, обследование в смотровом кабинете, инструментальный осмотр, для беременных, не состоящих на учете в клинике)",
                fourthСell: "950,00"                       
            },
            {
                firstCell: "B01.001.004",
                secondCell: "100.22",
                thirdCell: "Прием врача-акушера-гинеколога беременной первичный (Программа первичного обследования для постановки на учет и ведение беременности: скрининг УЗИ, скрининг биохимический, полное обследование и осмотр специалистов согласно приказу МЗ РФ)",
                fourthСell: "15000,00"                       
            },
            {
                firstCell: "B01.001.005",
                secondCell: "100.23",
                thirdCell: "Прием врача-акушера-гинеколога беременной повторный (Ведение беременности 1 триместр: скрининг УЗИ, скрининг биохимический, полное обследование и осмотр специалистов согласно приказу МЗ РФ, 2 посещения по 30 мин.)",
                fourthСell: "8000,00"                       
            },
            {
                firstCell: "B01.001.005",
                secondCell: "100.24",
                thirdCell: "Прием врача-акушера-гинеколога беременной повторный (Ведение беременности 2 триместр: скрининг УЗИ, скрининг биохимический, полное обследование согласно приказу МЗ РФ, 5 посещений по 30 мин.)",
                fourthСell: "13000,00"                       
            },
            {
                firstCell: "B01.001.005",
                secondCell: "100.25",
                thirdCell: "Прием врача-акушера-гинеколога беременной повторный (Ведение беременности 3 триместр: скрининг УЗИ, полное обследование, а так же: КТГ плода и осмотр специалистов согласно приказу МЗ РФ, 8 посещений по 30 мин.)",
                fourthСell: "26000,00"                       
            },
            {
                firstCell: "",
                secondCell: "",
                thirdCell: "Дистанционное консультирование",
                fourthСell: "",
                title: true
            },
            {
                firstCell: "В01.001.001",
                secondCell: "ДИС100.02",
                thirdCell: "Консультация врача-акушера-гинеколога первичная (репродуктолог, дистанционное информирование)",
                fourthСell: "500,00"                       
            },
            {
                firstCell: "В01.001.001",
                secondCell: "ДИС100.02.01",
                thirdCell: "Консультация врача-акушера-гинеколога  первичная (репродуктолог, кандидат медицинских наук, дистанционное информирование)",
                fourthСell: "600,00"                       
            },
            {
                firstCell: "B01.001.002",
                secondCell: "ДИС100.03",
                thirdCell: "Консультация врача-акушера-гинеколога повторная (репродуктолог, дистанционная)",
                fourthСell: "850,00"                       
            },
            {
                firstCell: "B01.001.002",
                secondCell: "ДИС100.03.01",
                thirdCell: "Консультация врача-акушера-гинеколога повторная (репродуктолог, кандидат медицинских наук, дистанционная)",
                fourthСell: "950,00"                       
            },
            {
                firstCell: "В01.001.001",
                secondCell: "ДИС100.06",
                thirdCell: "Консультация врача-акушера - гинеколога первичная (дистанционное информирование)",
                fourthСell: "500,00"                       
            },
            {
                firstCell: "B01.001.002",
                secondCell: "ДИС100.07",
                thirdCell: "Консультация врача-акушера - гинеколога повторная (дистанционная)",
                fourthСell: "800,00"                       
            },
            {
                firstCell: "1.2",
                secondCell: "",
                thirdCell: "Процедуры и лечебные манипуляции",
                fourthСell: "",
                subChapter: true            
            },
            {
                firstCell: "1.2.1",
                secondCell: "",
                thirdCell: "Гинекология и урология",
                fourthСell: "",
                subSubChapter: true            
            },
            {
                firstCell: "A03.20.001",
                secondCell: "200.06",
                thirdCell: "Кольпоскопия (исследование шейки матки методом видеокольпоскопии)",
                fourthСell: "1050,00"                       
            },
            {
                firstCell: "A03.20.001",
                secondCell: "200.09",
                thirdCell: "Кольпоскопия",
                fourthСell: "1000,00"                       
            },
            {
                firstCell: "A03.20.005 ",
                secondCell: "200.08",
                thirdCell: "Вульвоскопия",
                fourthСell: "850,00"                       
            },
            {
                firstCell: "A16.20.036.001",
                secondCell: "200.30",
                thirdCell: "Электродиатермоконизация шейки матки (аргоноплазменная конизации)",
                fourthСell: "3500,00"                       
            },
            {
                firstCell: "A16.20.036.001",
                secondCell: "200.31",
                thirdCell: "Электродиатермоконизация шейки матки (радиоволновая конизация)",
                fourthСell: "3300,00"                       
            },
            {
                firstCell: "A16.20.090",
                secondCell: "200.22",
                thirdCell: "Снятие швов с шейки матки",
                fourthСell: "850,00"                       
            },
            {
                firstCell: "A16.30.069",
                secondCell: "200.22.1",
                thirdCell: "Снятие послеоперационных швов (лигатур)",
                fourthСell: "850,00"                       
            },
            {
                firstCell: "A11.28.008",
                secondCell: "200.01",
                thirdCell: "Инстилляция мочевого пузыря  (капельное введение лекарственных средств в мочевой пузырь)",
                fourthСell: "800,00"                       
            },
            {
                firstCell: "A11.20.024",
                secondCell: "200.01.1",
                thirdCell: "Введение лекарственных препаратов интравагинально (в цервикальный канал)",
                fourthСell: "850,00"                       
            },
            {
                firstCell: "A11.20.024",
                secondCell: "200.01.2",
                thirdCell: "Введение лекарственных препаратов интравагинально  (введение препарата Антиадгезин 3г (противоспаечный гель) в полость матки, включая стоимость препарата)",
                fourthСell: "7000,00"                       
            },
            {
                firstCell: "A11.20.024",
                secondCell: "200.01.3",
                thirdCell: "Введение лекарственных препаратов интравагинально  (введение препарата Антиадгезин 5г (противоспаечный гель) в полость матки, включая стоимость препарата)",
                fourthСell: "7500,00"                       
            },
            {
                firstCell: "A11.20.024",
                secondCell: "200.55",
                thirdCell: "Введение лекарственных препаратов интравагинально  (введение препарата Мезогель в полость матки,  включая стоимость препарата)",
                fourthСell: "6500,00"                       
            },
            {
                firstCell: "A11.20.024",
                secondCell: "200.29",
                thirdCell: "Введение лекарственных препаратов интравагинально (в полость матки, без стоимости препарата)",
                fourthСell: "850,00"                       
            },
            {
                firstCell: "A11.20.024",
                secondCell: "200.29.1",
                thirdCell: "Введение лекарственных препаратов интравагинально (введение препарата Инстиллагель в полость матки, включая стоимость препарата)",
                fourthСell: "1200,00"                       
            },
            {
                firstCell: "A11.20.024",
                secondCell: "200.65",
                thirdCell: "Введение лекарственных препаратов интравагинально (ванночки, инстилляция препарата в цервикальный канал, без стоимости лекарственного препарата)",
                fourthСell: "700,00"                       
            },
            {
                firstCell: "A11.20.003",
                secondCell: "200.11",
                thirdCell: "Биопсия тканей матки (взятие биопсийного материала с шейки матки в амбулаторных условиях с гистологическим исследованием)",
                fourthСell: "3000,00"                       
            },
            {
                firstCell: "А11.20.",
                secondCell: "200.12",
                thirdCell: "Получение аспирата эндометрия на гистологическое исследование",
                fourthСell: "4500,00"                       
            },
            {
                firstCell: "А11.20.",
                secondCell: "200.13",
                thirdCell: "Получение аспирата эндометрия для бактериологического исследования",
                fourthСell: "4500,00"                       
            },
            {
                firstCell: "A11.20.005",
                secondCell: "200.17",
                thirdCell: "Получение влагалищного мазка",
                fourthСell: "300,00"                       
            },
            {
                firstCell: "A11.20.014",
                secondCell: "200.18",
                thirdCell: "Введение внутриматочной спирали  (под УЗИ контролем, без стоимости внутриматочной спирали)",
                fourthСell: "2000,00"                       
            },
            {
                firstCell: "A11.20.015",
                secondCell: "200.19",
                thirdCell: "Удаление внутриматочной спирали",
                fourthСell: "1000,00"                       
            },
            {
                firstCell: "A11.20.013",
                secondCell: "200.54",
                thirdCell: "Тампонирование лечебное влагалища (без стоимости препаратов)",
                fourthСell: "250,00"                       
            },
            {
                firstCell: "A11.21.004",
                secondCell: "200.04",
                thirdCell: "Сбор секрета простаты",
                fourthСell: "750,00"                       
            },
            {
                firstCell: " A11.28.009",
                secondCell: "200.01.4",
                thirdCell: "Инстилляция уретры (капельное введение лекарственных средств в уретру)",
                fourthСell: "800,00"                       
            },
            {
                firstCell: " A11.28.009",
                secondCell: "200.29.2",
                thirdCell: "Инстилляция уретры (в уретру, без стоимости препарата)",
                fourthСell: "800,00"                       
            },
            {
                firstCell: "A11.28.006",
                secondCell: "200.52",
                thirdCell: "Получение уретрального отделяемого",
                fourthСell: "300,00"                       
            },
            {
                firstCell: "A16.01.004",
                secondCell: "200.39",
                thirdCell: "Хирургическая обработка раны или инфицированной ткани  (обработка раны и наложение асептической повязки)",
                fourthСell: "250,00"                       
            },
            {
                firstCell: "A16.01.004",
                secondCell: "200.21",
                thirdCell: "Хирургическая обработка раны или инфицированной ткани (обработка швов на шейке матки, влагалище)",
                fourthСell: "750,00"                       
            },
            {
                firstCell: "A16.01.004",
                secondCell: "200.66",
                thirdCell: "Хирургическая обработка раны или инфицированной ткани (послеоперационная обработка шейки матки)",
                fourthСell: "350,00"                       
            },
            {
                firstCell: "A17.30.021",
                secondCell: "200.14",
                thirdCell: "Электрокоагуляция (аргоноплазменная коагуляция шейки матки)",
                fourthСell: "2700,00"                       
            },
            {
                firstCell: "A17.30.021",
                secondCell: "200.15",
                thirdCell: "Электрокоагуляция (радиоволновая коагуляция шейки матки)",
                fourthСell: "2500,00"                       
            },
            {
                firstCell: "A21.21.001",
                secondCell: "200.05",
                thirdCell: "Массаж простаты",
                fourthСell: "800,00"                       
            },
            {
                firstCell: "A25.20.001",
                secondCell: "200.15.1",
                thirdCell: "Назначение лекарственных препаратов при заболеваниях женских половых органов (диагностика и лечение патологии шейки матки)",
                fourthСell: "3670,00"                       
            },
            {
                firstCell: "A11.02.002",
                secondCell: "200.33",
                thirdCell: "Внутримышечное введение лекарственных препаратов  (без стоимости препаратов)",
                fourthСell: "120,00"                       
            },
            {
                firstCell: "A11.12.003",
                secondCell: "200.32",
                thirdCell: "Внутривенное введение лекарственных препаратов  (без стоимости одноразовой иглы с адаптером и лекарственных препаратов)",
                fourthСell: "200,00"                       
            },
            {
                firstCell: "A11.12.003.001",
                secondCell: "200.34",
                thirdCell: "Внутривенное введение лекарственных препаратов (включая стоимость одноразовой иглы с адаптером, без стоимости лекарственных препаратов)",
                fourthСell: "300,00"                       
            },
            {
                firstCell: "A11.12.003.001",
                secondCell: "200.34.1",
                thirdCell: "Внутривенное введение лекарственных препаратов (включая стоимость одноразовой системы, окситоцина 5МЕ/1 мл , натрия хлорида 200 мл)",
                fourthСell: "500,00"                       
            },
            {
                firstCell: "A11.12.003.001",
                secondCell: "200.34.2",
                thirdCell: "Внутривенное введение лекарственных препаратов (без стоимости одноразовой системы, без стоимости лекарственных препаратов)",
                fourthСell: "400,00"                       
            },
            {
                firstCell: "A11.12.003.001",
                secondCell: "200.34.3",
                thirdCell: "Внутривенное введение лекарственных препаратов (включая стоимость одноразовой системы, без стоимости лекарственных препаратов)",
                fourthСell: "450,00"                       
            },
            {
                firstCell: "A11.12.009",
                secondCell: "01-00-001",
                thirdCell: "Взятие крови из периферической вены (с использованием вакуумной системы)",
                fourthСell: "150,00"                       
            },
            {
                firstCell: "A11.08.010.001",
                secondCell: "01-00-002",
                thirdCell: "Получение мазков со слизистой оболочки носоглотки",
                fourthСell: "100,00"                       
            },
            {
                firstCell: "A11.08.010.002",
                secondCell: "01-00-003",
                thirdCell: "Получение мазков со слизистой оболочки ротоглотки",
                fourthСell: "100,00"                       
            },
            {
                firstCell: "А23.02",
                secondCell: "200.23",
                thirdCell: "Постановка экспресс-теста на беременность",
                fourthСell: "220,00"                       
            },
            {
                firstCell: "1.2.2",
                secondCell: "",
                thirdCell: "Травматология",
                fourthСell: "",
                subSubChapter: true            
            },
            {
                firstCell: "A11.04.004",
                secondCell: "200.68",
                thirdCell: "Внутрисуставное введение лекарственных препаратов  (PRP терапия)",
                fourthСell: "8000,00"                       
            },
            {
                firstCell: "A11.04.005",
                secondCell: "200.68.1",
                thirdCell: "Пункция синовиальной сумки сустава",
                fourthСell: "900,00"                       
            },
            {
                firstCell: "A11.04.006",
                secondCell: "200.68.2",
                thirdCell: "Околосуставное введение лекарственных препаратов (без стоимости препаратов)",
                fourthСell: "900,00"                       
            },
            {
                firstCell: "A11.04.006",
                secondCell: "200.68.3",
                thirdCell: "Околосуставное введение лекарственных препаратов (со стоимостью препарата)",
                fourthСell: "1500,00"                       
            },
            {
                firstCell: "A16.04.051",
                secondCell: "200.68.4",
                thirdCell: "Внутрисуставное введение заменителей (протезов) синовиальной жидкости ( со стоимостью препарата Русвиск 1,6% 2 мл.)",
                fourthСell: "10000,00"                       
            },
            {
                firstCell: "A16.04.051",
                secondCell: "200.68.4.1",
                thirdCell: "Внутрисуставное введение заменителей (протезов) синовиальной жидкости ( со стоимостью препарата Русвиск 2,2% 3 мл.)",
                fourthСell: "15000,00"                       
            },
            {
                firstCell: "A16.04.051",
                secondCell: "200.68.4.2",
                thirdCell: "Внутрисуставное введение заменителей (протезов) синовиальной жидкости ( со стоимостью препарата Русвиск 2,2% 5 мл.)",
                fourthСell: "20000,00"                       
            },
            {
                firstCell: "A16.04.051",
                secondCell: "200.68.5",
                thirdCell: "Внутрисуставное введение заменителей (протезов) синовиальной жидкости  ( со стоимостью препарата Армавискон 1,5 % 2мл.)",
                fourthСell: "8500,00"                       
            },
            {
                firstCell: "A16.04.051",
                secondCell: "200.68.6",
                thirdCell: "Внутрисуставное введение заменителей (протезов) синовиальной жидкости ( со стоимостью препарата Гиапро 1,5 % 30 мг/2 мл.)",
                fourthСell: "10000,00"                       
            },
            {
                firstCell: "A16.04.051",
                secondCell: "200.68.6.1",
                thirdCell: "Внутрисуставное введение заменителей (протезов) синовиальной жидкости ( со стоимостью препарата Гиапро 2 % 40 мг/2 мл.)",
                fourthСell: "15000,00"                       
            },
            {
                firstCell: "A16.04.051",
                secondCell: "200.68.6.2",
                thirdCell: "Внутрисуставное введение заменителей (протезов) синовиальной жидкости ( со стоимостью препарата Гиапро Макс 2 % 40 мг/2 мл.)",
                fourthСell: "20000,00"                       
            },
            {
                firstCell: "A16.30.069",
                secondCell: "200.22.1",
                thirdCell: "Снятие послеоперационных швов (лигатур)",
                fourthСell: "850,00"                       
            },
            {
                firstCell: "A15.04.003",
                secondCell: "200.69",
                thirdCell: "Наложение повязки при операциях на суставах",
                fourthСell: "550,00"                       
            },
            {
                firstCell: "1.3",
                secondCell: "",
                thirdCell: "Услуги отделения УЗД (ультразвуковой диагностики)",
                fourthСell: "",
                subChapter: true            
            },
            {
                firstCell: "1.3.1",
                secondCell: "",
                thirdCell: "Акушерство и гинекология",
                fourthСell: "",
                subSubChapter: true            
            },
            {
                firstCell: "A04.20.001.001",
                secondCell: "600.01",
                thirdCell: "Ультразвуковое исследование матки и придатков трансвагиальное",
                fourthСell: "1000,00"                       
            },
            {
                firstCell: "A04.20.001",
                secondCell: "600.02",
                thirdCell: "Ультразвуковое исследование матки и придатков трансабдоминальное ",
                fourthСell: "700,00"                       
            },
            {
                firstCell: "A04.20.001.002",
                secondCell: "600.04",
                thirdCell: "Ультразвуковое исследование матки и придатков трансректальное",
                fourthСell: "900,00"                       
            },
            {
                firstCell: "A04.20.001 A04.20.001.001",
                secondCell: "600.05",
                thirdCell: "Ультразвуковое исследование матки и придатков трансвагиальное/ трансабдоминальное (в динамике, в течение 1 месяца)",
                fourthСell: "700,00"                       
            },
            {
                firstCell: "B01.052.001",
                secondCell: "600.06",
                thirdCell: "Осмотр врача ультразвуковой диагностики (УЗ-мониторинг овуляции: включает оценку эндометрия и фолликулярного аппарата яичников, на аппарате экспертного класса)",
                fourthСell: "800,00"                       
            },
            {
                firstCell: "B01.001.002",
                secondCell: "200.16",
                thirdCell: "Прием врача-акушера-гинеколога повторный (ультразвуковой мониторинг овуляции: включает оценку эндометрия и фолликулярного аппарата яичников)",
                fourthСell: "700,00"                       
            },
            {
                firstCell: "A04.20.001.004",
                secondCell: "600.07",
                thirdCell: "Ультразвуковое исследование шейки матки (УЗ-цервикометрия)",
                fourthСell: "800,00"                       
            },
            {
                firstCell: "A04.30.010",
                secondCell: "600.08",
                thirdCell: "Ультразвуковое исследование органов малого таза (комплексное) (трехмерная реконструкция органов малого таза (у женщин)",
                fourthСell: "900,00"                       
            },
            {
                firstCell: "",
                secondCell: "",
                thirdCell: "Исследования при сроке беременности до 11 недель",
                fourthСell: "",
                title: true
            },
            {
                firstCell: "A04.30.001",
                secondCell: "600.09",
                thirdCell: "Ультразвуковое исследование плода  (при сроке беременности до 11 недель)",
                fourthСell: "1000,00"                       
            },
            {
                firstCell: "A04.30.001",
                secondCell: "600.10",
                thirdCell: "Ультразвуковое исследование плода (при сроке беременности до 11 недель, многоплодная беременность (двойня)",
                fourthСell: "1100,00"                       
            },
            {
                firstCell: "A04.30.001",
                secondCell: "600.11",
                thirdCell: "Ультразвуковое исследование плода (при сроке беременности до 11 недель, многоплодная беременность (тройня)",
                fourthСell: "1200,00"                       
            },
            {
                firstCell: "",
                secondCell: "",
                thirdCell: "Исследования при сроке беременности 11-18 недель",
                fourthСell: "",
                title: true
            },
            {
                firstCell: "A04.30.001",
                secondCell: "600.15",
                thirdCell: "Ультразвуковое исследование плода (при сроке беременности  11-18 недель, демонстрация родственникам и фотография в подарок)",
                fourthСell: "1300,00"                       
            },
            {
                firstCell: "A04.30.001",
                secondCell: "600.16",
                thirdCell: "Ультразвуковое исследование плода (при сроке беременности  11-18 недель, многоплодная беременность (двойня), демонстрация родственникам и фотография в подарок)",
                fourthСell: "1400,00"                       
            },
            {
                firstCell: "A04.30.001",
                secondCell: "600.17",
                thirdCell: "Ультразвуковое исследование плода (при сроке беременности  11-18 недель многоплодная беременность (тройня), демонстрация родственникам и фотография в подарок)",
                fourthСell: "1450,00"                       
            },
            {
                firstCell: "A04.30.001",
                secondCell: "600.18",
                thirdCell: "Ультразвуковое исследование плода (при сроке беременности  11-18 недель с допплерографией и цветным картированием маточно-плацентарно-плодового комплекса)",
                fourthСell: "1500,00"                       
            },
            {
                firstCell: "A04.30.001",
                secondCell: "600.19",
                thirdCell: "Ультразвуковое исследование плода ( при сроке беременности  11-18 недель с допплерографией и цветным картированием маточно-плацентарно-плодового комплекса, многоплодная беременность (двойня)",
                fourthСell: "1650,00"                       
            },
            {
                firstCell: "A04.30.001",
                secondCell: "600.20",
                thirdCell: "Ультразвуковое исследование плода (при сроке беременности  11-18 недель с допплерографией и цветным картированием маточно-плацентарно-плодового комплекса, многоплодная беременность (тройня)",
                fourthСell: "1700,00"                       
            },
            {
                firstCell: "A04.30.001",
                secondCell: "600.24",
                thirdCell: "Ультразвуковое исследование плода  (при сроке беременности до 18 недель, в режиме 3D/4D, демонстрация родственникам, запись на DVD)",
                fourthСell: "2400,00"                       
            },
            {
                firstCell: "A04.30.001",
                secondCell: "600.25",
                thirdCell: "Ультразвуковое исследование плода (при сроке беременности до 18 недель (двойня), в режиме 3D/4D, демонстрация родственникам, запись на DVD)",
                fourthСell: "3000,00"                       
            },
            {
                firstCell: "A04.30.001",
                secondCell: "600.26",
                thirdCell: "Ультразвуковое исследование плода (при сроке беременности до 18 недель (тройня), в режиме 3D/4D, демонстрация родственникам, запись на DVD)",
                fourthСell: "3300,00"                       
            },
            {
                firstCell: "A04.12.024",
                secondCell: "600.27",
                thirdCell: "Ультразвуковая допплерография маточно-плацентарного кровотока  (при сроке беременности до 18 недель, цветное картирование)",
                fourthСell: "1000,00"                       
            },
            {
                firstCell: "A04.12.024.001",
                secondCell: "600.28",
                thirdCell: "Ультразвуковая допплерография маточно-плацентарного кровотока  (при сроке беременности до 18 недель (двойня), цветное картирование)",
                fourthСell: "1050,00"                       
            },
            {
                firstCell: "A04.12.024.002",
                secondCell: "600.29",
                thirdCell: "Ультразвуковая допплерография маточно-плацентарного кровотока  (при сроке беременности до 18 недель (тройня), цветное картирование)",
                fourthСell: "1100,00"                       
            },
            {
                firstCell: "",
                secondCell: "",
                thirdCell: "Исследования при сроке беременности  18-30 недель",
                fourthСell: "",
                title: true
            },
            {
                firstCell: "A04.30.001",
                secondCell: "600.30",
                thirdCell: "Ультразвуковое исследование плода (при сроке беременности 18-30 недель, демонстрация родственникам и фотография в подарок) ",
                fourthСell: "1500,00"                       
            },
            {
                firstCell: "A04.30.001",
                secondCell: "600.31",
                thirdCell: "Ультразвуковое исследование плода (при сроке беременности 18-30 недель (двойня), демонстрация родственникам и фотография в подарок)",
                fourthСell: "1600,00"                       
            },
            {
                firstCell: "A04.30.001",
                secondCell: "600.32",
                thirdCell: "Ультразвуковое исследование плода (при сроке беременности 18-30 недель (тройня), демонстрация родственникам и фотография в подарок)",
                fourthСell: "1700,00"                       
            },
            {
                firstCell: "A04.30.001",
                secondCell: "600.33",
                thirdCell: "Ультразвуковое исследование плода (при сроке беременности  18-30 недель с допплерографией и цветным картированием маточно-плацентарно-плодового комплекса)",
                fourthСell: "1700,00"                       
            },
            {
                firstCell: "A04.30.001",
                secondCell: "600.34",
                thirdCell: "Ультразвуковое исследование плода ( при сроке беременности  18-30 недель с допплерографией и цветным картированием маточно-плацентарно-плодового комплекса, многоплодная беременность (двойня)",
                fourthСell: "1900,00"                       
            },
            {
                firstCell: "A04.30.001",
                secondCell: "600.35",
                thirdCell: "Ультразвуковое исследование плода (при сроке беременности 18-30 недель с допплерографией и цветным картированием маточно-плацентарно-плодового комплекса, многоплодная беременность (тройня)",
                fourthСell: "2000,00"                       
            },
            {
                firstCell: "A04.30.001",
                secondCell: "600.39",
                thirdCell: "Ультразвуковое исследование плода  (при сроке беременности 18-30 недель, в режиме 3D/4D, демонстрация родственникам, запись на DVD)",
                fourthСell: "2400,00"                       
            },
            {
                firstCell: "A04.30.001",
                secondCell: "600.40",
                thirdCell: "Ультразвуковое исследование плода (при сроке беременности 18-30 недель (двойня), в режиме 3D/4D, демонстрация родственникам, запись на DVD)",
                fourthСell: "3000,00"                       
            },
            {
                firstCell: "A04.30.001",
                secondCell: "600.41",
                thirdCell: "Ультразвуковое исследование плода (при сроке беременности  18-30  недель (тройня), в режиме 3D/4D, демонстрация родственникам, запись на DVD)",
                fourthСell: "3500,00"                       
            },
            {
                firstCell: "A04.12.024",
                secondCell: "600.42",
                thirdCell: "Ультразвуковая допплерография маточно-плацентарного кровотока  (при сроке беременности 18-30 недель, цветное картирование)",
                fourthСell: "1000,00"                       
            },
            {
                firstCell: "A04.12.024",
                secondCell: "600.43",
                thirdCell: "Ультразвуковая допплерография маточно-плацентарного кровотока  (при сроке беременности 18-30 недель (двойня), цветное картирование)",
                fourthСell: "1100,00"                       
            },
            {
                firstCell: "A04.12.024",
                secondCell: "600.44",
                thirdCell: "Ультразвуковая допплерография маточно-плацентарного кровотока  (при сроке беременности 18-30 недель (тройня), цветное картирование)",
                fourthСell: "1200,00"                       
            },
            {
                firstCell: "",
                secondCell: "",
                thirdCell: "Исследования при сроке беременности более 30 недель",
                fourthСell: "",
                title: true
            },
            {
                firstCell: "A04.30.001",
                secondCell: "600.45",
                thirdCell: "Ультразвуковое исследование плода (при сроке беременности более 30 недель, демонстрация родственникам и фотография в подарок)",
                fourthСell: "1300,00"                       
            },
            {
                firstCell: "A04.30.001",
                secondCell: "600.46",
                thirdCell: "Ультразвуковое исследование плода (при сроке беременности более 30 недель (двойня), демонстрация родственникам и фотография в подарок)",
                fourthСell: "1700,00"                       
            },
            {
                firstCell: "A04.30.001",
                secondCell: "600.47",
                thirdCell: "Ультразвуковое исследование плода (при сроке беременности более 30 недель (тройня), демонстрация родственникам и фотография в подарок)",
                fourthСell: "2600,00"                       
            },
            {
                firstCell: "A04.30.001",
                secondCell: "600.48",
                thirdCell: "Ультразвуковое исследование плода (при сроке беременности  более 30 недель с допплерографией и цветным картированием маточно-плацентарно-плодового комплекса)",
                fourthСell: "1600,00"                       
            },
            {
                firstCell: "A04.30.001",
                secondCell: "600.49",
                thirdCell: "Ультразвуковое исследование плода ( при сроке беременности  более 30 недель с допплерографией и цветным картированием маточно-плацентарно-плодового комплекса, многоплодная беременность (двойня)",
                fourthСell: "2000,00"                       
            },
            {
                firstCell: "A04.30.001",
                secondCell: "600.50",
                thirdCell: "Ультразвуковое исследование плода (при сроке беременности более 30 недель с допплерографией и цветным картированием маточно-плацентарно-плодового комплекса, многоплодная беременность (тройня)",
                fourthСell: "2100,00"                       
            },
            {
                firstCell: "A04.30.001",
                secondCell: "600.54",
                thirdCell: "Ультразвуковое исследование плода  (при сроке беременности более 30 недель, в режиме 3D/4D, демонстрация родственникам, запись на DVD)",
                fourthСell: "2400,00"                       
            },
            {
                firstCell: "A04.30.001",
                secondCell: "600.55",
                thirdCell: "Ультразвуковое исследование плода (при сроке беременности более 30 недель (двойня), в режиме 3D/4D, демонстрация родственникам, запись на DVD)",
                fourthСell: "3000,00"                       
            },
            {
                firstCell: "A04.30.001",
                secondCell: "600.56",
                thirdCell: "Ультразвуковое исследование плода (при сроке беременности  более 30  недель (тройня), в режиме 3D/4D, демонстрация родственникам, запись на DVD)",
                fourthСell: "3500,00"                       
            },
            {
                firstCell: "A04.12.024",
                secondCell: "600.57",
                thirdCell: "Ультразвуковая допплерография маточно-плацентарного кровотока  (при сроке беременности более 30 недель, цветное картирование)",
                fourthСell: "1000,00"                       
            },
            {
                firstCell: "A04.12.024",
                secondCell: "600.58",
                thirdCell: "Ультразвуковая допплерография маточно-плацентарного кровотока  (при сроке беременности более 30 недель (двойня), цветное картирование)",
                fourthСell: "1100,00"                       
            },
            {
                firstCell: "A04.12.024",
                secondCell: "600.59",
                thirdCell: "Ультразвуковая допплерография маточно-плацентарного кровотока  (при сроке беременности более 30 недель (тройня), цветное картирование)",
                fourthСell: "1200,00"                       
            },
            {
                firstCell: "1.3.2",
                secondCell: "",
                thirdCell: "УЗИ органов малого таза, брюшной полости, забрюшинного пространства и мочеполовой системы",
                fourthСell: "",
                subSubChapter: true            
            },
            {
                firstCell: "B03.052.001",
                secondCell: "600.60",
                thirdCell: "Комплексное ультразвуковое исследование внутренних органов (брюшная полость: печень, желчный пузырь, поджелудочная железа, селезенка)",
                fourthСell: "1000,00"                       
            },
            {
                firstCell: "B03.052.001",
                secondCell: "600.61",
                thirdCell: "Комплексное ультразвуковое исследование внутренних органов (органы брюшной полости и почки)",
                fourthСell: "1400,00"                       
            },
            {
                firstCell: "B03.052.001",
                secondCell: "600.62",
                thirdCell: "Комплексное ультразвуковое исследование внутренних органов (почки, надпочечники и забрюшинное пространство)",
                fourthСell: "700,00"                       
            },
            {
                firstCell: "A04.28.002.001",
                secondCell: "600.63",
                thirdCell: "Ультразвуковое исследование почек  (допплерография почек с цветным картированием)",
                fourthСell: "900,00"                       
            },
            {
                firstCell: "A04.28.002.003",
                secondCell: "600.64",
                thirdCell: "Ультразвуковое исследование мочевого пузыря",
                fourthСell: "700,00"                       
            },
            {
                firstCell: "A04.09.001",
                secondCell: "600.65",
                thirdCell: "Ультразвуковое исследование плевральной полости",
                fourthСell: "700,00"                       
            },
            {
                firstCell: "A04.18.001",
                secondCell: "600.66",
                thirdCell: "Ультразвуковое исследование толстой кишки",
                fourthСell: "700,00"                       
            },
            {
                firstCell: "A04.19.001.001",
                secondCell: "600.67",
                thirdCell: "Ультразвуковое исследование прямой кишки трансректальное (с допплерографией и цветным картированием)",
                fourthСell: "800,00"                       
            },
            {
                firstCell: "A04.21.001",
                secondCell: "600.68",
                thirdCell: "Ультразвуковое исследование предстательной железы",
                fourthСell: "800,00"                       
            },
            {
                firstCell: "A04.21.001.001",
                secondCell: "600.69",
                thirdCell: "Ультразвуковое исследование предстательной железы трансректальное",
                fourthСell: "800,00"                       
            },
            {
                firstCell: "A04.21.001",
                secondCell: "600.70",
                thirdCell: "Ультразвуковое исследование предстательной железы  (Комплекс: предстательная железа и мочевой пузырь, определение остаточной мочи (трансабдоминально)",
                fourthСell: "1300,00"                       
            },
            {
                firstCell: "A04.21.001.001",
                secondCell: "600.71",
                thirdCell: "Ультразвуковое исследование предстательной железы трансректальное ( с допплерографией и цветным картированием)",
                fourthСell: "900,00"                       
            },
            {
                firstCell: "A04.28.003",
                secondCell: "600.74",
                thirdCell: "Ультразвуковое исследование органов мошонки (с допплерографией)",
                fourthСell: "900,00"                       
            },
            {
                firstCell: "B03.052.001",
                secondCell: "Б600.91",
                thirdCell: "Комплексное ультразвуковое исследование внутренних органов (трансвагинальное: тазовое дно и уретра)",
                fourthСell: "1400,00"                       
            },
            {
                firstCell: "1.3.3",
                secondCell: "",
                thirdCell: "Поверхностные структуры",
                fourthСell: "",
                subSubChapter: true            
            },
            {
                firstCell: "A04.22.001",
                secondCell: "600.80",
                thirdCell: "Ультразвуковое исследование щитовидной железы и паращитовидных желез  (с регионарными лимфатическими узлами и допплерографией щитовидной железы с цветным картированием)",
                fourthСell: "900,00"                       
            },
            {
                firstCell: "A04.20.002",
                secondCell: "600.82",
                thirdCell: "Ультразвуковое исследование молочных желез (с регионарными лимфоузлами)",
                fourthСell: "900,00"                       
            },
            {
                firstCell: "A04.07.002",
                secondCell: "600.83",
                thirdCell: "Ультразвуковое исследование слюнных желез ",
                fourthСell: "800,00"                       
            },
            {
                firstCell: "A04.06.002",
                secondCell: "600.84",
                thirdCell: "Ультразвуковое исследование лимфатических узлов (одна анатомическая зона) при системных заболеваниях",
                fourthСell: "800,00"                       
            },
            {
                firstCell: "A04.06.002",
                secondCell: "600.84.1",
                thirdCell: "Ультразвуковое исследование лимфатических узлов (все группы лимфатических узлов при системных заболеваниях)",
                fourthСell: "1400,00"                       
            },
            {
                firstCell: "A04.01.001",
                secondCell: "600.85",
                thirdCell: "Ультразвуковое исследование мягких тканей (одна анатомическая зона)",
                fourthСell: "700,00"                       
            },
            {
                firstCell: "1.3.4",
                secondCell: "",
                thirdCell: "Сердечно-сосудистая система",
                fourthСell: "",
                subSubChapter: true            
            },
            {
                firstCell: "A04.10.002",
                secondCell: "600.86.1",
                thirdCell: "Эхокардиография плода (УЗИ сердца с допплеровским анализом, одноплодная беременность)",
                fourthСell: "1100,00"                       
            },
            {
                firstCell: "A04.10.002",
                secondCell: "600.86.2",
                thirdCell: "Эхокардиография плода (УЗИ сердца с допплеровским анализом, многоплодная беременность)",
                fourthСell: "1300,00"                       
            },
            {
                firstCell: "A04.12.",
                secondCell: "600.87",
                thirdCell: "Цветное допплеровское картирование и допплерометрия сосудов матки",
                fourthСell: "1000,00"                       
            },
            {
                firstCell: "1.3.5",
                secondCell: "",
                thirdCell: "Ультразвуковая диагностика (дети)",
                fourthСell: "",
                subSubChapter: true            
            },
            {
                firstCell: "A04.20.002",
                secondCell: "600.82.1",
                thirdCell: "Ультразвуковое исследование молочных желез (с регионарными лимфоузлами у детей)",
                fourthСell: "750,00"                       
            },
            {
                firstCell: "B03.052.001",
                secondCell: "600.98",
                thirdCell: "Комплексное ультразвуковое исследование внутренних органов (брюшная полость, почки, забрюшинное пространство)",
                fourthСell: "1200,00"                       
            },
            {
                firstCell: "B03.052.001",
                secondCell: "600.99",
                thirdCell: "Комплексное ультразвуковое исследование внутренних органов (брюшная полость, почки)",
                fourthСell: "1000,00"                       
            },
            {
                firstCell: "B03.052.001",
                secondCell: "600.60.1",
                thirdCell: "Комплексное ультразвуковое исследование внутренних органов (брюшная полость: печень, желчный пузырь, поджелудочная железа, селезенка)",
                fourthСell: "870,00"                       
            },
            {
                firstCell: "A04.07.002",
                secondCell: "600.83",
                thirdCell: "Ультразвуковое исследование слюнных желез",
                fourthСell: "700,00"                       
            },
            {
                firstCell: "A04.28.002.001",
                secondCell: "600.63.1",
                thirdCell: "Ультразвуковое исследование почек (с допплерографией сосудов)",
                fourthСell: "800,00"                       
            },
            {
                firstCell: "A04.28.002.001 A04.28.002.005",
                secondCell: "600.64.1",
                thirdCell: "Ультразвуковое исследование почек и мочевого пузыря (с определением остаточной мочи)",
                fourthСell: "1150,00"                       
            },
            {
                firstCell: "A04.28.002.005",
                secondCell: "600.100",
                thirdCell: "Ультразвуковое исследование мочевого пузыря с определением остаточной мочи",
                fourthСell: "650,00"                       
            },
            {
                firstCell: "B03.052.001",
                secondCell: "600.101",
                thirdCell: "Комплексное ультразвуковое исследование внутренних органов (почки и надпочечники)",
                fourthСell: "700,00"                       
            },
            {
                firstCell: "A04.21.001",
                secondCell: "600.68.1",
                thirdCell: "Ультразвуковое исследование предстательной железы",
                fourthСell: "700,00"                       
            },
            {
                firstCell: "A04.22.001",
                secondCell: "600.80.1",
                thirdCell: "Ультразвуковое исследование щитовидной железы и паращитовидных желез  (ЦДК и регионарными лимфатическими узлами)",
                fourthСell: "750,00"                       
            },
            {
                firstCell: "A04.28.003",
                secondCell: "600.104",
                thirdCell: "Ультразвуковое исследование органов мошонки (с допплерографией и пробой Вальсальвы)",
                fourthСell: "700,00"                       
            },
            {
                firstCell: "A04.01.001",
                secondCell: "600.105",
                thirdCell: "Ультразвуковое исследование мягких тканей",
                fourthСell: "600,00"                       
            },
            {
                firstCell: "A04.30.010",
                secondCell: "600.106",
                thirdCell: "Ультразвуковое исследование органов малого таза (комплексное), трансобдоминально",
                fourthСell: "700,00"                       
            },
            {
                firstCell: "1.4",
                secondCell: "",
                thirdCell: "Функциональная диагностика",
                fourthСell: "",
                subChapter: true            
            },
            {
                firstCell: "A05.10.006",
                secondCell: "Б1002.1",
                thirdCell: "Регистрация электрокардиограммы (ЭКГ с расшифровкой)",
                fourthСell: "450,00"                       
            },
            {
                firstCell: "A05.10.006",
                secondCell: "Б1002.2",
                thirdCell: "Регистрация электрокардиограммы (ЭКГ с описанием и интерпретацией данных)",
                fourthСell: "650,00"                       
            },
            {
                firstCell: "A05.10.006",
                secondCell: "Б1002.2",
                thirdCell: "Регистрация электрокардиограммы (ЭКГ с описанием и интерпретацией данных)",
                fourthСell: "650,00"                       
            },
            {
                firstCell: "A05.10.006",
                secondCell: "100.36",
                thirdCell: "Регистрация электрокардиограммы (ЭКГ без описания)",
                fourthСell: "330,00"                       
            },
            {
                firstCell: "A05.30.001",
                secondCell: "200.50",
                thirdCell: "Кардиотокография плода",
                fourthСell: "1000,00"                       
            },
            {
                firstCell: "Раздел 2",
                secondCell: "400",
                thirdCell: "Лаборатория ВРТ (вспомогательные репродуктивные технологии)",
                fourthСell: "",
                chapter: true            
            },
            {
                firstCell: "B01.001.002",
                secondCell: "400.11.01",
                thirdCell: "Прием врача-акушера-гинеколога повторный  (Выбор тактики лечения, овариальная гиперстимуляция без стоимости препаратов,коррекция стимуляции суперовуляции, УЗИ мониторинг созревания фолликулов)",
                fourthСell: "21500,00"                       
            },
            {
                firstCell: "B01.001.002",
                secondCell: "400.12.01",
                thirdCell: "Прием врача-акушера-гинеколога повторный (Выбор тактики лечения, овариальная гиперстимуляция без стоимости препаратов, коррекция стимуляции суперовуляции, УЗИ мониторинг созревания фолликулов. Для пациентов, проходящих повторный цикл ЭКО)",
                fourthСell: "18500,00"                       
            },
            {
                firstCell: "B01.001.002",
                secondCell: "400.13.01",
                thirdCell: "Прием врача-акушера-гинеколога повторный (ультразвуковой мониторинг созревания фолликулов)",
                fourthСell: "5000,00"                       
            },
            {
                firstCell: "",
                secondCell: "",
                thirdCell: "Донорский биоматериал / донор",
                fourthСell: "",
                title: true
            },
            {
                firstCell: "",
                secondCell: "400.31",
                thirdCell: "Предоставление законсервированной спермы (2 соломины для процедуры ЭКО) (г.Казань)",
                fourthСell: "26000,00"                       
            },
            {
                firstCell: "",
                secondCell: "400.31.1",
                thirdCell: "Предоставление законсервированной спермы (1 доза), г. Москва",
                fourthСell: "60000,00"                       
            },
            {
                firstCell: "",
                secondCell: "400.31.2",
                thirdCell: "Предоставление законсервированной спермы (3 соломины для процедуры ВМИ) г. Казань",
                fourthСell: "29000,00"                       
            },
            {
                firstCell: "",
                secondCell: "400.32",
                thirdCell: "Предоставление донорских ооцитов (6 шт.)",
                fourthСell: "180000,00"                       
            },
            {
                firstCell: "",
                secondCell: "400.33",
                thirdCell: "Предоставление донорских ооцитов (7 шт.)",
                fourthСell: "195000,00"                       
            },
            {
                firstCell: "",
                secondCell: "400.34",
                thirdCell: "Предоставление донорских ооцитов (8 шт.)",
                fourthСell: "210000,00"                       
            },
            {
                firstCell: "",
                secondCell: "400.32-5",
                thirdCell: "Предоставление донорских ооцитов (9 шт.)",
                fourthСell: "225000,00"                       
            },
            {
                firstCell: "",
                secondCell: "400.32-6",
                thirdCell: "Предоставление донорских ооцитов (10 шт.)",
                fourthСell: "240000,00"                       
            },
            {
                firstCell: "",
                secondCell: "",
                thirdCell: "Исследование эякулята",
                fourthСell: "",
                title: true
            },
            {
                firstCell: "B03.053.002",
                secondCell: "400.22",
                thirdCell: "Спермограмма (для цикла ЭКО по критериям Крюгера: спермограмма по ВОЗ, MAR - тест, исследование морфологии)",
                fourthСell: "3000,00"                       
            },
            {
                firstCell: "B03.053.002",
                secondCell: "400.22В",
                thirdCell: "Спермограмма (для цикла ЭКО по критериям Крюгера)",
                fourthСell: "2450,00"                       
            },
            {
                firstCell: "A11.21.010",
                secondCell: "400.22.1",
                thirdCell: "Обработка спермы для проведения процедуры экстракорпорального оплодотворения (осуществляется дополнительно к спермограмме для цикла ЭКО по критериям Крюгера)",
                fourthСell: "3000,00"                       
            },
            {
                firstCell: "B03.053.002",
                secondCell: "400.23",
                thirdCell: "Спермограмма",
                fourthСell: "1000,00"                       
            },
            {
                firstCell: "A12.21.001",
                secondCell: "400.24",
                thirdCell: "Микроскопическое исследование спермы  (MAR тест с использованием IgG)",
                fourthСell: "1800,00"                       
            },
            {
                firstCell: "A12.21.001",
                secondCell: "400.25",
                thirdCell: "Микроскопическое исследование спермы (исследование морфологии эякулята по критериям Крюгера)",
                fourthСell: "1500,00"                       
            },
            {
                firstCell: "A12.21.001",
                secondCell: "400.26",
                thirdCell: "Микроскопическое исследование спермы (тест на криотолерантность сперматозоидов, комплексное исследование)",
                fourthСell: "4500,00"                       
            },
            {
                firstCell: "A12.21.001",
                secondCell: "400.27",
                thirdCell: "Микроскопическое исследование спермы (анализ на среду)",
                fourthСell: "2300,00"                       
            },
            {
                firstCell: "A12.21.001",
                secondCell: "400.29",
                thirdCell: "Микроскопическое исследование спермы (проба Шуварского, посткоитальный тест)",
                fourthСell: "800,00"                       
            },
            {
                firstCell: "A12.21.001",
                secondCell: "400.37",
                thirdCell: "Микроскопическое исследование спермы (НВА тест на связывание сперматозоидов с гиалуроновой кислотой)",
                fourthСell: "6900,00"                       
            },
            {
                firstCell: "A12.21.001",
                secondCell: "400.46",
                thirdCell: "Микроскопическое исследование спермы (Тест-LeucoScreen определение пероксидаза-положительных лейкоцитов в сперме)",
                fourthСell: "1500,00"                       
            },
            {
                firstCell: "A12.21.001",
                secondCell: "400.43",
                thirdCell: "Микроскопическое исследование спермы (Тест на фрагментацию ДНК сперматозоидов TUNEL",
                fourthСell: "7200,00"                       
            },
            {
                firstCell: "",
                secondCell: "",
                thirdCell: "Биопсия эмбрионов, ПГД",
                fourthСell: "",
                title: true
            },
            {
                firstCell: "A11.30.010",
                secondCell: "400.38",
                thirdCell: "Биопсия эмбриона  (биопсия трофэктодермы бластоцисты (1 эмбриона на 5-6 день развития)",
                fourthСell: "12000,00"                       
            },
            {
                firstCell: "A11.30.010.001",
                secondCell: "400.39",
                thirdCell: "Биопсия эмбриона (биопсия трофэктодермы бластоцисты (2 эмбрионов на 5-6 день развития)",
                fourthСell: "13000,00"                       
            },
            {
                firstCell: "A11.30.010.002",
                secondCell: "400.40",
                thirdCell: "Биопсия эмбриона (биопсия трофэктодермы бластоцисты (3 эмбрионов на 5-6 день развития)",
                fourthСell: "17500,00"                       
            },
            {
                firstCell: "A11.30.010.004",
                secondCell: "400.41.1",
                thirdCell: "Биопсия эмбриона (биопсия трофэктодермы бластоцисты (4 эмбрионов на 5-6 день развития)",
                fourthСell: "19000,00"                       
            },
            {
                firstCell: "A11.30.010.005",
                secondCell: "400.41.2",
                thirdCell: "Биопсия эмбриона (биопсия трофэктодермы бластоцисты (5 эмбрионов на 5-6 день развития)",
                fourthСell: "24500,00"                       
            },
            {
                firstCell: "A11.30.010.006",
                secondCell: "400.41.3",
                thirdCell: "Биопсия эмбриона (биопсия трофэктодермы бластоцисты (6 эмбрионов на 5-6 день развития)",
                fourthСell: "27000,00"                       
            },
            {
                firstCell: "A11.30.010.007",
                secondCell: "400.41.4",
                thirdCell: "Биопсия эмбриона (биопсия трофэктодермы бластоцисты (7 эмбрионов на 5-6 день развития)",
                fourthСell: "33000,00"                       
            },
            {
                firstCell: "A11.30.010.008",
                secondCell: "400.41.5",
                thirdCell: "Биопсия эмбриона (биопсия трофэктодермы бластоцисты (8 эмбрионов на 5-6 день развития)",
                fourthСell: "35500,00"                       
            },
            {
                firstCell: "А10.20.001.001",
                secondCell: "400.42",
                thirdCell: "Преимплантационное генетическое исследование на анеуплоидии (ПГТ-А)  (исследование 1 эмбриона методом NGS)",
                fourthСell: "35000,00"                       
            },
            {
                firstCell: "А10.20.001.001",
                secondCell: "400.42.1",
                thirdCell: "Преимплантационное генетическое исследование на анеуплоидии (ПГТ-А) (исследование 2 эмбрионов методом NGS",
                fourthСell: "60000,00"                       
            },
            {
                firstCell: "А10.20.001.001",
                secondCell: "400.42.2",
                thirdCell: "Преимплантационное генетическое исследование на анеуплоидии (ПГТ-А)  (исследование 3 эмбрионов методом NGS)",
                fourthСell: "80000,00"                       
            },
            {
                firstCell: "А10.20.001.001",
                secondCell: "400.42.3",
                thirdCell: "Преимплантационное генетическое исследование на анеуплоидии (ПГТ-А)  (исследование 4 эмбрионов методом NGS)",
                fourthСell: "95000,00"                       
            },
            {
                firstCell: "А10.20.001.001",
                secondCell: "400.42.8",
                thirdCell: "Преимплантационное генетическое исследование на анеуплоидии (ПГТ-А)  (исследование 5 эмбрионов методом NGS)",
                fourthСell: "115000,00"                       
            },
            {
                firstCell: "А10.20.001.001",
                secondCell: "400.42.4",
                thirdCell: "Преимплантационное генетическое исследование на анеуплоидии (ПГТ-А)  (исследование 6 эмбрионов методом NGS)",
                fourthСell: "135000,00"                       
            },
            {
                firstCell: "А10.20.001.001",
                secondCell: "400.42.5",
                thirdCell: "Преимплантационное генетическое исследование на анеуплоидии (ПГТ-А) (исследование 7 эмбрионов методом NGS)",
                fourthСell: "157500,00"                       
            },
            {
                firstCell: "А10.20.001.001",
                secondCell: "400.42.6",
                thirdCell: "Преимплантационное генетическое исследование на анеуплоидии (ПГТ-А)  (исследование 8 эмбрионов методом NGS)",
                fourthСell: "180000,00"                       
            },
            {
                firstCell: "А10.20.001.002",
                secondCell: "400.42.9",
                thirdCell: "Преимплантационное генетическое исследование на моногенные заболевания (ПГТ-М)",
                fourthСell: "170000,00"                       
            },
            {
                firstCell: "Раздел 3",
                secondCell: "700",
                thirdCell: "Лабораторные исследования",
                fourthСell: "",
                chapter: true            
            },
            {
                firstCell: "",
                secondCell: "",
                thirdCell: "Генетические исследования",
                fourthСell: "",
                title: true
            },
            {
                firstCell: "B03.045.031",
                secondCell: "700.1",
                thirdCell: "Тест на родство «Универсальный»",
                fourthСell: "18000,00"                       
            },
            {
                firstCell: "B03.045.031",
                secondCell: "700.1.1",
                thirdCell: "Тест на родство «Универсальный» (досудебная, судебная экспертиза)",
                fourthСell: "20000,00"                       
            },
            {
                firstCell: "B03.045.031",
                secondCell: "700.2",
                thirdCell: "Тест на отцовство/материнство (дуэт) 20 маркеров",
                fourthСell: "13000,00"                       
            },
            {
                firstCell: "B03.045.031",
                secondCell: "700.2.1",
                thirdCell: "Дополнительный участник исследования, 20 маркеров",
                fourthСell: "6000,00"                       
            },
            {
                firstCell: "B03.045.031",
                secondCell: "700.2.1.1",
                thirdCell: "Тест на отцовство/материнство (дуэт, трио) 20 маркеров (судебная/досудебная экспертиза)",
                fourthСell: "17000,00"                       
            },
            {
                firstCell: "B03.045.031",
                secondCell: "700.5",
                thirdCell: "Тест на отцовство/материнство ТРИО, 20 маркеров (3 участника: 1 предполагаемый родитель, 1 безусловный родитель, 1 ребенок)",
                fourthСell: "14000,00"                       
            },
            {
                firstCell: "B03.045.031",
                secondCell: "700.7",
                thirdCell: "ТЕСТ НА РОДСТВО, 20 маркеров (исследуются аутосомные маркеры, 2 участника: дедушка/бабушка - внук/внучка, дядя/тетя - племянник/племянница (авункулярный тест), родные/сводные братья/сестры (полно- и полусиблинговый, близнецовый тест",
                fourthСell: "13500,00"                       
            },
            {
                firstCell: "B03.045.031",
                secondCell: "700.8",
                thirdCell: "ТЕСТ НА РОДСТВО, 24 маркера (исследуются аутосомные маркеры, 2 участника: дедушка/бабушка - внук/внучка, дядя/тетя - племянник/племянница (авункулярный тест), родные/сводные братья/сестры (полно- и полусиблинговый, близнецовый тест",
                fourthСell: "17000,00"                       
            },
            {
                firstCell: "B03.045.031",
                secondCell: "700.10",
                thirdCell: "ТЕСТ на родство, тестирование Х-хромосом (2 участника: бабушка по линии отца, внучка)",
                fourthСell: "16000,00"                       
            },
            {
                firstCell: "B03.045.031",
                secondCell: "700.11",
                thirdCell: "ТЕСТ на родство, тестирование Х-хромосом (2 участника: бабушка по линии отца, внучка), досудебная/судебная экспертиза",
                fourthСell: "22000,00"                       
            },
            {
                firstCell: "B03.045.031",
                secondCell: "700.13",
                thirdCell: "ТЕСТ НА РОДСТВО, тестирование Y-хромосомы (2 участника: дедушка по линии отца - внук, дядя - племянник, родные/сводные по отцу братья)",
                fourthСell: "16000,00"                       
            },
            {
                firstCell: "B03.045.031",
                secondCell: "700.26",
                thirdCell: "ТЕСТ на отцовство/материнство (дуэт, трио) 20 маркеров (досудебная экспертиза)",
                fourthСell: "15000,00"                       
            },
            {
                firstCell: "B03.045.031",
                secondCell: "700.44",
                thirdCell: "ВЫДЕЛЕНИЕ ДНК ИЗ НЕСТАНДАРТНОГО ОБРАЗЦА 1 ТИПА (волосы с корешками; ногти; окурок; жевательная резинка; зубная щетка; ушная сера; детская соска-пустыш",
                fourthСell: "5000,00"                       
            },
            {
                firstCell: "B03.045.031",
                secondCell: "ВКР 700.1",
                thirdCell: "Неинвазивный тест по дородовому установлению отцовствства",
                fourthСell: "90000,00"                       
            },
            {
                firstCell: "B03.006",
                secondCell: "НИПТ31",
                thirdCell: "Неинвазивный пренатальный тест (расширенная панель, 31 синдром)+определение пола плода",
                fourthСell: "35000,00"                       
            },
            {
                firstCell: "B03.006",
                secondCell: "НИПТ7",
                thirdCell: "Неинвазивный пренатальный тест (стандартная панель 7 синдромов)+определение пола плода",
                fourthСell: "30000,00"                       
            },
            {
                firstCell: "B03.006",
                secondCell: "НИПС1",
                thirdCell: "Неинвазивный пренатальный ДНК скрининг на синдром Дауна + определение пола плода",
                fourthСell: "23000,00"                       
            },
            {
                firstCell: "",
                secondCell: "700.9era",
                thirdCell: "Определение уровня рецептивности эндометрия - тест ERA",
                fourthСell: "60000,00"                       
            },     
        ],
        geneticResearch: [
            {
                firstCell: "01110",
                secondCell: "8",
                thirdCell: "НИПС Т21 - Неинвазивный пренатальный ДНК скрининг на синдром Дауна",
                fourthСell: "23000,00",                        
            },
            {
                firstCell: "1438",
                secondCell: "8",
                thirdCell: "НИПС 5 - Неинвазивный пренатальный ДНК скрининг на 5 синдромов",
                fourthСell: "30000,00"                       
            },
            {
                firstCell: "866",
                secondCell: "8",
                thirdCell: "НИПС 12 - Неинвазивный пренатальный ДНК скрининг на 12 синдромов",
                fourthСell: "35000,00"                       
            },
            {
                firstCell: "16",
                secondCell: "14",
                thirdCell: "НИПТ Panorama (Natera, США), базовая панель - неинвазивный пренатальный ДНК тест на 8 синдромов",
                fourthСell: "30000,00"                       
            },
            {
                firstCell: "498",
                secondCell: "14",
                thirdCell: "НИПТ Panorama (Natera, США), расширенная панель - неинвазивный пренатальный ДНК тест на 13 синдромов",
                fourthСell: "44500,00"                       
            },
            {
                firstCell: "1416",
                secondCell: "21",
                thirdCell: "Vistara - скрининг на 25 моногенных синдромов",
                fourthСell: "69000,00"                       
            },            
            {
                firstCell: "1724",
                secondCell: "8",
                thirdCell: "НИПС Расширенный - Неинвазивный пренатальный ДНК скрининг на 31 синдром",
                fourthСell: "34000,00"                       
            },
            {
                firstCell: "589",
                secondCell: "7",
                thirdCell: "Хромосомный микроматричный анализ пренатальный",
                fourthСell: "15000,00"                       
            },
            {
                firstCell: "797",
                secondCell: "14",
                thirdCell: "Пренатальная ДНК-диагностика мышечной дистрофии Дюшенна/Беккера",
                fourthСell: "11700,00"                       
            },
            {
                firstCell: "26",
                secondCell: "7",
                thirdCell: "Неинвазивное определение Резус-фактора плода",
                fourthСell: "7300,00"                       
            },
            {
                firstCell: "834",
                secondCell: "7",
                thirdCell: "Неинвазивное определение пола плода. Скрининговый тест",
                fourthСell: "6500,00"                       
            },
            {
                firstCell: "672",
                secondCell: "14",
                thirdCell: "Пренатальная диагностика спинальной амиотрофии, типов I, II, III и IV",
                fourthСell: "10500,00"                       
            },
            {
                firstCell: "818",
                secondCell: "14",
                thirdCell: "Муковисцидоз: пренатальная ДНК диагностика",
                fourthСell: "12700,00"                       
            },
            {
                firstCell: "836",
                secondCell: "14",
                thirdCell: "Неинвазивное определение пола плода. Экспертный тест",
                fourthСell: "35000,00"                       
            },
            {
                firstCell: "871",
                secondCell: "14",
                thirdCell: "Поиск мутаций в гене GJB2 (Пренатальная диагностика)",
                fourthСell: "10000,00"                       
            },
            {
                firstCell: "1109",
                secondCell: "14",
                thirdCell: "Болезнь Норри: пренатальная ДНК-диагностика",
                fourthСell: "10500,00"                       
            },
            {
                firstCell: "1112",
                secondCell: "14",
                thirdCell: "Болезнь Шарко-Мари-Тута тип II: пренатальная диагностика",
                fourthСell: "12100,00"                       
            },
            {
                firstCell: "1179",
                secondCell: "14",
                thirdCell: "Врожденная гиперплазия коры надпочечников (адреногенитальный синдром). Пренатальная ДНК- диагностика",
                fourthСell: "11700,00"                       
            },
            {
                firstCell: "1357",
                secondCell: "14",
                thirdCell: "Пренатальная диагностика хореи Гентингтона",
                fourthСell: "11700,00"                       
            },
            {
                firstCell: "1569",
                secondCell: "14",
                thirdCell: "Гемофилия. Пренатальная диагностика",
                fourthСell: "12100,00"                       
            },
            {
                firstCell: "1749",
                secondCell: "21",
                thirdCell: "Пренатальная диагностика моногенного аутосомно-доминантного заболевания (генотип пробанда и родителей известен)",
                fourthСell: "5000,00"                       
            },
            {
                firstCell: "1750",
                secondCell: "21",
                thirdCell: "Пренатальная диагностика моногенного аутосомно-доминантного заболевания (генотип пробанда и родителей неизвестен)",
                fourthСell: "12000,00"                       
            },
            {
                firstCell: "1751",
                secondCell: "21",
                thirdCell: "Пренатальная диагностика моногенного аутосомно-рецессивного заболевания (пробанд - гомозигота, генотип пробанда и родителей известен)",
                fourthСell: "5000,00"                       
            },
            {
                firstCell: "1752",
                secondCell: "21",
                thirdCell: "Пренатальная диагностика моногенного аутосомно-рецессивного заболевания (пробанд - компаунд гетерозигота, генотип пробанда и родителей известен)",
                fourthСell: "10000,00"                       
            },
            {
                firstCell: "1753",
                secondCell: "21",
                thirdCell: "Пренатальная диагностика моногенного аутосомно-рецессивного заболевания (пробанд - гомозигота, генотип пробанда и родителей неизвестен)",
                fourthСell: "12000,00"                       
            },
            {
                firstCell: "1754",
                secondCell: "21",
                thirdCell: "Пренатальная диагностика моногенного аутосомно-рецессивного заболевания (пробанд - компаунд гетерозигота, генотип пробанда и родителей неизвестен)",
                fourthСell: "35000,00"                      
            },            
            {
                firstCell: "1755",
                secondCell: "21",
                thirdCell: "Пренатальная диагностика моногенного X-сцепленного заболевания (генотип пробанда известен)",
                fourthСell: "7000,00"                       
            },
            {
                firstCell: "1756",
                secondCell: "21",
                thirdCell: "Пренатальная диагностика моногенного X-сцепленного заболевания (генотип пробанда неизвестен)",
                fourthСell: "12000,00"                       
            },
            {
                firstCell: "856",
                secondCell: "90",
                thirdCell: "Полное секвенирование генома GenomeUNI",
                fourthСell: "99000,00"                       
            },
            {
                firstCell: "1351",
                secondCell: "90",
                thirdCell: "Полное секвенирование генома GenomeUNI при отрицательном результате анализа неврологических панелей генов",
                fourthСell: "64000,00"                       
            },
            {
                firstCell: "1341",
                secondCell: "90",
                thirdCell: "Полное секвенирование генома пробанда и родителей (3 человека) - GenomeUNI трио",
                fourthСell: "199000,00"                       
            },
            {
                firstCell: "1350",
                secondCell: "90",
                thirdCell: "Полное секвенирование генома родителей, при ранее сделанном полногеномном секвенировании пробанда",
                fourthСell: "117000,00"                       
            },
            {
                firstCell: "554",
                secondCell: "90",
                thirdCell: "Полное секвенирование экзома",
                fourthСell: "43000,00"                       
            },
            {
                firstCell: "841",
                secondCell: "90",
                thirdCell: "Полное секвенирование экзома (трио)",
                fourthСell: "129000,00"                       
            },
            {
                firstCell: "224",
                secondCell: "90",
                thirdCell: "Клиническое секвенирование экзома",
                fourthСell: "40000,00"                       
            },
            {
                firstCell: "586",
                secondCell: "90",
                thirdCell: "Секвенирование митохондриального генома",
                fourthСell: "35000,00"                       
            },
            {
                firstCell: "508",
                secondCell: "30",
                thirdCell: "Панель «Заболевания соединительной ткани»",
                fourthСell: "22750,00"                       
            },
            {
                firstCell: "786",
                secondCell: "30",
                thirdCell: "Панель «Факоматозы и наследственный рак»",
                fourthСell: "25000,00"                       
            },
            {
                firstCell: "671",
                secondCell: "90",
                thirdCell: "Панель «Наследственные эпилепсии»",
                fourthСell: "35000,00"                       
            },
            {
                firstCell: "523",
                secondCell: "30",
                thirdCell: "Панель «Наследственная тугоухость»",
                fourthСell: "22750,00"                       
            },
            {
                firstCell: "825",
                secondCell: "90",
                thirdCell: "Панель «Нейродегенеративные заболевания»",
                fourthСell: "35000,00"                       
            },
            {
                firstCell: "530",
                secondCell: "30",
                thirdCell: "Панель «Первичный иммунодефицит и наследственные анемии»",
                fourthСell: "22750,00"                       
            },
            {
                firstCell: "823",
                secondCell: "90",
                thirdCell: "Панель «Умственная отсталость и расстройства аутистического спектра»",
                fourthСell: "35000,00"                       
            },
            {
                firstCell: "824",
                secondCell: "90",
                thirdCell: "Панель «Наследственные нарушения обмена веществ»",
                fourthСell: "35000,00"                       
            },
            {
                firstCell: "826",
                secondCell: "90",
                thirdCell: "Панель «Нервно-мышечные заболевания»",
                fourthСell: "35000,00"                       
            },
            {
                firstCell: "837",
                secondCell: "30",
                thirdCell: "Панель «Наследственные заболевания глаз»",
                fourthСell: "22750,00"                       
            },            
            {
                firstCell: "838",
                secondCell: "30",
                thirdCell: "Панель «Наследственные заболевания почек»",
                fourthСell: "22750,00"                       
            },
            {
                firstCell: "839",
                secondCell: "30",
                thirdCell: "Панель «Наследственные заболевания сердца»",
                fourthСell: "22750,00"                       
            },
            {
                firstCell: "840",
                secondCell: "30",
                thirdCell: "Панель «Наследственные нарушения репродуктивной системы»",
                fourthСell: "22750,00"                       
            },
            {
                firstCell: "857",
                secondCell: "30",
                thirdCell: "Панель «Наследственные заболевания желудочно-кишечного тракта»",
                fourthСell: "22750,00"                       
            },
            {
                firstCell: "1466",
                secondCell: "90",
                thirdCell: "Большая неврологическая панель",
                fourthСell: "35000,00"                       
            },
            {
                firstCell: "1358",
                secondCell: "10",
                thirdCell: "Рак легких, базовая панель (гены EGFR, KRAS, NRAS, BRAF)",
                fourthСell: "12500,00"                       
            },
            {
                firstCell: "1460",
                secondCell: "14",
                thirdCell: "Рак легких, жидкостная биопсия, базовая панель (гены EGFR, KRAS, NRAS, BRAF)",
                fourthСell: "26000,00"                       
            },
            {
                firstCell: "262",
                secondCell: "30",
                thirdCell: "Панель «Наследственный рак толстой кишки»",
                fourthСell: "25000,00"                       
            },
            {
                firstCell: "811",
                secondCell: "10",
                thirdCell: "Определение мутаций в генах BRAF, NRAS и KIT",
                fourthСell: "14000,00"                       
            },
            {
                firstCell: "1096",
                secondCell: "10",
                thirdCell: "Определение мутаций в генах BRAF, KRAS и NRAS",
                fourthСell: "11000,00"                       
            },
            {
                firstCell: "8",
                secondCell: "10",
                thirdCell: "Определение мутаций в гене KRAS",
                fourthСell: "8000,00"                       
            },
            {
                firstCell: "639",
                secondCell: "10",
                thirdCell: "Определение мутаций в гене NRAS",
                fourthСell: "8000,00"                       
            },
            {
                firstCell: "9",
                secondCell: "10",
                thirdCell: "Определение мутаций V600 в гене BRAF",
                fourthСell: "7000,00"                       
            },
            {
                firstCell: "1173",
                secondCell: "10",
                thirdCell: "Определение мутаций в генах KIT и PDGFRA",
                fourthСell: "13000,00"                       
            },
            {
                firstCell: "1183",
                secondCell: "14",
                thirdCell: "Определение мутаций в гене EGFR в плазме крови (жидкостная биопсия)",
                fourthСell: "20000,00"                       
            },
            {
                firstCell: "7",
                secondCell: "10",
                thirdCell: "Определение мутаций в гене EGFR",
                fourthСell: "8000,00"                       
            },
            {
                firstCell: "788",
                secondCell: "30",
                thirdCell: "Панель «Наследственный рак молочной железы»",
                fourthСell: "25000,00"                       
            },
            {
                firstCell: "787",
                secondCell: "30",
                thirdCell: "Панель «Женские наследственные опухоли»",
                fourthСell: "25000,00"                       
            },
            {
                firstCell: "829",
                secondCell: "30",
                thirdCell: "Панель «Наследственные опухолевые синдромы»",
                fourthСell: "25000,00"                       
            },
            {
                firstCell: "5",
                secondCell: "5",
                thirdCell: "Определение 8 частых мутаций в генах BRCA1 и BRCA2",
                fourthСell: "3700,00"                       
            },            
            {
                firstCell: "1182",
                secondCell: "10",
                thirdCell: "Определение мутаций в гене PIK3CA",
                fourthСell: "7000,00"                       
            },
            {
                firstCell: "355",
                secondCell: "21",
                thirdCell: "Тест ОнкоКарта",
                fourthСell: "29000,00"                       
            },
            {
                firstCell: "354",
                secondCell: "30",
                thirdCell: "Тест Онкоскан",
                fourthСell: "49000,00"                       
            },
            {
                firstCell: "848",
                secondCell: "15",
                thirdCell: "Тест Mammaprint",
                fourthСell: "210000,00"                       
            },
            {
                firstCell: "1169",
                secondCell: "21",
                thirdCell: "Рак легких, расширенная панель",
                fourthСell: "35000,00"                       
            },
            {
                firstCell: "1108",
                secondCell: "30",
                thirdCell: "Жидкостная биопсия на 57 генов",
                fourthСell: "49000,00"                       
            },
            {
                firstCell: "1039",
                secondCell: "30",
                thirdCell: "Жидкостная биопсия для рака легкого, расширенная панель",
                fourthСell: "110000,00"                       
            },
            {
                firstCell: "1093",
                secondCell: "10",
                thirdCell: "Определение экспрессии гена PCA3",
                fourthСell: "5000,00"                       
            },
            {
                firstCell: "1093",
                secondCell: "10",
                thirdCell: "Определение экспрессии гена PCA3",
                fourthСell: "5000,00"                       
            },
            {
                firstCell: "981",
                secondCell: "10",
                thirdCell: "Определение транслокации BCR-ABL t(9;22) (p230) (качественное)",
                fourthСell: "4900,00"                       
            },
            {
                firstCell: "810",
                secondCell: "10",
                thirdCell: "Определение микросателлитной нестабильности (MSI)",
                fourthСell: "6000,00"                       
            },
            {
                firstCell: "651",
                secondCell: "10",
                thirdCell: "Определение транслокаций гена ALK",
                fourthСell: "15000,00"                       
            },
            {
                firstCell: "852",
                secondCell: "10",
                thirdCell: "Определение транслокаций гена ROS1",
                fourthСell: "15000,00"                       
            },
            {
                firstCell: "853",
                secondCell: "14",
                thirdCell: "Определение транслокаций гена RET",
                fourthСell: "15000,00"                       
            },
            {
                firstCell: "1184",
                secondCell: "14",
                thirdCell: "Определение амплификаций гена MET",
                fourthСell: "12000,00"                       
            },
            {
                firstCell: "850",
                secondCell: "14",
                thirdCell: "Определение числа копий гена MYCN",
                fourthСell: "15000,00"                       
            },
            {
                firstCell: "982",
                secondCell: "14",
                thirdCell: "Определение транслокации BCR-ABL t(9;22) (p230) (количественное)",
                fourthСell: "6500,00"                       
            },
            {
                firstCell: "600",
                secondCell: "10",
                thirdCell: "Определение амплификаций гена ERBB2 (Her2/Neu)",
                fourthСell: "15000,00"                       
            },
            {
                firstCell: "849",
                secondCell: "14",
                thirdCell: "Определение числа копий гена KMT2A (MLL)",
                fourthСell: "15000,00"                       
            },
            {
                firstCell: "980",
                secondCell: "10",
                thirdCell: "Определение транслокации BCR-ABL t(9;22) (р190) (количественное)",
                fourthСell: "3500,00"                       
            },            
            {
                firstCell: "978",
                secondCell: "10",
                thirdCell: "Определение транслокации BCR-ABL t(9;22) (р210) (количественное)",
                fourthСell: "3600,00"                       
            },
            {
                firstCell: "1057",
                secondCell: "14",
                thirdCell: "Определение мутации W515 в гене MPL",
                fourthСell: "5500,00"                       
            },
            {
                firstCell: "851",
                secondCell: "14",
                thirdCell: "Определение числа копий локуса 1p36",
                fourthСell: "15000,00"                       
            },
            {
                firstCell: "10",
                secondCell: "7",
                thirdCell: "Определение мутации V617F в гене JAK2 (качественное)",
                fourthСell: "2500,00"                       
            },
            {
                firstCell: "983",
                secondCell: "10",
                thirdCell: "Определение мутации D816V в гене KIT",
                fourthСell: "3000,00"                       
            },
            {
                firstCell: "1058",
                secondCell: "10",
                thirdCell: "Определение мутаций 9 экзона гена CALR",
                fourthСell: "5350,00"                       
            },
            {
                firstCell: "1049",
                secondCell: "10",
                thirdCell: "Определение транслокации PML-RARA t(15;17) (количественное), bcr1-2",
                fourthСell: "4000,00"                       
            },
            {
                firstCell: "854",
                secondCell: "14",
                thirdCell: "Определение транслокаций гена SS18 (SYT)",
                fourthСell: "15000,00"                       
            },
            {
                firstCell: "855",
                secondCell: "14",
                thirdCell: "Определение транслокаций гена EWSR1",
                fourthСell: "15000,00"                       
            },
            {
                firstCell: "1050",
                secondCell: "10",
                thirdCell: "Определение транслокации AML1-ETO t(8;21)",
                fourthСell: "3500,00"                       
            },
            {
                firstCell: "559",
                secondCell: "30",
                thirdCell: "Экзомное секвенирование генов BRCA1 и BRCA2",
                fourthСell: "22750,00"                       
            },
            {
                firstCell: "975",
                secondCell: "10",
                thirdCell: "Поиск мутации 1100delC в гене CHEK2",
                fourthСell: "3000,00"                       
            },
            {
                firstCell: "976",
                secondCell: "10",
                thirdCell: "Поиск мутации ivs2+1G>A в гене CHEK2",
                fourthСell: "3000,00"                       
            },
            {
                firstCell: "977",
                secondCell: "10",
                thirdCell: "Определение транслокации BCR-ABL t(9;22) (р210) (качественное)",
                fourthСell: "3000,00"                       
            },
            {
                firstCell: "979",
                secondCell: "10",
                thirdCell: "Определение транслокации BCR-ABL t(9;22) (р190) (качественное)",
                fourthСell: "3000,00"                       
            },
            {
                firstCell: "1168",
                secondCell: "7",
                thirdCell: "Определение мутации V617F в гене JAK2 (количественное)",
                fourthСell: "3000,00"                       
            },
            {
                firstCell: "1176",
                secondCell: "10",
                thirdCell: "Определение транслокации PML-RARA t(15;17), bcr 1-2 (качественное)",
                fourthСell: "3000,00"                       
            },
            {
                firstCell: "1177",
                secondCell: "10",
                thirdCell: "Определение транслокации PML-RARA t(15;17), bcr 3 (качественное)",
                fourthСell: "2900,00"                       
            },
            {
                firstCell: "1186",
                secondCell: "14",
                thirdCell: "Определение делеций в 12 экзоне гена JAK2",
                fourthСell: "5000,00"                       
            },
            {
                firstCell: "1333",
                secondCell: "14",
                thirdCell: "Определение метилирования промотора гена MGMT",
                fourthСell: "6900,00"                       
            },            
            {
                firstCell: "1334",
                secondCell: "14",
                thirdCell: "Определение коделеции локусов 1p/19q",
                fourthСell: "6900,00"                       
            },
            {
                firstCell: "1410",
                secondCell: "15",
                thirdCell: "Определение мутации в генах H3F3A, HIST1H3B и HIST1H3С",
                fourthСell: "15500,00"                       
            },
            {
                firstCell: "1461",
                secondCell: "21",
                thirdCell: "Онкокарта, 60 генов (+BRCA1, BRCA2, PALB2)",
                fourthСell: "39000,00"                       
            },
            {
                firstCell: "1462",
                secondCell: "21",
                thirdCell: "Жидкостная биопсия на 60 генов (+BRCA1, BRCA2, PALB2)",
                fourthСell: "62000,00"                       
            },
            {
                firstCell: "1463",
                secondCell: "21",
                thirdCell: "Определение мутаций в генах BRCA1, BRCA2, PALB2 в ткани опухоли",
                fourthСell: "20000,00"                       
            },
            {
                firstCell: "1465",
                secondCell: "21",
                thirdCell: "Жидкостная биопсия для рака толстой кишки и меланомы",
                fourthСell: "26000,00"                       
            },
            {
                firstCell: "1477",
                secondCell: "14",
                thirdCell: "Определение мутации в гене IDH2",
                fourthСell: "6000,00"                       
            },
            {
                firstCell: "1780",
                secondCell: "14",
                thirdCell: "Анализ химерного гена CBFbMYH11-inv.16, t(16;16) (качественно)",
                fourthСell: "5000,00"                       
            },
            {
                firstCell: "1510",
                secondCell: "7",
                thirdCell: "Определение делеций в 12 экзоне гена JAK2 (количественное)",
                fourthСell: "4500,00"                       
            },
            {
                firstCell: "1476",
                secondCell: "14",
                thirdCell: "Определение мутации в гене IDH1",
                fourthСell: "6000,00"                       
            },
            {
                firstCell: "1505",
                secondCell: "12",
                thirdCell: "Определение мутаций вызывающих резистентность к ингибиторам тирозинкиназной активности при ХМЛ",
                fourthСell: "10000,00"                       
            },
            {
                firstCell: "1570",
                secondCell: "10",
                thirdCell: "Определение мутаций в гене KIT",
                fourthСell: "7000,00"                       
            },
            {
                firstCell: "1571",
                secondCell: "14",
                thirdCell: "Определение мутаций в гене KIT в плазме крови (жидкостная биопсия)",
                fourthСell: "11000,00"                       
            },
            {
                firstCell: "1572",
                secondCell: "14",
                thirdCell: "Определение мутаций в гене PIK3CA в плазме крови (жидкостная биопсия)",
                fourthСell: "11000,00"                       
            },
            {
                firstCell: "1630",
                secondCell: "21",
                thirdCell: "Мониторинг минимальной остаточной болезни. Signatera 2 (анализ плазмы крови)",
                fourthСell: "75000,00"                       
            },
            {
                firstCell: "1631",
                secondCell: "21",
                thirdCell: "Мониторинг минимальной остаточной болезни. Signatera 1 (изготовление тест-системы АКЦИЯ)",
                fourthСell: "120000,00"                       
            },
            {
                firstCell: "1679",
                secondCell: "15",
                thirdCell: "Цитогенетическое исследование крови/костного мозга при гемобластозах",
                fourthСell: "8000,00"                       
            },
            {
                firstCell: "1686",
                secondCell: "21",
                thirdCell: "Определение мутаций в гене TP53",
                fourthСell: "29000,00"                       
            },
            {
                firstCell: "1729",
                secondCell: "10",
                thirdCell: "Определение транслокации PML-RARA t(15;17) (количественное), bcr3",
                fourthСell: "4500,00"                       
            },
            {
                firstCell: "1732",
                secondCell: "21",
                thirdCell: "Определение опухолевой мутационной нагрузки (TMB)",
                fourthСell: "45000,00"                       
            },            
            {
                firstCell: "1740",
                secondCell: "14",
                thirdCell: "Определение транслокаций генов NTRK1, NTRK2, NTRK3",
                fourthСell: "25000,00"                       
            },
            {
                firstCell: "1744",
                secondCell: "14",
                thirdCell: "Определение мутаций с пропуском 14 экзона гена MET",
                fourthСell: "7000,00"                       
            },
            {
                firstCell: "1767",
                secondCell: "14-50",
                thirdCell: "FoundationOne CDx",
                fourthСell: "395000,00"                       
            },
            {
                firstCell: "1768",
                secondCell: "14-50",
                thirdCell: "FoundationOne Heme",
                fourthСell: "395000,00"                       
            },
            {
                firstCell: "1769",
                secondCell: "14-50",
                thirdCell: "FoundationOne Liquid",
                fourthСell: "395000,00"                       
            },
            {
                firstCell: "1779",
                secondCell: "14",
                thirdCell: "Определение транслокации RUNX1RUNX1T1-t(8;21)(качественно)",
                fourthСell: "5000,00"                       
            },
            {
                firstCell: "1781",
                secondCell: "7",
                thirdCell: "FISH исследование транслокаций для гемобластозов (1 зонд)",
                fourthСell: "6700,00"                       
            },
            {
                firstCell: "1784",
                secondCell: "22",
                thirdCell: "Определение мутаций в гене EZH2",
                fourthСell: "7000,00"                       
            },
            {
                firstCell: "1785",
                secondCell: "22",
                thirdCell: "Определение мутаций в гене ASXL1",
                fourthСell: "7000,00"                       
            },
            {
                firstCell: "1787",
                secondCell: "10",
                thirdCell: "Молекулярно-генетическое исследование В -клеточной клональности",
                fourthСell: "9500,00"                       
            },
            {
                firstCell: "1788",
                secondCell: "10",
                thirdCell: "Молекулярно-генетическое исследование Т-клеточной клональности",
                fourthСell: "9500,00"                       
            },
            {
                firstCell: "1789",
                secondCell: "10",
                thirdCell: "Определение делеций в гене TP53",
                fourthСell: "8000,00"                       
            },
            {
                firstCell: "1791",
                secondCell: "10",
                thirdCell: "Определение делеций в гене ATM",
                fourthСell: "6500,00"                       
            },
            {
                firstCell: "1793",
                secondCell: "12",
                thirdCell: "ПАНЕЛЬ «Множественная миелома»",
                fourthСell: "25000,00"                       
            },
            {
                firstCell: "1797",
                secondCell: "14",
                thirdCell: "Определение p.L625P в гене MYD88",
                fourthСell: "5000,00"                       
            },
            {
                firstCell: "1799",
                secondCell: "10",
                thirdCell: "Определение транслокации t(9;22) на операционном (биопсийном материале)",
                fourthСell: "12000,00"                       
            },
            {
                firstCell: "1800",
                secondCell: "14",
                thirdCell: "Определение мутации T790M в гене EGFR в плазме крови (жидкостная биопсия)",
                fourthСell: "13000,00"                       
            },
            {
                firstCell: "1801",
                secondCell: "21",
                thirdCell: "Определение делеций в гене BRCA2 методом MLPA",
                fourthСell: "6500,00"                       
            },
            {
                firstCell: "1805",
                secondCell: "14",
                thirdCell: "FISH исследование хромосомных аберраций на материале парафинового блока(1 зонд)",
                fourthСell: "15000,00"                       
            },
            {
                firstCell: "1807",
                secondCell: "10",
                thirdCell: "Панель «Рак щитовидной железы»: мутации KRAS, NRAS, HRAS, TERT, BRAF, транслокация RET/PTC, транслокация PAX8/PPARG",
                fourthСell: "15000,00"                       
            },            
            {
                firstCell: "1808",
                secondCell: "14",
                thirdCell: "Определение мутаций в гене ESR1",
                fourthСell: "7000,00"                       
            },
            {
                firstCell: "682",
                secondCell: "10",
                thirdCell: "Молекулярное кариотипирование абортивного материала «ОПТИМА»",
                fourthСell: "12500,00"                       
            },
            {
                firstCell: "1487",
                secondCell: "90",
                thirdCell: "Полное секвенирование генома абортивного материала «ФЕРТУС»",
                fourthСell: "80000,00"                       
            },
            {
                firstCell: "1353",
                secondCell: "90",
                thirdCell: "Геном «ФЕРТИ» - диагностика генетических причин бесплодия у мужчин и женщин",
                fourthСell: "75000,00"                       
            },
            {
                firstCell: "1",
                secondCell: "10",
                thirdCell: "Анализ генетических полиморфизмов, ассоциированных с риском тромбообразования с расчетом интегративного риска",
                fourthСell: "6900,00"                       
            },
            {
                firstCell: "2",
                secondCell: "5",
                thirdCell: "Анализ полиморфизмов в генах фолатного цикла",
                fourthСell: "4900,00"                       
            },
            {
                firstCell: "23",
                secondCell: "5",
                thirdCell: "Типирование по трем генам HLA II класса (DRB1, DQA1, DQB1)",
                fourthСell: "9900,00"                       
            },
            {
                firstCell: "490",
                secondCell: "14",
                thirdCell: "Исследование инактивации Х хромосомы",
                fourthСell: "6900,00"                       
            },
            {
                firstCell: "793",
                secondCell: "7",
                thirdCell: "Расширенный поиск микроделеций AZF локуса Y-хромосомы",
                fourthСell: "8100,00"                       
            },
            {
                firstCell: "578",
                secondCell: "14",
                thirdCell: "FISH-диагностика (хромосомы X и Y)",
                fourthСell: "14000,00"                       
            },
            {
                firstCell: "358",
                secondCell: "14",
                thirdCell: "Определение генотипа по резус-фактору, включая гетерозиготное носительство",
                fourthСell: "8000,00"                       
            },
            {
                firstCell: "35",
                secondCell: "7",
                thirdCell: "Анализ полиморфизмов в генах ACE и AGTсвязанных с риском артериальной гипертензии, гипертензивных осложнений беременности и преэклампсии",
                fourthСell: "3000,00"                       
            },
            {
                firstCell: "844",
                secondCell: "21",
                thirdCell: "Инверсия пола 46XY: анализ наличия SRY гена",
                fourthСell: "5000,00"                       
            },
            {
                firstCell: "944",
                secondCell: "14",
                thirdCell: "Анализ числа CAG-повторов в гене андрогенового рецептора (AR)",
                fourthСell: "4900,00"                       
            },
            {
                firstCell: "648",
                secondCell: "14",
                thirdCell: "Поиск частых мутаций в экзоне 10 гена MEFV (Периодическая болезнь)",
                fourthСell: "7800,00"                       
            },
            {
                firstCell: "11",
                secondCell: "14",
                thirdCell: "Синдром ломкой Х хромосомы: анализ метилирования (синдром Мартина-Белл)",
                fourthСell: "6900,00"                       
            },
            {
                firstCell: "582",
                secondCell: "90",
                thirdCell: "Врожденная гиперкальциемия (секвенирование гена CYP24A1)",
                fourthСell: "30000,00"                       
            },
            {
                firstCell: "31",
                secondCell: "21",
                thirdCell: "Врожденная гиперплазия коры надпочечников (адреногенитальный синдром). Поиск 9-ти наиболее частых мутаций в гене CYP21A2 у родительской пары при недоступности материала больного ребенка",
                fourthСell: "12100,00"                       
            },
            {
                firstCell: "12",
                secondCell: "21",
                thirdCell: "Синдром ломкой Х хромосомы: определение числа CGG повторов",
                fourthСell: "10000,00"                       
            },
            {
                firstCell: "514",
                secondCell: "90",
                thirdCell: "Муковисцидоз. Секвенирование гена CFTR",
                fourthСell: "30000,00"                       
            },            
            {
                firstCell: "29",
                secondCell: "90",
                thirdCell: "Синдром Драве. Секвенирование гена SCN1A",
                fourthСell: "30000,00"                       
            },
            {
                firstCell: "30",
                secondCell: "21",
                thirdCell: "Врожденная гиперплазия коры надпочечников (адреногенитальный синдром). Поиск 9-ти наиболее частых мутаций в гене CYP21A2",
                fourthСell: "9500,00"                       
            },
            {
                firstCell: "218",
                secondCell: "30",
                thirdCell: "Тандемная масс-спектрометрия (спектр ацилкарнитинов, аминокислот)",
                fourthСell: "4800,00"                       
            },
            {
                firstCell: "492",
                secondCell: "21",
                thirdCell: "Газовая хроматография образцов мочи (органические ацидурии)",
                fourthСell: "6500,00"                       
            },
            {
                firstCell: "38",
                secondCell: "21",
                thirdCell: "Альбинизм глазокожный: поиск мутаций в гене TYR",
                fourthСell: "11500,00"                       
            },
            {
                firstCell: "4",
                secondCell: "7",
                thirdCell: "Анализ полиморфизмов, ассоциированных с функциями интерлейкина 28В",
                fourthСell: "1000,00"                       
            },
            {
                firstCell: "39",
                secondCell: "90",
                thirdCell: "Альбинизм глазокожный: поиск мутаций в гене OCA2",
                fourthСell: "30000,00"                       
            },
            {
                firstCell: "3",
                secondCell: "7",
                thirdCell: "Анализ полиморфизма c.-13910C>T, ассоциированного с метаболизмом лактозы",
                fourthСell: "1300,00"                       
            },
            {
                firstCell: "46",
                secondCell: "21",
                thirdCell: "Анемия Даймонда-Блекфена: поиск мутаций в гене RPS19",
                fourthСell: "12100,00"                       
            },
            {
                firstCell: "57",
                secondCell: "14",
                thirdCell: "Атрофия зрительного нерва Лебера: поиск трех частых мутаций митохондриальной ДНК",
                fourthСell: "5800,00"                       
            },
            {
                firstCell: "58",
                secondCell: "21",
                thirdCell: "Атрофия зрительного нерва Лебера: поиск 12-ти частых мутаций митохондриальной ДНК",
                fourthСell: "9200,00"                       
            },
            {
                firstCell: "59",
                secondCell: "90",
                thirdCell: "Ахондроплазия: секвенирование гена FGFR3",
                fourthСell: "30000,00"                       
            },
            {
                firstCell: "64",
                secondCell: "14",
                thirdCell: "Болезнь Вильсона-Коновалова: поиск 12 наиболее частых мутаций в гене ATP7B",
                fourthСell: "8100,00"                       
            },
            {
                firstCell: "66",
                secondCell: "21",
                thirdCell: "Болезнь Гиршпрунга: поиск мутаций в гене EDNRB",
                fourthСell: "15000,00"                       
            },
            {
                firstCell: "67",
                secondCell: "21",
                thirdCell: "Болезнь Гиршпрунга: поиск мутаций в экзонах 10, 11, 13, 14, 15 гена RET",
                fourthСell: "10500,00"                       
            },
            {
                firstCell: "68",
                secondCell: "30",
                thirdCell: "Болезнь Гиршпрунга: поиск мутаций в гене NTRK1",
                fourthСell: "22000,00"                       
            },
            {
                firstCell: "69",
                secondCell: "30",
                thirdCell: "Болезнь Гиршпрунга: поиск мутаций в гене ZEB2",
                fourthСell: "27000,00"                       
            },
            {
                firstCell: "85",
                secondCell: "30",
                thirdCell: "Гипертрофическая кардиомиопатия: поиск мутаций в гене TNNT2",
                fourthСell: "22000,00"                       
            },
            {
                firstCell: "102",
                secondCell: "21",
                thirdCell: "Ихтиоз вульгарный: поиск частых мутаций в гене FLG",
                fourthСell: "8100,00"                       
            },
            {
                firstCell: "103",
                secondCell: "21",
                thirdCell: "Ихтиоз ламеллярный: поиск мутаций в гене TGM1",
                fourthСell: "18000,00"                       
            },            
            {
                firstCell: "104",
                secondCell: "21",
                thirdCell: "Липодистрофия: поиск мутаций в «горячих» участках гена LMNA",
                fourthСell: "6300,00"                       
            },
            {
                firstCell: "105",
                secondCell: "21",
                thirdCell: "Липодистрофия: поиск мутаций в гене LMNA",
                fourthСell: "18000,00"                       
            },
            {
                firstCell: "107",
                secondCell: "14",
                thirdCell: "Миотония Томсена/Беккера: поиск частых мутаций в гене CLCN1",
                fourthСell: "8100,00"                       
            },
            {
                firstCell: "113",
                secondCell: "21",
                thirdCell: "Муковисцидоз: поиск крупных делеций/дупликаций в гене CFTR",
                fourthСell: "10400,00"                       
            },
            {
                firstCell: "114",
                secondCell: "30",
                thirdCell: "Синдром Ретта: поиск мутаций в гене MECP2",
                fourthСell: "15000,00"                       
            },
            {
                firstCell: "117",
                secondCell: "21",
                thirdCell: "Мышечная дистрофия Дюшенна/Беккера: поиск делеций и дупликаций в гене дистрофина у мальчиков",
                fourthСell: "12700,00"                       
            },
            {
                firstCell: "122",
                secondCell: "21",
                thirdCell: "Мышечная дистрофия Эмери-Дрейфуса: поиск мутаций в гене EMD",
                fourthСell: "8900,00"                       
            },
            {
                firstCell: "123",
                secondCell: "21",
                thirdCell: "Мышечная дистрофия Эмери-Дрейфуса: поиск мутаций в гене LMNA",
                fourthСell: "18000,00"                       
            },
            {
                firstCell: "305",
                secondCell: "90",
                thirdCell: "Глициновая энцефалопатия (секвенирование генов GLDC, GCSH, AMT)",
                fourthСell: "35000,00"                       
            },
            {
                firstCell: "124",
                secondCell: "21",
                thirdCell: "Мышечная дистрофия Эмери-Дрейфуса: поиск мутаций в гене FHL1",
                fourthСell: "15000,00"                       
            },
            {
                firstCell: "125",
                secondCell: "14",
                thirdCell: "Наследственная моторно-сенсорная нейропатия (болезнь Шарко-Мари-Тута) тип I: поиск дупликаций на хромосоме 17 в области гена PMP22",
                fourthСell: "5800,00"                       
            },
            {
                firstCell: "126",
                secondCell: "21",
                thirdCell: "Наследственная моторно-сенсорная нейропатия (болезнь Шарко-Мари-Тута) тип I: поиск мутаций в гене GJB1 (Cx32)",
                fourthСell: "7500,00"                       
            },
            {
                firstCell: "129",
                secondCell: "21",
                thirdCell: "Наследственная моторно-сенсорная нейропатия (болезнь Шарко-Мари-Тута) тип I: поиск мутаций в гене EGR2",
                fourthСell: "10400,00"                       
            },
            {
                firstCell: "132",
                secondCell: "14",
                thirdCell: "Наследственная моторно-сенсорная нейропатия (болезнь Шарко-Мари-Тута) тип I: поиск частых рецессивных мутаций в генах FGD4, SH3TC2, FIG4, GDAP1",
                fourthСell: "8100,00"                       
            },
            {
                firstCell: "133",
                secondCell: "14",
                thirdCell: "Наследственная моторно-сенсорная нейропатия (болезнь Шарко-Мари-Тута) тип I: поиск частых мутаций в генах NDRG1 и SH3TC2",
                fourthСell: "4600,00"                       
            },
            {
                firstCell: "136",
                secondCell: "21",
                thirdCell: "Наследственная моторно-сенсорная нейропатия (болезнь Шарко-Мари-Тута) тип II: поиск мутаций в гене GDAP1",
                fourthСell: "13800,00"                       
            },
            {
                firstCell: "137",
                secondCell: "21",
                thirdCell: "Наследственная моторно-сенсорная нейропатия (болезнь Шарко-Мари-Тута) тип II: поиск мутаций в гене NEFL",
                fourthСell: "12000,00"                       
            },
            {
                firstCell: "140",
                secondCell: "90",
                thirdCell: "Наследственная моторно-сенсорная нейропатия (болезнь Шарко-Мари-Тута) тип II: поиск мутаций в гене DNM2",
                fourthСell: "30000,00"                       
            },
            {
                firstCell: "142",
                secondCell: "90",
                thirdCell: "Наследственная моторно-сенсорная нейропатия (болезнь Шарко-Мари-Тута) тип II: поиск мутаций в гене FIG4",
                fourthСell: "30000,00"                       
            },
            {
                firstCell: "143",
                secondCell: "14",
                thirdCell: "Нейросенсорная несиндромальная тугоухость: поиск частых мутаций в гене GJB2",
                fourthСell: "2500,00"                       
            },            
            {
                firstCell: "152",
                secondCell: "21",
                thirdCell: "Прогерия Хатчинсона-Гилфорда: поиск мутаций в гене LMNA",
                fourthСell: "21000,00"                       
            },
            {
                firstCell: "157",
                secondCell: "90",
                thirdCell: "Спастическая параплегия Штрюмпеля: поиск мутаций в гене ATL1",
                fourthСell: "30000,00"                       
            },
            {
                firstCell: "161",
                secondCell: "14",
                thirdCell: "Синдром Прадера-Вилли/Ангельмана",
                fourthСell: "6900,00"                       
            },
            {
                firstCell: "163",
                secondCell: "21",
                thirdCell: "Псевдоксантома эластическая: поиск частых мутаций в гене ABCC6",
                fourthСell: "6300,00"                       
            },
            {
                firstCell: "164",
                secondCell: "30",
                thirdCell: "Псевдоксантома эластическая: поиск мутаций в гене ABCC6",
                fourthСell: "47000,00"                       
            },
            {
                firstCell: "169",
                secondCell: "30",
                thirdCell: "Синдром Аарскога-Скотта: секвенирование гена FGD1",
                fourthСell: "25000,00"                       
            },
            {
                firstCell: "170",
                secondCell: "21",
                thirdCell: "Синдром Ваарденбурга: поиск мутаций в гене PAX3",
                fourthСell: "15000,00"                       
            },
            {
                firstCell: "171",
                secondCell: "21",
                thirdCell: "Синдром Вильямса: поиск делеций в регионе 7q11",
                fourthСell: "10400,00"                       
            },
            {
                firstCell: "173",
                secondCell: "90",
                thirdCell: "Синдром Коккейна: поиск мутаций в гене ERCC6",
                fourthСell: "30000,00"                       
            },
            {
                firstCell: "175",
                secondCell: "90",
                thirdCell: "Синдром Коффина-Лоури: поиск мутаций в гене RPS6KA3",
                fourthСell: "30000,00"                       
            },
            {
                firstCell: "178",
                secondCell: "21",
                thirdCell: "Поиск мутаций в гене BCS1L",
                fourthСell: "10400,00"                       
            },
            {
                firstCell: "179",
                secondCell: "90",
                thirdCell: "Синдром Марфана: поиск мутаций в гене FBN1",
                fourthСell: "30000,00"                       
            },
            {
                firstCell: "180",
                secondCell: "90",
                thirdCell: "Синдром множественной эндокринной неоплазии второго типа (МЭН2): секвенирование гена RET",
                fourthСell: "30000,00"                       
            },
            {
                firstCell: "184",
                secondCell: "30",
                thirdCell: "Синдром Мовата-Вильсона: поиск мутаций в гене ZEB2",
                fourthСell: "25500,00"                       
            },
            {
                firstCell: "188",
                secondCell: "14",
                thirdCell: "Синдром Смит-Магенис: поиск делеций в регионе 17p11.2",
                fourthСell: "15000,00"                       
            },
            {
                firstCell: "190",
                secondCell: "90",
                thirdCell: "Синдром Уокера-Варбург: поиск мутаций в генах POMT1 и FKRP",
                fourthСell: "30000,00"                       
            },
            {
                firstCell: "192",
                secondCell: "21",
                thirdCell: "Синдром фон Хиппеля-Линдау: секвенирование гена VHL",
                fourthСell: "8000,00"                       
            },
            {
                firstCell: "197",
                secondCell: "14",
                thirdCell: "Спинальная амиотрофия типы I, II, III, IV: поиск делеций в гене SMN1",
                fourthСell: "6700,00"                       
            },
            {
                firstCell: "204",
                secondCell: "90",
                thirdCell: "Туберозный склероз: поиск мутаций в гене TSC1 и TSC2",
                fourthСell: "30000,00"                       
            },
            {
                firstCell: "210",
                secondCell: "14",
                thirdCell: "Хорея Гентингтона: поиск наиболее частых мутаций в гене HTT",
                fourthСell: "5500,00"                       
            },            
            {
                firstCell: "211",
                secondCell: "21",
                thirdCell: "Тяжелый комбинированный иммунодефицит, Х-сцепленный: поиск мутаций в гене IL2RG",
                fourthСell: "10400,00"                       
            },
            {
                firstCell: "212",
                secondCell: "21",
                thirdCell: "Ихтиоз ламеллярный: поиск мутаций в гене ALOX12B",
                fourthСell: "17000,00"                       
            },
            {
                firstCell: "213",
                secondCell: "90",
                thirdCell: "Экзостозы множественные: поиск мутаций в гене EXT1 и EXT2",
                fourthСell: "30000,00"                       
            },
            {
                firstCell: "225",
                secondCell: "14",
                thirdCell: "Синдром Беквита-Видемана",
                fourthСell: "6900,00"                       
            },
            {
                firstCell: "228",
                secondCell: "90",
                thirdCell: "Уолкотта-Раллисона синдром: секвенирование гена EIF2AK3",
                fourthСell: "30000,00"                       
            },
            {
                firstCell: "229",
                secondCell: "90",
                thirdCell: "IPEX синдром: поиск мутаций в гене FOXP3",
                fourthСell: "30000,00"                       
            },
            {
                firstCell: "230",
                secondCell: "90",
                thirdCell: "Вольфрама синдром (поиск мутаций в генах WFS1 и CISD2)",
                fourthСell: "30000,00"                       
            },
            {
                firstCell: "231",
                secondCell: "90",
                thirdCell: "Синдром диабета и кист почек: секвенирование гена HNF1B",
                fourthСell: "30000,00"                       
            },
            {
                firstCell: "233",
                secondCell: "90",
                thirdCell: "Альстрема синдром (секвенирование гена ALMS1)",
                fourthСell: "30000,00"                       
            },
            {
                firstCell: "235",
                secondCell: "90",
                thirdCell: "Анемия Фанкони",
                fourthСell: "30000,00"                       
            },
            {
                firstCell: "237",
                secondCell: "90",
                thirdCell: "Х-сцепленная сидеробластная анемия (cеквенирование гена ALAS2)",
                fourthСell: "30000,00"                       
            },
            {
                firstCell: "238",
                secondCell: "90",
                thirdCell: "Врожденная амегакариоцитарная тромбоцитопения (cеквенирование гена MPL)",
                fourthСell: "30000,00"                       
            },
            {
                firstCell: "245",
                secondCell: "20",
                thirdCell: "Галактоземия (секвенирование гена GALT)",
                fourthСell: "18000,00"                       
            },
            {
                firstCell: "247",
                secondCell: "90",
                thirdCell: "Врожденный гипотиреоз (cеквенирование генов TSHR, TSHB, NKX2-5)",
                fourthСell: "30000,00"                       
            },
            {
                firstCell: "249",
                secondCell: "90",
                thirdCell: "Цитруллинемия (секвенирование генов ASS1, SLC25A13)",
                fourthСell: "30000,00"                       
            },
            {
                firstCell: "261",
                secondCell: "90",
                thirdCell: "Тюрко синдром (секвенирование генов APC, MLH1, MSH2, MSH6)",
                fourthСell: "30000,00"                       
            },
            {
                firstCell: "267",
                secondCell: "90",
                thirdCell: "Рефсума синдром (секвенирование гена PHYH)",
                fourthСell: "30000,00"                       
            },
            {
                firstCell: "267",
                secondCell: "90",
                thirdCell: "Рефсума синдром (секвенирование гена PHYH)",
                fourthСell: "30000,00"                       
            },
            {
                firstCell: "268",
                secondCell: "90",
                thirdCell: "Арта синдром (секвенирование гена PRPS1)",
                fourthСell: "30000,00"                       
            },
            {
                firstCell: "269",
                secondCell: "90",
                thirdCell: "Андерманна синдром (cеквенирование гена SLC12A6)",
                fourthСell: "30000,00"                       
            },            
            {
                firstCell: "273",
                secondCell: "14",
                thirdCell: "Синдром Блума (поиск частых мутаций в гене RECQL3 (BLM)",
                fourthСell: "5800,00"                       
            },
            {
                firstCell: "274",
                secondCell: "90",
                thirdCell: "Паллистера-Холла синдром (секвенирование гена GLI3)",
                fourthСell: "30000,00"                       
            },
            {
                firstCell: "279",
                secondCell: "90",
                thirdCell: "Недостаточность длинноцепочечной ацил-КоА дегидрогеназы (секвенирование гена ACADVL)",
                fourthСell: "30000,00"                       
            },
            {
                firstCell: "280",
                secondCell: "90",
                thirdCell: "Недостаточность короткоцепочечной ацил-КоА дегидрогеназы (секвенирование гена ACADS)",
                fourthСell: "30000,00"                       
            },
            {
                firstCell: "281",
                secondCell: "90",
                thirdCell: "Недостаточность 3-гидроксиацил-КоА дегидрогеназы (cеквенирование генов HADHA и HADНВ)",
                fourthСell: "30000,00"                       
            },
            {
                firstCell: "284",
                secondCell: "90",
                thirdCell: "Аспартилглюкозаминурия (секвенирование гена AGA)",
                fourthСell: "30000,00"                       
            },
            {
                firstCell: "287",
                secondCell: "90",
                thirdCell: "Гомоцистинурия (секвенирование генов CBS, MTHFR, MTR, MTRR, MMADHC)",
                fourthСell: "30000,00"                       
            },
            {
                firstCell: "306",
                secondCell: "90",
                thirdCell: "Гиперпролинемия 1 и 2 типа (секвенирование генов ALDH4A1, PRODH)",
                fourthСell: "30000,00"                       
            },
            {
                firstCell: "319",
                secondCell: "90",
                thirdCell: "Стиклера синдром, тип 1 (секвенирование гена COL2A1)",
                fourthСell: "30000,00"                       
            },
            {
                firstCell: "314",
                secondCell: "90",
                thirdCell: "Ганглиозидоз G1, G2, AB вариант (секвенирование генов GLB1, GM2A)",
                fourthСell: "30000,00"                       
            },
            {
                firstCell: "332",
                secondCell: "90",
                thirdCell: "Унферрихта-Лундберга болезнь (секвенирование гена CSTB)",
                fourthСell: "30000,00"                       
            },
            {
                firstCell: "289",
                secondCell: "90",
                thirdCell: "Изовалериановая ацидемия (cеквенирование гена IVD)",
                fourthСell: "30000,00"                       
            },
            {
                firstCell: "291",
                secondCell: "90",
                thirdCell: "Глутаровая ацидемия IIA, B, C (секвенирование генов ETFA, ETFB, ETFDH)",
                fourthСell: "30000,00"                       
            },
            {
                firstCell: "292",
                secondCell: "90",
                thirdCell: "Болезнь Фабри (секвенирование гена GLA)",
                fourthСell: "30000,00"                       
            },
            {
                firstCell: "293",
                secondCell: "90",
                thirdCell: "Краббе болезнь (секвенирование гена GALC)",
                fourthСell: "30000,00"                       
            },
            {
                firstCell: "294",
                secondCell: "90",
                thirdCell: "Ниманна-Пика болезнь (секвенирование генов NPC1, NPC2, SMPD1)",
                fourthСell: "30000,00"                       
            },
            {
                firstCell: "296",
                secondCell: "90",
                thirdCell: "Мукополисахаридоз 1 типа (cеквенирование гена IDUA)",
                fourthСell: "30000,00"                       
            },
            {
                firstCell: "297",
                secondCell: "90",
                thirdCell: "Мукополисахаридоз 2 типа (секвенирование гена IDS)",
                fourthСell: "30000,00"                       
            },
            {
                firstCell: "300",
                secondCell: "90",
                thirdCell: "Пропионовая ацидемия (cеквенирование генов PCCA, PCCB)",
                fourthСell: "30000,00"                       
            },
            {
                firstCell: "309",
                secondCell: "90",
                thirdCell: "Метилмалоновая ацидемия (секвенирование генов MUT, MMAA, MMAB, MCEE, MMADHC)",
                fourthСell: "30000,00"                       
            },            
            {
                firstCell: "313",
                secondCell: "90",
                thirdCell: "Недостаточность карнитин пальмитоилтрансферазы (секвенирование генов СРТ1A, CPT1B, CPT2)",
                fourthСell: "30000,00"                       
            },
            {
                firstCell: "317",
                secondCell: "90",
                thirdCell: "Гистидинемия (секвенирование гена HAL)",
                fourthСell: "30000,00"                       
            },
            {
                firstCell: "322",
                secondCell: "90",
                thirdCell: "Псевдоахондроплазия (секвенирование гена COMP)",
                fourthСell: "30000,00"                       
            },
            {
                firstCell: "323",
                secondCell: "90",
                thirdCell: "Джексона-Вейсса синдром (секвенирование генов FGFR2 и FGFR1)",
                fourthСell: "30000,00"                       
            },
            {
                firstCell: "324",
                secondCell: "90",
                thirdCell: "Апера синдром (секвенирование гена FGFR2)",
                fourthСell: "30000,00"                       
            },
            {
                firstCell: "326",
                secondCell: "90",
                thirdCell: "Множественная эпифизарная дисплазия (секвенирование генов COMP и SLC26A2)",
                fourthСell: "30000,00"                       
            },
            {
                firstCell: "334",
                secondCell: "90",
                thirdCell: "Синдром хондродисплазии с улиткоподобной формой таза (Schneckenbecken dysplasia). Секвенирование гена SLC35D1",
                fourthСell: "30000,00"                       
            },
            {
                firstCell: "335",
                secondCell: "90",
                thirdCell: "Миотоническая дистрофия тип 1 и 2 (секвенирование генов DMPK и ZNF9)",
                fourthСell: "30000,00"                       
            },
            {
                firstCell: "340",
                secondCell: "90",
                thirdCell: "Цистиноз (секвенирование гена CTNS)",
                fourthСell: "30000,00"                       
            },
            {
                firstCell: "341",
                secondCell: "90",
                thirdCell: "Синдром Бартера (секвенирование генов SLC12A1, KCNJ1, CLCNKB, CASR)",
                fourthСell: "30000,00"                       
            },
            {
                firstCell: "342",
                secondCell: "90",
                thirdCell: "Аутосомно-рецессивный злокачественный остеопетроз (секвенирование генов OSTM1, TCIRG1, CLCN7)",
                fourthСell: "30000,00"                       
            },
            {
                firstCell: "346",
                secondCell: "90",
                thirdCell: "Гиперкератоз ладонно-подошвенный (секвенирование генов KRT1 и KRT9)",
                fourthСell: "30000,00"                       
            },
            {
                firstCell: "349",
                secondCell: "90",
                thirdCell: "Синдром Пейтца-Егерса (секвенирование гена STK11)",
                fourthСell: "30000,00"                       
            },
            {
                firstCell: "350",
                secondCell: "90",
                thirdCell: "Аденоматозный полипоз (секвенирование гена АРС)",
                fourthСell: "30000,00"                       
            },
            {
                firstCell: "351",
                secondCell: "14",
                thirdCell: "Синдром Жильбера",
                fourthСell: "3700,00"                       
            },
            {
                firstCell: "352",
                secondCell: "90",
                thirdCell: "Синдром Алажилля (секвенирование гена JAG1)",
                fourthСell: "30000,00"                       
            },
            {
                firstCell: "353",
                secondCell: "90",
                thirdCell: "Синдром удлиненного интервала QT",
                fourthСell: "30000,00"                       
            },
            {
                firstCell: "361",
                secondCell: "90",
                thirdCell: "Гипофосфатемический витамин-D-резистентный рахит (секвенирование гена PHEX)",
                fourthСell: "30000,00"                       
            },
            {
                firstCell: "491",
                secondCell: "90",
                thirdCell: "Опухоль Вильмса (секвенирование гена WT1)",
                fourthСell: "30000,00"                       
            },
            {
                firstCell: "503",
                secondCell: "90",
                thirdCell: "Тирозинемия тип 1 (секвенирование гена FAH)",
                fourthСell: "30000,00"                       
            },            
            {
                firstCell: "542",
                secondCell: "90",
                thirdCell: "Синдром Цельвегера",
                fourthСell: "30000,00"                       
            },
            {
                firstCell: "544",
                secondCell: "90",
                thirdCell: "Синдром Чедиака-Хигаши (секвенирование гена LYST)",
                fourthСell: "30000,00"                       
            },
            {
                firstCell: "546",
                secondCell: "90",
                thirdCell: "Голопрозэнцефалия (секвенирование генов FGF8, GLI2, GLI3, PTCH1, SHH, SIX3, TGIF1, ZIC2",
                fourthСell: "30000,00"                       
            },
            {
                firstCell: "548",
                secondCell: "90",
                thirdCell: "Врожденная дизэритропоэтическая анемия (секвенирование генов CDAN1, SEC23B)",
                fourthСell: "30000,00"                       
            },
            {
                firstCell: "550",
                secondCell: "90",
                thirdCell: "Дистрофия роговицы (секвенирование генов TGFBI, SLC4A11)",
                fourthСell: "30000,00"                       
            },
            {
                firstCell: "561",
                secondCell: "90",
                thirdCell: "Айкарди-Гутьерес синдром (секвенирование генов TREX1, RNASEH2B, ADAR)",
                fourthСell: "30000,00"                       
            },
            {
                firstCell: "520",
                secondCell: "90",
                thirdCell: "Талассемия",
                fourthСell: "30000,00"                       
            },
            {
                firstCell: "545",
                secondCell: "90",
                thirdCell: "Наследственная моторно-сенсорная демиелинизрующая нейропатия",
                fourthСell: "30000,00"                       
            },
            {
                firstCell: "579",
                secondCell: "90",
                thirdCell: "Секвенирование гена AR",
                fourthСell: "30000,00"                       
            },
            {
                firstCell: "605",
                secondCell: "30",
                thirdCell: "Подтверждение мутации, выявленной при NGS секвенированием по Сэнгеру",
                fourthСell: "5000,00"                       
            },
            {
                firstCell: "612",
                secondCell: "21",
                thirdCell: "Синдром Аксенфельда-Ригера: поиск мутаций в гене FOXC1",
                fourthСell: "9200,00"                       
            },
            {
                firstCell: "613",
                secondCell: "21",
                thirdCell: "Синдром Андерсена: поиск мутаций в гене KCNJ2",
                fourthСell: "9000,00"                       
            },
            {
                firstCell: "614",
                secondCell: "21",
                thirdCell: "Синдром Антли-Бикслера: поиск мутаций в экзоне 9 гена FGFR2",
                fourthСell: "6900,00"                       
            },
            {
                firstCell: "616",
                secondCell: "21",
                thirdCell: "Аутоиммунный лимфопролиферативный синдром: поиск мутаций в «горячих» участках гена TNFRSF6 (FAS)",
                fourthСell: "6000,00"                       
            },
            {
                firstCell: "617",
                secondCell: "21",
                thirdCell: "Аутоиммунный лимфопролиферативный синдром: поиск мутаций в гене TNFRSF6 (FAS)",
                fourthСell: "14000,00"                       
            },
            {
                firstCell: "618",
                secondCell: "21",
                thirdCell: "Первичная прогрессирующая афазия: поиск мутаций в гене GRN",
                fourthСell: "11000,00"                       
            },
            {
                firstCell: "621",
                secondCell: "21",
                thirdCell: "Синдром Баннаян-Райли-Рувалькаба",
                fourthСell: "15500,00"                       
            },
            {
                firstCell: "623",
                secondCell: "21",
                thirdCell: "Синдром Бёрта-Хога-Дьюба: поиск мутаций в гене FLCN",
                fourthСell: "20000,00"                       
            },
            {
                firstCell: "626",
                secondCell: "21",
                thirdCell: "Синдром Боуэна-Конради: поиск мутаций в гене EMG1",
                fourthСell: "10400,00"                       
            },
            {
                firstCell: "627",
                secondCell: "21",
                thirdCell: "Брахидактилия: поиск мутаций в гене HOXD13",
                fourthСell: "10400,00"                       
            },            
            {
                firstCell: "628",
                secondCell: "21",
                thirdCell: "Брахидактилия: поиск мутаций в экзонах 8 и 9 гена ROR2",
                fourthСell: "9200,00"                       
            },
            {
                firstCell: "629",
                secondCell: "21",
                thirdCell: "Брахидактилия: поиск мутаций в гене NOG",
                fourthСell: "5800,00"                       
            },
            {
                firstCell: "630",
                secondCell: "21",
                thirdCell: "Синдром Ван дер Вуда: поиск мутаций в гене IRF6",
                fourthСell: "15500,00"                       
            },
            {
                firstCell: "632",
                secondCell: "14",
                thirdCell: "Синдром врожденной центральной гиповентиляции: поиск частых мутаций в гене PHOX2B",
                fourthСell: "5800,00"                       
            },
            {
                firstCell: "633",
                secondCell: "21",
                thirdCell: "Синдром Галлервордена-Шпатца: поиск наиболее частых мутаций в гене PANK2",
                fourthСell: "6300,00"                       
            },
            {
                firstCell: "637",
                secondCell: "21",
                thirdCell: "Синдром Германски-Пудлака: поиск частых мутаций в гене HPS1",
                fourthСell: "7500,00"                       
            },
            {
                firstCell: "657",
                secondCell: "14",
                thirdCell: "Фенилкетонурия: расширенный поиск мутаций в гене PAH (25 шт.)",
                fourthСell: "9900,00"                       
            },
            {
                firstCell: "658",
                secondCell: "21",
                thirdCell: "Фенилкетонурия: поиск мутаций в гене PAH",
                fourthСell: "20000,00"                       
            },
            {
                firstCell: "659",
                secondCell: "21",
                thirdCell: "Торсионная дистония: поиск мутаций в гене TOR1A",
                fourthСell: "11400,00"                       
            },
            {
                firstCell: "785",
                secondCell: "14",
                thirdCell: "Муковисцидоз: Расширенный поиск частых мутаций в гене CFTR (30 шт.)",
                fourthСell: "10400,00"                       
            },
            {
                firstCell: "791",
                secondCell: "21",
                thirdCell: "Поиск наиболее частых мутаций в гене AR",
                fourthСell: "5800,00"                       
            },
            {
                firstCell: "828",
                secondCell: "14",
                thirdCell: "Поиск делеций мтДНК методом лонг-ПЦР",
                fourthСell: "5500,00"                       
            },
            {
                firstCell: "833",
                secondCell: "14",
                thirdCell: "Синдром Сильвера-Рассела",
                fourthСell: "6900,00"                       
            },
            {
                firstCell: "859",
                secondCell: "21",
                thirdCell: "Анализ числа (CAG)-повторов в гене андрогенового рецептора (AR), частые делеции в AZF локусе, частые мутации в гене CFTR (22 шт.+IVS8TT)",
                fourthСell: "9000,00"                       
            },
            {
                firstCell: "864",
                secondCell: "30",
                thirdCell: "Подтверждение мутации, выявленной при NGS секвенированием по Сэнгеру у трио",
                fourthСell: "13000,00"                       
            },
            {
                firstCell: "865",
                secondCell: "21",
                thirdCell: "Поиск делеций в гене NF1 методом MLPA",
                fourthСell: "6900,00"                       
            },
            {
                firstCell: "962",
                secondCell: "14",
                thirdCell: "Поиск наиболее частых мутаций в генах ATXN1, ATXN2, ATXN3",
                fourthСell: "7600,00"                       
            },
            {
                firstCell: "987",
                secondCell: "21",
                thirdCell: "Наследственная нейропатия с подверженностью параличу от сдавления (поиск мутаций в гене PMP22)",
                fourthСell: "10400,00"                       
            },
            {
                firstCell: "988",
                secondCell: "90",
                thirdCell: "Клиническое секвенирование экзома трио",
                fourthСell: "140000,00"                       
            },
            {
                firstCell: "1005",
                secondCell: "21",
                thirdCell: "Частичный анализ гена NOTCH3 (CADASIL синдром)",
                fourthСell: "8000,00"                       
            },            
            {
                firstCell: "1056",
                secondCell: "40",
                thirdCell: "Лицелопаточно-плечевая мышечная дистрофия тип 1",
                fourthСell: "45000,00"                       
            },
            {
                firstCell: "1060",
                secondCell: "14",
                thirdCell: "Определение числа копий генов SMN1, SMN2",
                fourthСell: "8500,00"                       
            },
            {
                firstCell: "1098",
                secondCell: "14",
                thirdCell: "Поиск наиболее частых мутаций в гене ATXN7",
                fourthСell: "5300,00"                       
            },
            {
                firstCell: "1111",
                secondCell: "21",
                thirdCell: "Поиск мутаций в гене MEFV",
                fourthСell: "17000,00"                       
            },
            {
                firstCell: "1118",
                secondCell: "15",
                thirdCell: "Частичный анализ гена PLP методом MLPA -дупликации гена (Пелициуса-Мельцбахера)",
                fourthСell: "5500,00"                       
            },
            {
                firstCell: "1131",
                secondCell: "21",
                thirdCell: "Дефицит карнитина системный первичный (поиск мутаций в гене SLC22A5)",
                fourthСell: "17000,00"                       
            },
            {
                firstCell: "1143",
                secondCell: "14",
                thirdCell: "Синдром Ниймеген: Поиск наиболее частых мутаций в гене NBN",
                fourthСell: "4600,00"                       
            },
            {
                firstCell: "1147",
                secondCell: "21",
                thirdCell: "Поиск делеций в гене NF2 методом MLPA",
                fourthСell: "6900,00"                       
            },
            {
                firstCell: "1187",
                secondCell: "90",
                thirdCell: "Синдром Нунан",
                fourthСell: "35000,00"                       
            },
            {
                firstCell: "1210",
                secondCell: "21",
                thirdCell: "Поиск наиболее частых мутаций в экзонах 10, 11 гена RET при МЭН2А",
                fourthСell: "7300,00"                       
            },
            {
                firstCell: "1217",
                secondCell: "21",
                thirdCell: "Поиск частых мутаций в гене GALT",
                fourthСell: "4500,00"                       
            },
            {
                firstCell: "1290",
                secondCell: "14",
                thirdCell: "Окулофарингеальная мышечная дистрофия: поиск наиболее частых мутаций в гене PABPN1",
                fourthСell: "5300,00"                       
            },
            {
                firstCell: "1292",
                secondCell: "14",
                thirdCell: "Поиск частых мутаций в гене POLG методом MLPA",
                fourthСell: "5500,00"                       
            },
            {
                firstCell: "1293",
                secondCell: "21",
                thirdCell: "Поиск мутаций в «горячих» участках гена ACVR1",
                fourthСell: "10000,00"                       
            },
            {
                firstCell: "1437",
                secondCell: "21",
                thirdCell: "Поиск мутаций в гене GJB2 (Cx26)",
                fourthСell: "4200,00"                       
            },
            {
                firstCell: "706",
                secondCell: "3",
                thirdCell: "Тест на отцовство/материнство 20 маркеров (3 участника: отец+ребенок+мать, один из родителей безусловный)",
                fourthСell: "11900,00"                       
            },
            {
                firstCell: "705",
                secondCell: "3",
                thirdCell: "Тест на отцовство/материнство, 25 маркеров (2 участника: отец+ребенок или мать+ребенок)",
                fourthСell: "8800,00"                       
            },
            {
                firstCell: "688",
                secondCell: "7",
                thirdCell: "Тест на отцовство/материнство для суда, 20 маркеров (2 участника: отец+ребенок или мать+ребенок)",
                fourthСell: "13900,00"                       
            },
            {
                firstCell: "712",
                secondCell: "7",
                thirdCell: "Тест на отцовство/материнство для суда 20 маркеров (3 участника: отец+ребенок+мать, один из родителей безусловный)",
                fourthСell: "14900,00"                       
            },
            {
                firstCell: "725",
                secondCell: "",
                thirdCell: "Дополнительный участник к анализу 20/26 маркеров",
                fourthСell: "6450,00"                       
            },            
            {
                firstCell: "753",
                secondCell: "7",
                thirdCell: "Тестирование Y-хромосомы - тест на родство по мужской линии (2 участника: дедушка по отцу - внук, дядя (брат отца) - племянник, родные/сводные по отцу братья)",
                fourthСell: "13800,00"                       
            },
            {
                firstCell: "697",
                secondCell: "7",
                thirdCell: "Тест на родство, 24 маркера (2 участника: дедушка/бабушка - внук/внучка, дядя/тетя - племянник/племянница , родные/сводные братья/сестры)",
                fourthСell: "14800,00"                       
            },
            {
                firstCell: "693",
                secondCell: "7",
                thirdCell: "Тестирование Х-хромосомы (2 участника: бабушка по отцу - внучка, сводные сестры по отцу)",
                fourthСell: "16800,00"                       
            },
            {
                firstCell: "756",
                secondCell: "7",
                thirdCell: "ДНК -профилирование (25 маркеров, Х или Y-хромосома) (1 человек) для суда",
                fourthСell: "9450,00"                       
            },
            {
                firstCell: "760",
                secondCell: "",
                thirdCell: "Выделение ДНК из нестандартного образца (высохшие пятна крови, обрезки ногтей, волосы) (1 человек) + 1рд",
                fourthСell: "3000,00"                       
            },
            {
                firstCell: "691",
                secondCell: "8 рабочих часов",
                thirdCell: "ЭКСПРЕСС- установление отцовства/материнства, информативный за 8 рабочих часов",
                fourthСell: "28500,00"                       
            },
            {
                firstCell: "690",
                secondCell: "8 рабочих часов",
                thirdCell: "ЭКСПРЕСС-установление родства для трех участников (отец/мать/ребенок), информативный за 8 рабочих часов",
                fourthСell: "35000,00"                       
            },
            {
                firstCell: "755",
                secondCell: "7",
                thirdCell: "ДНК -профилирование (25 маркеров, Х или У-хромосома) (1 человек) Информативный",
                fourthСell: "6450,00"                       
            },
            {
                firstCell: "726",
                secondCell: "",
                thirdCell: "ДОПОЛНИТЕЛЬНЫЙ участник по Х - хромосоме или по Y - хромосоме",
                fourthСell: "4500,00"                       
            },
            {
                firstCell: "761",
                secondCell: "+1 рд",
                thirdCell: "Выделение ДНК из нестандартного образца (жевательная резинка, сигаретный фильтр, ушная сера,сперма) (1 человек)",
                fourthСell: "3500,00"                       
            },
            {
                firstCell: "762",
                secondCell: "1",
                thirdCell: "Выделение ДНК из нестандартного образца ( парафиновые блоки) (1 человек) +1рд",
                fourthСell: "4000,00"                       
            },
            {
                firstCell: "763",
                secondCell: "+1 рд",
                thirdCell: "Выделение ДНК из нестандартного образца (коктельная трубочка, лезвие станка, зубная щетка, детская пустышка) (1 человек) +1рд",
                fourthСell: "4500,00"                       
            },
            {
                firstCell: "770",
                secondCell: "0",
                thirdCell: "Второй экземпляр информативного теста",
                fourthСell: "500,00"                       
            },
            {
                firstCell: "1404",
                secondCell: "7",
                thirdCell: "Тестирование Y-хромосомы - тест на родство по мужской линии (2 участника: дедушка по отцу - внук, дядя (брат отца) - племянник, родные/сводные по отцу братья) по суду",
                fourthСell: "18900,00"                       
            },
            {
                firstCell: "699",
                secondCell: "",
                thirdCell: "Определение генетического профиля по 19 аутосомным STR-маркерам",
                fourthСell: "по запросу"                       
            },
            {
                firstCell: "552",
                secondCell: "30",
                thirdCell: "Хромосомный микроматричный анализ «Стандартный»",
                fourthСell: "19500,00"                       
            },
            {
                firstCell: "13",
                secondCell: "21",
                thirdCell: "Кариотип, анализ экспертного уровня",
                fourthСell: "5400,00"                       
            },
            {
                firstCell: "504",
                secondCell: "15",
                thirdCell: "Хромосомный микроматричный анализ тканей из архивного материала",
                fourthСell: "45000,00"                       
            },
            {
                firstCell: "1304",
                secondCell: "30",
                thirdCell: "Хромосомный микроматричный анализ экзонного уровня",
                fourthСell: "33500,00"                       
            },
            {
                firstCell: "646",
                secondCell: "30",
                thirdCell: "Биоинформатический и клинический анализ данных секвенирования генома (данные предоставляются заказчиком)",
                fourthСell: "25000,00"                       
            },            
            {
                firstCell: "645",
                secondCell: "30",
                thirdCell: "Биоинформатический и клинический анализ данных секвенирования экзома (данные предоставляются заказчиком)",
                fourthСell: "7000,00"                       
            },
            {
                firstCell: "946",
                secondCell: "2",
                thirdCell: "Интерпретация 2-5 полиморфизмов",
                fourthСell: "700,00"                       
            },
            {
                firstCell: "885",
                secondCell: "14",
                thirdCell: "Панель «Фолатный цикл и риск гипергомоцистеинемии» - 10 маркеров",
                fourthСell: "8500,00"                       
            },
            {
                firstCell: "894",
                secondCell: "14",
                thirdCell: "Предрасположенность к гиперхолистеринемии, сердечно-сосудистым заболеваниям и болезни Альцгеймера (ген APOE)",
                fourthСell: "4200,00"                       
            },
            {
                firstCell: "901",
                secondCell: "21",
                thirdCell: "Панель «Медиаторные нарушения» - 8 маркеров",
                fourthСell: "8500,00"                       
            },
            {
                firstCell: "892",
                secondCell: "21",
                thirdCell: "Панель «Липидный обмен»",
                fourthСell: "8500,00"                       
            },
            {
                firstCell: "893",
                secondCell: "21",
                thirdCell: "Панель «Риск Сахарного диабета 2 типа»",
                fourthСell: "8500,00"                       
            },
            {
                firstCell: "881",
                secondCell: "7",
                thirdCell: "Панель «Гемохроматоз 1 типа» (HFE: H63D, С282Y, S65C)",
                fourthСell: "3500,00"                       
            },
            {
                firstCell: "883",
                secondCell: "14",
                thirdCell: "Предрасположенность к нарушению системы гемостаза и риску тромбообразования (13 маркеров).",
                fourthСell: "8500,00"                       
            },
            {
                firstCell: "882",
                secondCell: "30",
                thirdCell: "Панель «Нарушения системы гемостаза» 30 маркеров",
                fourthСell: "11000,00"                       
            },
            {
                firstCell: "897",
                secondCell: "40",
                thirdCell: "Панель «Нутригенетика max»",
                fourthСell: "33000,00"                       
            },
            {
                firstCell: "898",
                secondCell: "14",
                thirdCell: "Панель «Нутригенетика: Оптимальный вариант диеты для снижения веса»",
                fourthСell: "9000,00"                       
            },
            {
                firstCell: "929",
                secondCell: "14",
                thirdCell: "Панель «Нутригенетика: негативные последствия кофе»",
                fourthСell: "8500,00"                       
            },
            {
                firstCell: "1311",
                secondCell: "14",
                thirdCell: "Панель «Нутригенетика: Реакция организма на некоторые компоненты пищи»",
                fourthСell: "8000,00"                       
            },
            {
                firstCell: "899",
                secondCell: "21",
                thirdCell: "Панель «Нутригенетика - витамины»",
                fourthСell: "9800,00"                       
            },
            {
                firstCell: "1309",
                secondCell: "14",
                thirdCell: "Панель «Нутригенетика и спорт: Оптимальный вариант диеты и физических нагрузок для снижения веса»",
                fourthСell: "8500,00"                       
            },
            {
                firstCell: "1154",
                secondCell: "14",
                thirdCell: "Панель Нутригенетика: Витамин А",
                fourthСell: "8500,00"                       
            },
            {
                firstCell: "1155",
                secondCell: "14",
                thirdCell: "Панель Нутригенетика Витамин C",
                fourthСell: "8500,00"                       
            },
            {
                firstCell: "1156",
                secondCell: "14",
                thirdCell: "Панель Нутригенетика Витамин E",
                fourthСell: "8500,00"                       
            },
            {
                firstCell: "1157",
                secondCell: "14",
                thirdCell: "Метаболизм витамина D",
                fourthСell: "8500,00"                       
            },            
            {
                firstCell: "1158",
                secondCell: "14",
                thirdCell: "Панель Нутригенетика Витамин B9",
                fourthСell: "8500,00"                       
            },
            {
                firstCell: "1159",
                secondCell: "14",
                thirdCell: "Панель Нутригенетика Витамин B12",
                fourthСell: "5500,00"                       
            },
            {
                firstCell: "1160",
                secondCell: "14",
                thirdCell: "Панель Нутригенетика Витамин B2",
                fourthСell: "4500,00"                       
            },
            {
                firstCell: "1161",
                secondCell: "14",
                thirdCell: "Панель Нутригенетика Витамин B6",
                fourthСell: "4500,00"                       
            },
            {
                firstCell: "895",
                secondCell: "21",
                thirdCell: "Панель «Костный метаболизм. Остеопороз»",
                fourthСell: "8500,00"                       
            },
            {
                firstCell: "896",
                secondCell: "14",
                thirdCell: "Панель «Метаболизм глютена»",
                fourthСell: "8500,00"                       
            },
            {
                firstCell: "900",
                secondCell: "14",
                thirdCell: "Болезнь Бехтерева. Ревматоидный артрит. HLAB27",
                fourthСell: "2000,00"                       
            },
            {
                firstCell: "905",
                secondCell: "40",
                thirdCell: "Комплексный генетический тест",
                fourthСell: "35000,00"                       
            },
            {
                firstCell: "918",
                secondCell: "10",
                thirdCell: "Панель «Безопасность гормональной терапии»",
                fourthСell: "8500,00"                       
            },
            {
                firstCell: "1192",
                secondCell: "10",
                thirdCell: "Устойчивость к стрессу и склонность к зависимостям (анализ полиморфизмов гена COMT - 4 маркера)",
                fourthСell: "8500,00"                       
            },
            {
                firstCell: "927",
                secondCell: "14",
                thirdCell: "Панель «Антидепрессанты. Нейролептики»",
                fourthСell: "8500,00"                       
            },
            {
                firstCell: "1152",
                secondCell: "40",
                thirdCell: "ВСЕ обо МНЕ",
                fourthСell: "19500,00"                       
            },
            {
                firstCell: "1051",
                secondCell: "10",
                thirdCell: "Заказ 1 дополнительного полиморфизма к готовой панели (без интерпретации)",
                fourthСell: "600,00"                       
            },
            {
                firstCell: "1115",
                secondCell: "21",
                thirdCell: "Панель «Метаболический синдром и ожирение»",
                fourthСell: "8500,00"                       
            },
            {
                firstCell: "1162",
                secondCell: "14",
                thirdCell: "Панель «Антиоксидантная защита»",
                fourthСell: "8500,00"                       
            },
            {
                firstCell: "1196",
                secondCell: "60",
                thirdCell: "Микробиом - носоглотка (без интерпретации)",
                fourthСell: "12000,00"                       
            },
            {
                firstCell: "1197",
                secondCell: "60",
                thirdCell: "Микробиом - мочеполовая система (без интерпретации)",
                fourthСell: "12000,00"                       
            },
            {
                firstCell: "1198",
                secondCell: "60",
                thirdCell: "Микробиом кишечника",
                fourthСell: "12000,00"                       
            },
            {
                firstCell: "1300",
                secondCell: "14",
                thirdCell: "Панель «Подготовка к ЭКО»",
                fourthСell: "8500,00"                       
            },
            {
                firstCell: "1308",
                secondCell: "21",
                thirdCell: "Панель «Нейрогенетические особенности пищевого поведения»",
                fourthСell: "6000,00"                       
            },            
            {
                firstCell: "1315",
                secondCell: "40",
                thirdCell: "Панель «Женское здоровье (комплекс)»",
                fourthСell: "35000,00"                       
            },
            {
                firstCell: "1317",
                secondCell: "40",
                thirdCell: "Панель «Мужское здоровье (комплекс)»",
                fourthСell: "35000,00"                       
            },
            {
                firstCell: "1320",
                secondCell: "21",
                thirdCell: "Панель «Риск нарушений работы опорно-связочного аппарата»",
                fourthСell: "9900,00"                       
            },
            {
                firstCell: "1321",
                secondCell: "21",
                thirdCell: "Панель «Воспалительный ответ»",
                fourthСell: "9900,00"                       
            },
            {
                firstCell: "1325",
                secondCell: "21",
                thirdCell: "Панель «Косметология и Anti age»",
                fourthСell: "9900,00"                       
            },
            {
                firstCell: "1406",
                secondCell: "30",
                thirdCell: "Панель «ДНК - Генеалогия» по материнской линии",
                fourthСell: "10900,00"                       
            },
            {
                firstCell: "1407",
                secondCell: "30",
                thirdCell: "Панель «ДНК - Генеалогия» по отцовской линии",
                fourthСell: "10900,00"                       
            },
            {
                firstCell: "1418",
                secondCell: "30",
                thirdCell: "Панель «ДНК Генеалогия» национальность",
                fourthСell: "11700,00"                       
            },
            {
                firstCell: "1421",
                secondCell: "30",
                thirdCell: "Панель «ДНК Генеалогия» Происхождение по материнской и отцовской линии + национальность",
                fourthСell: "29900,00"                       
            },
            {
                firstCell: "1422",
                secondCell: "30",
                thirdCell: "Панель «ДНК Генеалогия» Происхождение по материнской линии + национальность",
                fourthСell: "20900,00"                       
            },
            {
                firstCell: "1423",
                secondCell: "30",
                thirdCell: "Панель «ДНК Генеалогия» Происхождение по отцовской линии + национальность",
                fourthСell: "20900,00"                       
            },
            {
                firstCell: "1681",
                secondCell: "10",
                thirdCell: "Скрининг на носительство наследственных заболеваний «Базовый»",
                fourthСell: "7000,00"                       
            },
            {
                firstCell: "575",
                secondCell: "45",
                thirdCell: "Скрининг на наследственные заболевания «Экспертный»",
                fourthСell: "30000,00"                       
            },
            {
                firstCell: "118",
                secondCell: "21",
                thirdCell: "Мышечная дистрофия Дюшенна/Беккера: поиск делеций и дупликаций у родственниц больного по женской линии",
                fourthСell: "14400,00"                       
            },
            {
                firstCell: "198",
                secondCell: "14",
                thirdCell: "Спинальная амиотрофия: анализ носительства делеций в гене SMN1",
                fourthСell: "9000,00"                       
            },
            {
                firstCell: "701",
                secondCell: "14",
                thirdCell: "Поиск частых мутаций в генах CFTR, PAH, SMN1, GJB2",
                fourthСell: "11500,00"                       
            },
            {
                firstCell: "704",
                secondCell: "14",
                thirdCell: "Анализ носительства спинальной амиотрофии для супружеской пары (кровь с ЭДТА)",
                fourthСell: "9500,00"                       
            },
            {
                firstCell: "36",
                secondCell: "10",
                thirdCell: "Установление отцовства дородовое, неинвазивное",
                fourthСell: "70000,00"                       
            },
            {
                firstCell: "1090",
                secondCell: "8",
                thirdCell: "Определение экспрессии белка PD-L1",
                fourthСell: "14000,00"                       
            },
            {
                firstCell: "831",
                secondCell: "3",
                thirdCell: "Изготовление стекла из парафинового блока",
                fourthСell: "1000,00"                       
            },            
            {
                firstCell: "1511",
                secondCell: "4",
                thirdCell: "Гистологическое исследование эндоскопического материала из различных локусов: пищевод, гортань, желудок, трахея, тонкая и толстая кишка, бронхи. (Не более 3 фрагментов ткани).",
                fourthСell: "3500,00"                       
            },
            {
                firstCell: "1512",
                secondCell: "4",
                thirdCell: "Гистологическое исследование материала, полученного при эдоскопической полипэктомии (полип не более 2 см.).",
                fourthСell: "4000,00"                       
            },
            {
                firstCell: "1513",
                secondCell: "4",
                thirdCell: "Гистологическое исследование эндоскопического материала из различных локусов: пищевод, гортань, желудок, трахея, тонкая и толстая кишка, бронхи. (Более 3 фрагментов ткани).",
                fourthСell: "5500,00"                       
            },
            {
                firstCell: "1514",
                secondCell: "4",
                thirdCell: "Верификация Helicobacter pylori в одном образце биологического материала",
                fourthСell: "2500,00"                       
            },
            {
                firstCell: "1515",
                secondCell: "4",
                thirdCell: "Гистологическое исследование биопсийного материала с применением стандартной гистологической окраски гематоксилином и эозином (локусы - полость рта, носоглотки, слюнная железа).",
                fourthСell: "3500,00"                       
            },
            {
                firstCell: "1575",
                secondCell: "3",
                thirdCell: "Гистологическое исследование биопсийного материала с применением стандартной гистологической окраски гематоксилином и эозином (локус - органы мочевыделительной системы).",
                fourthСell: "3500,00"                       
            },
            {
                firstCell: "1576",
                secondCell: "3",
                thirdCell: "Гистологическое исследование биопсийного материала с применением стандартной гистологической окраски (локусы - мягкие ткани подмышечной области).",
                fourthСell: "3500,00"                       
            },
            {
                firstCell: "1577",
                secondCell: "4",
                thirdCell: "Гистологическое исследование биопсийного материала с применением стандартной гистологической окраски гематоксилином и эозином (пайпель-биопсия эндометрия).",
                fourthСell: "3500,00"                       
            },
            {
                firstCell: "1578",
                secondCell: "4",
                thirdCell: "Гистологическое исследование биопсийного материала с применением стандартной гистологической окраски гематоксилином и эозином (локус - ткань яичка).",
                fourthСell: "3500,00"                       
            },
            {
                firstCell: "1579",
                secondCell: "4",
                thirdCell: "Гистологическое исследование биопсийного материала с применением стандартной гистологической окраски гематоксилином и эозином (локус - шейка матки, влагалище).",
                fourthСell: "3500,00"                       
            },
            {
                firstCell: "1580",
                secondCell: "4",
                thirdCell: "Гистологическое исследование биопсийного материала с применением стандартной гистологической окраски гематоксилином и эозином (локус - забрюшинное пространство).",
                fourthСell: "3500,00"                       
            },
            {
                firstCell: "1581",
                secondCell: "4",
                thirdCell: "Гистологическое исследование биопсийного материала с применением стандартной гистологической окраски гематоксилином и эозином (локус -сустав).",
                fourthСell: "3500,00"                       
            },
            {
                firstCell: "1582",
                secondCell: "5",
                thirdCell: "Гистологическое исследование биопсийного материала с применением стандартной гистологической окраски гематоксилином и эозином (локус - кости и хрящевая ткань).",
                fourthСell: "5000,00"                       
            },
            {
                firstCell: "1583",
                secondCell: "4",
                thirdCell: "Гистологическое исследование биопсийного материала с применением стандартной гистологической окраски гематоксилином и эозином (локус -лимфатические узлы, в том числе сторожевые).",
                fourthСell: "5000,00"                       
            },
            {
                firstCell: "1584",
                secondCell: "3",
                thirdCell: "Гистологическое исследование биопсийного материала с применением стандартной гистологической окраски гематоксилином и эозином (костный мозг).",
                fourthСell: "7000,00"                       
            },
            {
                firstCell: "1585",
                secondCell: "4",
                thirdCell: "Гистологическое исследование пункционной биопсии с применением стандартной гистологической окраски гематоксилином и эозином (локусы - печень, почки, молочная железа и др).",
                fourthСell: "3500,00"                       
            },
            {
                firstCell: "1586",
                secondCell: "4",
                thirdCell: "Гистологическое исследование пункционной биопсии с применением стандартной гистологической окраски гематоксилином и эозином не более 12 фрагментов ткани (локус - предстательная железа).",
                fourthСell: "6500,00"                       
            },
            {
                firstCell: "1587",
                secondCell: "4",
                thirdCell: "Гистологическое исследование операционного материала фрагментов кожи и подкожно-жировой клетчатки с применением стандартной гистологической окраски гематоксилином и эозином. (Размер биологического образца не более 14 мм).",
                fourthСell: "3700,00"                       
            },
            {
                firstCell: "1588",
                secondCell: "4",
                thirdCell: "Гистологическое исследование операционного материала грыжевого мешка, червеобразного отростка, желчного пузыря, свищевого хода, с применением стандартной",
                fourthСell: "3700,00"                       
            },
            {
                firstCell: "1589",
                secondCell: "5",
                thirdCell: "Гистологическое исследование операционного материала миндалин, кист яичника, геморроидальных узлов, миокарда, опухоли средостения с применением стандартной гистологической окраски гематоксилином и эозином.",
                fourthСell: "3500,00"                       
            },            
            {
                firstCell: "1590",
                secondCell: "5",
                thirdCell: "Гистологическое исследование операционного материала придатков матки, кожи и подкожно -жировой клетчатки (размер биологического образца более 14 мм), лимфатических узлов и молочной железы при секторальной резекции с применением стандартной гистологической окраски гематоксилином и эозином.",
                fourthСell: "4000,00"                       
            },
            {
                firstCell: "1591",
                secondCell: "5",
                thirdCell: "Комплексное гистологическое исследование операционного материала легких, кишечника, желудка, предстательной железы, почек, молочной железы, и других органов без лимфатических узлов с применением стандартной гистологической окраски гематоксилином и эозином.",
                fourthСell: "7000,00"                       
            },
            {
                firstCell: "1592",
                secondCell: "5",
                thirdCell: "Комплексное гистологическое исследование операционного материала органокомплекса и целого органа с исследованием сторожевых лимфатических узлов с применением стандартной гистологической окраски гематоксилином и эозином.",
                fourthСell: "11500,00"                       
            },
            {
                firstCell: "1593",
                secondCell: "5",
                thirdCell: "Гистологическое исследование операционного материала предстательной железы (исследование целого органа после простатэктомии) с применением стандартной гистологической окраски гематоксилином и эозином.",
                fourthСell: "17000,00"                       
            },
            {
                firstCell: "1594",
                secondCell: "5",
                thirdCell: "Гистологическое исследование операционного материала матки с придатками (исследование целого органа после гистерэктомии, в связи со злокачетсвенным новообразованием) с применением стандартной гистологической окраски гематоксилином и эозином.",
                fourthСell: "7000,00"                       
            },
            {
                firstCell: "1595",
                secondCell: "5",
                thirdCell: "Гистологическое исследование операционного материала матки с придатками (исследование целого органа после гистерэктомии, в связи с гиперплазией и интраэпителиальной неоплазии) с применением стандартной гистологической окраски гематоксилином и эозином.",
                fourthСell: "10000,00"                       
            },
            {
                firstCell: "1596",
                secondCell: "5",
                thirdCell: "Гистологическое исследование операционного материала матки с придатками (исследование целого органа после гистерэктомии,патология не связана со злокачественной опухолью) с применением стандартной гистологической окраски гематоксилином и эозином.",
                fourthСell: "4000,00"                       
            },
            {
                firstCell: "1597",
                secondCell: "5",
                thirdCell: "Гистологическое исследование операционного материала цервикального канала и соскобов полости матки с применением стандартной гистологической окраски гематоксилином и эозином.",
                fourthСell: "3500,00"                       
            },
            {
                firstCell: "1598",
                secondCell: "5",
                thirdCell: "Гистологическое исследование операционного материала по поводу замершей или неразвивающейся беременности, а также выскабливания полости матки с применением стандартной гистологической окраски гематоксилином и эозином.",
                fourthСell: "5000,00"                       
            },
            {
                firstCell: "1599",
                secondCell: "5",
                thirdCell: "Гистологическое исследование последа (плацента, плодные оболочки и пуповина) с применением стандартной гистологической окраски гематоксилином и эозином.",
                fourthСell: "6000,00"                       
            },
            {
                firstCell: "1600",
                secondCell: "7",
                thirdCell: "Иммуногистохимическое исследование (PD-L1, клон Dako)",
                fourthСell: "20000,00"                       
            },
            {
                firstCell: "1601",
                secondCell: "3",
                thirdCell: "Иммуногистохимическое исследование (HER2)",
                fourthСell: "4500,00"                       
            },
            {
                firstCell: "1602",
                secondCell: "3",
                thirdCell: "Иммуногистохимическое исследование (1 ИГХ реакция)",
                fourthСell: "4500,00"                       
            },
            {
                firstCell: "1603",
                secondCell: "3",
                thirdCell: "Иммуногистохимическое исследование (определение индекса пролиферативной активности Ki-67)",
                fourthСell: "4500,00"                       
            },
            {
                firstCell: "1604",
                secondCell: "3",
                thirdCell: "Иммуногистохимическое исследование (не более 4 ИГХ антител)",
                fourthСell: "9000,00"                       
            },
            {
                firstCell: "1605",
                secondCell: "3",
                thirdCell: "Иммуногистохимическое исследование (от 5 до 10 ИГХ антител)",
                fourthСell: "13000,00"                       
            },
            {
                firstCell: "1606",
                secondCell: "3",
                thirdCell: "Иммуногистохимическое исследование (более 10 ИГХ антител)",
                fourthСell: "24000,00"                       
            },
            {
                firstCell: "1607",
                secondCell: "3",
                thirdCell: "Определение рецептивности эндометрия (окно имплантации) с помощью иммуногистохимического исследования.",
                fourthСell: "13000,00"                       
            },
            {
                firstCell: "1608",
                secondCell: "2",
                thirdCell: "Комплексная диагностика хронического эндометрита с помощью иммуногистохимического исследования.",
                fourthСell: "11000,00"                       
            },
            {
                firstCell: "1609",
                secondCell: "3",
                thirdCell: "Дифференциальная диагностика хронического эндометрита с помощью иммуногистохимического исследования.",
                fourthСell: "11000,00"                       
            },            
            {
                firstCell: "1610",
                secondCell: "5",
                thirdCell: "Диагностика хронического эндометрита с помощью иммуногистохимического исследования.",
                fourthСell: "3600,00"                       
            },
            {
                firstCell: "1611",
                secondCell: "5",
                thirdCell: "Комплексное гистологическое исследование сторожевых лимфатических узловпри меланоме с применением стандартной гистологической окраски гематоксилином и эозином.",
                fourthСell: "21000,00"                       
            },
            {
                firstCell: "1669",
                secondCell: "5",
                thirdCell: "Определение экспрессии NTRK (ИГХ)",
                fourthСell: "9000,00"                       
            },
            {
                firstCell: "1717",
                secondCell: "5",
                thirdCell: "Определение экспрессии INI-1 (ИГХ)",
                fourthСell: "9000,00"                       
            },
            {
                firstCell: "1722",
                secondCell: "14",
                thirdCell: "Определение экспрессии ROS1 (ИГХ)",
                fourthСell: "8000,00"                       
            },
            {
                firstCell: "1723",
                secondCell: "14",
                thirdCell: "Определение экспрессии ALK (ИГХ)",
                fourthСell: "7000,00"                       
            },
            {
                firstCell: "6",
                secondCell: "7",
                thirdCell: "Фармакогенетика варфарина",
                fourthСell: "1500,00"                       
            },
            {
                firstCell: "909",
                secondCell: "40",
                thirdCell: "Панель «Фармакогенетика - max»",
                fourthСell: "16000,00"                       
            },
            {
                firstCell: "941",
                secondCell: "14",
                thirdCell: "Фармакогенетика: Цитохром CYP2D6",
                fourthСell: "2000,00"                       
            },
            {
                firstCell: "943",
                secondCell: "14",
                thirdCell: "Панель: «Фармакогенетика: Цитохром CYP1A2»",
                fourthСell: "2000,00"                       
            },
            {
                firstCell: "660",
                secondCell: "5",
                thirdCell: "Фармакогенетика ингибиторов протонного насоса",
                fourthСell: "2000,00"                       
            },
            {
                firstCell: "661",
                secondCell: "5",
                thirdCell: "Фармакогенетика клопидогрела",
                fourthСell: "2500,00"                       
            },
            {
                firstCell: "1117",
                secondCell: "14",
                thirdCell: "Панель: «Фармакогенетика:Цитохром CYP2C9»",
                fourthСell: "2000,00"                       
            },
            {
                firstCell: "1339",
                secondCell: "10",
                thirdCell: "Панель «Фармакогенетика: DPYD»",
                fourthСell: "1500,00"                       
            },
        ]
    }    
}

export type tabNameType = "surova"|"bebelya"|"geneticResearch"; 

export type initialStateType = typeof initialState;

export const pricesReducer = (state = initialState, action:any):initialStateType => {
    return state;
};